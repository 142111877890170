import {
  Box,
  Heading,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import { PackageCard } from "./PackageCard";

const freemBenefits = `
<ul>
  <li>Access to all questions </li>
  <li>Scored all mock exams </li>
  <li>Unlimited AI scoring submissions for speaking </li>
  <li>Unlimited AI scoring submissions for writing </li>
  <li>Attempt all the mock exams </li>
  <li>Unlimited attempts per exam </li>
</ul>
`;
const premiumBenefits = `
<ul>
  <li>Access to all questions </li>
  <li>Scored all mock exams </li>
  <li>Unlimited AI scoring submissions for speaking </li>
  <li>Unlimited AI scoring submissions for writing </li>
  <li>Attempt all the mock exams </li>
  <li>Unlimited attempts per exam </li>
</ul>
`;
type PackageSelectionProps = {
  days: number;
  updatePrice: (newDays: number) => void;
};

export const PackageSelection: React.FC<PackageSelectionProps> = ({
  days,
  updatePrice,
}) => {
  const labelStyles = {
    mt: "2",
    ml: "-2.5",
    fontSize: "sm",
  };

  return (
    <Box boxShadow="md" p={[6, 8]} rounded="md" bg="white">
      <Heading as="h1" size="xl" textAlign="center"  mb={9}>
        Select Package
      </Heading>
      <Text  mb={9}>
      Determined learners can select a budget-friendly Self-learner plan that aligns with their study timeframe. 
      Enjoy unlimited AI scoring and recommendations in speaking and writing, graded mock exams,
       and full access to all mock exams to help you reach your goal with confidence.
      </Text>

      <SimpleGrid columns={[1, null, 4]} spacing={5} mt={4}>
          <PackageCard
            title=""
            duration={14}
            description={premiumBenefits}
            onClick={() => updatePrice(14)}
            isSelected={days === 14}
            bgColor="green.50"
          />
          <PackageCard
            title=""
            duration={30}
            description={premiumBenefits}
            onClick={() => updatePrice(30)}
            isSelected={days === 30}
            isPopular
            bgColor="blue.200"
          />
          <PackageCard
            title=""
            duration={60}
            description={premiumBenefits}
            onClick={() => updatePrice(60)}
            isSelected={days === 60}
            bgColor="blue.50"
          />
           <PackageCard
            title=""
            duration={90}
            description={premiumBenefits}
              onClick={() => updatePrice(90)}
            isSelected={days === 90}
            bgColor="green.50"
          />
      </SimpleGrid>

      {/* <Box p={4} mt={6}>
        <Text>Select Learning Days</Text>
        <Slider
          aria-label="slider-ex-6"
          onChange={(val) => updatePrice(val)}
          value={days}
          min={5}
          max={60}
          step={5}
        >
          <SliderMark value={5} {...labelStyles}>
            5 days
          </SliderMark>
          <SliderMark value={15} {...labelStyles}>
            15 days
          </SliderMark>
          <SliderMark value={30} {...labelStyles}>
            30 days
          </SliderMark>
          <SliderMark value={60} {...labelStyles}>
            60 days
          </SliderMark>
          <SliderMark
            value={days}
            textAlign="center"
            bg="teal.500"
            fontSize={12}
            color="white"
            mt="-10"
            ml="-5"
            w="22"
            p={2}
            borderRadius={3}
          >
            {days} days
          </SliderMark>
          <SliderTrack h={2}>
            <SliderFilledTrack bg={"teal.500"} />
          </SliderTrack>
          <SliderThumb h={5} w={5} />
        </Slider>
      </Box> */}
    </Box>
  );
};

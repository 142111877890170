import React, { useCallback, useEffect, useState } from "react";
import { useDropzone, Accept } from "react-dropzone";
import { Box, useToast, Text } from "@chakra-ui/react";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../utils/firebaseConfig";

type UploaderProps = {
  setMediaUrl: (url: string | null) => void;
  fileType?: "audio" | "video";
  oldAudio?: string;
};

export const AudioAndVideoUploader: React.FC<UploaderProps> = ({ setMediaUrl, oldAudio, fileType = "video" }) => {
  const [uploading, setUploading] = useState<boolean>(false);
  const [mediaLink, setMediaLink] = useState<string | null>(null);
  const toast = useToast();

  useEffect(() => {
    if (oldAudio) {
      setMediaLink(oldAudio);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oldAudio]);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length > 0) {
      const file = acceptedFiles[0];
      uploadMedia(file);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: fileType === "audio" ? { 'audio/*': [] } : { 'video/*': [] },
  });

  const uploadMedia = (file: File) => {
    const storageRef = ref(storage, `${fileType}/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);
 
    setUploading(true);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Handle progress if needed
      },
      (error) => {
        toast({
          title: "Upload failed.",
          description: error.message,
          status: "error",
          duration: 2000,
          isClosable: true,
        });
        setUploading(false);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setMediaUrl(downloadURL);
          setMediaLink(downloadURL);
          toast({
            title: "Upload successful.",
            description: `File has been uploaded successfully.`,
            status: "success",
            duration: 2000,
            isClosable: true,
          });
          setUploading(false);
        });
      }
    );
  };

  return (
    <Box
      {...getRootProps()}
      border="2px dashed"
      borderColor={isDragActive ? "teal.500" : "gray.200"}
      borderRadius="md"
      p={4}
      textAlign="center"
      cursor="pointer"
      h={mediaLink && fileType === "video" ? "auto" : 100}
      mb={mediaLink ? 20 : ""}
    >
      <input {...getInputProps()} />
      <Box textAlign="center" lineHeight={10} cursor="pointer">
        {isDragActive ? (
          <Text>Drop the {fileType} file here ...</Text>
        ) : (
          <Text>
            Drag 'n' drop a {fileType} file here, or click to select files
          </Text>
        )}
        <Text color="#FEAF63" fontWeight={520} as="span">
          browse {fileType} file
        </Text>
      </Box>
      {uploading && <Text>Uploading...</Text>}
      <Box mt={4} h={300} >
        {mediaLink && (
          <>
            {fileType === "audio" ? (
              <audio controls src={mediaLink} />
            ) : (
              <video controls src={mediaLink}   />
            )}
          </>
        )}
      </Box>
    </Box>
  );
};

import { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { QuestionBody, QuestionData } from "../types/reading-types";
import { config } from "../config";

export type testType = "Listening" | "Reading" | "Writing" | "Speaking";

export const useQuestionData = (reset: any, type?: testType) => {
  const { id } = useParams();
  const [question, setQuestion] = useState<QuestionBody | null>(null);
  const [questionData, setQuestionData] = useState<QuestionData | null>(null);
  const API_URL = config.baseUrl.default;

  useEffect(() => {
    const fetchQuestion = async () => {
      if (id) {
        try {
          const res = await axios.get<any>(`${API_URL}question/${id}`);
          setQuestion(res.data[0]);
        } catch (error) {
          console.error("Failed to fetch question:", error);
        }
      }
    };

    fetchQuestion();
  }, [id, API_URL]);

  useEffect(() => {
    if (question) {
      const readingQuestions: QuestionData = JSON.parse(question.data);
      setQuestionData(readingQuestions);
    }
  }, [question]);

  useEffect(() => {
    if (type === "Writing") {
      if (questionData) {
        reset({
          questionsType: "Writing",
          questionOne: questionData.questionOne,
          testNum: questionData.testNum,
          questionOneInstructionOne: questionData.questionOneInstructionOne,
          questionOneInstructionTwo: questionData.questionOneInstructionTwo,
          questionTwo: questionData.questionTwo,
          questionTwoInstruction: questionData.questionTwoInstruction,
          questionTwoOptionA: questionData.questionTwoOptionA,
          questionTwoOptionB: questionData.questionTwoOptionB,
          questionOneTime: questionData.questionOneTime,
          questionTwoTime: questionData.questionTwoTime,
        });
      }
    } else if (type === "Speaking") {
      if (questionData) {
        reset({
          questionsType: "Speaking",
          questionPracticeTask: questionData.questionPracticeTask,
          questionSpeakingTaskOne:  questionData.questionSpeakingTaskOne,
          questionSpeakingTaskTwo:  questionData.questionSpeakingTaskTwo,
          questionSpeakingTaskThree:  questionData.questionSpeakingTaskThree,
          questionSpeakingTaskThreePhoto:  questionData.questionSpeakingTaskThreePhoto,
          questionSpeakingTaskFour:  questionData.questionSpeakingTaskFour,
          questionSpeakingTaskFourPhoto:  questionData.questionSpeakingTaskFourPhoto,
          questionSpeakingTaskFive:  questionData.questionSpeakingTaskFive,
          questionSpeakingTaskFiveInstruction:  questionData.questionSpeakingTaskFiveInstruction,
          questionSpeakingTaskFivePhotoOne:  questionData.questionSpeakingTaskFivePhotoOne,
          questionSpeakingTaskFiveTwo:  questionData.questionSpeakingTaskFiveTwo,
          questionSpeakingTaskFivePhotoTwo:  questionData.questionSpeakingTaskFivePhotoTwo,
          questionSpeakingTaskFivePhotoThree:  questionData.questionSpeakingTaskFivePhotoThree,
          questionSpeakingTaskSix:  questionData.questionSpeakingTaskSix,
          questionSpeakingTaskSixInstructionOne:  questionData.questionSpeakingTaskSixInstructionOne,
          questionSpeakingTaskSeven:  questionData.questionSpeakingTaskSeven,
          questionSpeakingTaskSevenInstruction:  questionData.questionSpeakingTaskSevenInstruction,
          questionSpeakingTaskEight:  questionData.questionSpeakingTaskEight,
          questionSpeakingTaskEightPhoto:  questionData.questionSpeakingTaskEightPhoto,
          questionPracticeTaskTime:  questionData.questionPracticeTaskTime,
          questionSpeakingTaskOneTime:  questionData.questionSpeakingTaskOneTime,
          questionSpeakingTaskTwoTime:  questionData.questionSpeakingTaskTwoTime,
          questionSpeakingTaskThreeTime:  questionData.questionSpeakingTaskThreeTime,
          questionSpeakingTaskFourTime:  questionData.questionSpeakingTaskFourTime,
          questionSpeakingTaskFiveTime:  questionData.questionSpeakingTaskFiveTime,
          questionSpeakingTaskSixTime:  questionData.questionSpeakingTaskSixTime,
          questionSpeakingTaskSevenTime:  questionData.questionSpeakingTaskSevenTime,
          questionSpeakingTaskEightTime:  questionData.questionSpeakingTaskEightTime,
        });
      }
    } else if (type === "Listening") {
      // if(questionData) {
      //   reset({
      //     questionsType: "Listening",
      //     testNum: questionData.testNum,
      //     listeningInstructionOne:
      //       questionData.listeningInstructionOne,
      //     listeningInstructionTwo:
      //       questionData.listeningInstructionTwo,
      //     listeningToProblemSolvingAudioOne:
      //       questionData.listeningToProblemSolvingAudioOne,
      //     time: questionData.time,
      //     questions: questionData.questions,
      //   })
      // }
     
    }else {
      if (questionData) {
        reset({
          questionsType: questionData.questionsType,
          testNum: questionData.testNum,
          bodyContent: questionData.bodyContent,
          sectionTwoQuestions: questionData.sectionTwoQuestions,
          time: questionData.time,
          questions: questionData.questions,
        });
      }
    }
  }, [questionData, reset, type]);

  useEffect(() => {
    if (questionData) {
      reset({
        // questions: questionData.questions,
      });
    }
  }, [questionData, reset, type]);

  return { question, questionData };
};

import {
  Flex,
  Box,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  Text,
  Heading,
  Link,
  useToast,
  Spinner,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {
  useAppSelector,
  useAppDispatch,
} from "../../redux/hooks/useTypedSelector";
import { ForgetPasswordValidationSchema, RecoverPassword } from "./schema";
import {
  recoverPasswordAction,
  resetAuth,
} from "../../redux/slice/auth/authSlice";

export const ForgetPassword = () => {
  const { isSuccess, isError, errorMessage } = useAppSelector(
    (state) => state.auth,
  );
  const dispatch = useAppDispatch();
  const toast = useToast();

  const {
    handleSubmit,
    register,
    reset,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(ForgetPasswordValidationSchema),
    defaultValues: {
      email: "",
    },
  });

  const onSubmitHandler = async (email: RecoverPassword) => {
    try {
      await dispatch(recoverPasswordAction(email));
      if (isSuccess) {
        toast({
          title: "Password Email Sent!",
          description:
            "An email with password reset instructions has been sent to your email address",
          status: "success",
          position: "top-right",
          duration: 9000,
          isClosable: true,
        });
      } else if (isError || !errorMessage) {
        toast({
          title: "Error",
          description: errorMessage,
          status: "error",
          position: "top-right",
          duration: 9000,
          isClosable: true,
        });
        await dispatch(resetAuth);
      }
      reset();
    } catch (error: any) {
      console.log(error.message);
    }
  };

  return (
    <Flex minH={"100vh"} align={"center"} justify={"center"} bg={"gray.50"}>
      <Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
        <Box rounded={"lg"} bg={"gray.50"} boxShadow={"lg"} p={8}>
          <Box as={"form"} mt={0} onSubmit={handleSubmit(onSubmitHandler)}>
            <Flex direction="column" w="100%" background="transparent">
              <Heading color={"#"} fontSize={25} mb={5} textAlign={"center"}>
                Reset your password
              </Heading>
              <Text mb={5}>
                Please provide the email address you used when you signed up for
                your PassEnglishTest account.
              </Text>
              <FormControl>
                <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
                  Email
                </FormLabel>
                <Input
                  type="email"
                  {...register("email")}
                  isInvalid={!!errors.email}
                  mb="24px"
                  fontSize="sm"
                  placeholder="Email"
                  size="lg"
                />

                <Button
                  type="submit"
                  bg="#319795"
                  w="100%"
                  h="45"
                  mb="20px"
                  color="white"
                  _hover={{
                    bg: "teal.200",
                  }}
                  _active={{
                    bg: "teal.400",
                  }}
                >
                  {isSubmitting ? <Spinner /> : "Send Email"}
                </Button>
              </FormControl>
              <Flex
                flexDirection="column"
                justifyContent="left"
                alignItems="left"
                maxW="100%"
                mt="0px"
              >
                <Text color={"gray.700"} fontWeight="medium">
                  Remember my password?
                  <Link color={"#319795"} as="a" href="/sign-in" ms="5px">
                    Sign In
                  </Link>
                </Text>
              </Flex>
            </Flex>
          </Box>
        </Box>
      </Stack>
    </Flex>
  );
};

import React, { useCallback, useState, useEffect } from "react";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import { Box, Text, Progress, Flex, Image, useToast } from "@chakra-ui/react";
import { CountdownTimer } from "../../../components/CountdownTimer";
import { cleanString } from "../../../utils/helperFuntions";
import { useSpeakingQuestionStore } from "../../../redux/useQuestionStore";
import { submitMockTestSpeakingAnswerAction } from "../../../redux/slice/mock-test/speakingTestSlice";
import {
  useAppDispatch,
} from "../../../redux/hooks/useTypedSelector";

type VoiceRecorderProps = {
  recordTime: number;
  countdownTimer: number;
  questionNumber: string;
  question: string;
  currentSection: number;
  onTimeUp: () => void;
  testID: number;
  testNum: string;
  user: number;
};

export const VoiceRecorder: React.FC<VoiceRecorderProps> = ({
  recordTime,
  countdownTimer = 30,
  questionNumber,
  question,
  currentSection,
  onTimeUp,
  testID,
  testNum,
  user,
}) => {
  // eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
  const [transcriptText, setTranscriptText] = useState<string>("");
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [countdownTime, setCountdownTime] = useState<number>(recordTime);
  const [showCountdown, setShowCountdown] = useState<boolean>(true);
  const { transcript, resetTranscript } = useSpeechRecognition();
  const { addQuestion, speakingResponse } = useSpeakingQuestionStore();

  const dispatch = useAppDispatch();
  const toast = useToast();

  const handleTimeUp = useCallback(() => {
    if(currentSection !== 6){
      startListening();
      setShowCountdown(false);
      onTimeUp();
    }
   
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isRecording) {
      const timer = setTimeout(() => {
        stopListening();
        resetTranscript();
      }, recordTime * 1000);

      const countdown = setInterval(() => {
        setCountdownTime((prevTime) => prevTime - 1);
      }, 1000);

      return () => {
        clearTimeout(timer);
        clearInterval(countdown);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRecording, recordTime, resetTranscript]);

  useEffect(() => {

    if (countdownTime < 2) {
      stopListening();
      resetTranscript();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countdownTime]);

  if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
    return (
      <Text>
        Your browser does not support speech recognition software! Please use
        Chrome or Firefox.
      </Text>
    );
  }

  const startListening = () => {
    SpeechRecognition.startListening({ continuous: true });
    setIsRecording(true);
  };

  const stopListening = () => {

    SpeechRecognition.stopListening();
    setIsRecording(false);
    setTranscriptText(transcript);
    // Store the question and answer in the Zustand store
    addQuestion({
      questionNumber: questionNumber,
      question: cleanString(question),
      answer: transcript,
    });
    onTimeUp();
    if (currentSection === 11) {
      onSubmitHandler();
    }
  };

  const onSubmitHandler = async () => {
    await dispatch(
      submitMockTestSpeakingAnswerAction({
        userID: user,
        testID: testID,
        testNum: testNum,
        questionData: speakingResponse,
      }),
    );
    toast({
      title: "Sent successfully.",
      status: "success",
      position: "top-right",
      duration: 9000,
      isClosable: true,
    });
    setTimeout(() => {
      window.location.href = `/speaking-test-result/${testID}`;
    }, 1000);
  };
  const progressValue = ((recordTime - countdownTime) / recordTime) * 100;

  return (
    <Box p={4}>
      {/* {transcript} */}
      {showCountdown && currentSection !== 6 && (
        <Box boxShadow={"md"} bg={"gray.300"} p={9} borderRadius={"md"}>
          <Flex>
            <Image src={"/images/timer-icon.svg"} w={9} mr={4} mt={-1} />
            <CountdownTimer
              time={countdownTimer}
              onTimeUp={handleTimeUp}
              timeType="Second"
              customText="Preparing "
            />
          </Flex>
        </Box>
      )}

      {isRecording && (
        <>
        <Text color={"red"}>Start speaking now!</Text>
        <Box mt={4} boxShadow={"md"} bg={"gray.300"} p={9} borderRadius={"md"}>
          <Flex align="center">
            <Image src={"/images/mic-icon.svg"} w={9} />
            <Text fontWeight="bold" ml={3}>
              Recording........ {countdownTime}s left
            </Text>
          </Flex>
          <Progress colorScheme="blue" size="lg" mt={2} value={progressValue} />
        </Box>
        </>
      )}
    </Box>
  );
};

import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Button,
  Container,
  Flex,
  HStack,
  Heading,
  Icon,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";
import { addSpaceBeforeCaps } from "../../../utils/helperFuntions";
import {
  useAppSelector,
  useAppDispatch,
} from "../../../redux/hooks/useTypedSelector";
import { startMockTestQuestionsAction } from "../../../redux/slice/mock-test/mockTestSlice";
import { CountdownTimer } from "../../../components/CountdownTimer";
import {
  WritingQuestion,
  WritingQuestionData,
} from "../../../types/writing-types";
import { DisplayQuestionContent } from "../../../components/DisplayQuestionContent";
import { UserWritingResponse } from "./user-response/UserWritingResponse";
import { useParams } from "react-router-dom";
import { decryptString } from "../../../utils/encryption";

export const WritingTest: React.FC = () => {
  const [currentSection, setCurrentSection] = useState<number>(1);
  const [secondsLeft, setSecondsLeft] = useState<number>(0);
  const { isLoading } = useAppSelector((state) => state.mockTest);
  const [questions, setQuestions] = useState<WritingQuestion[]>([]);
  const [currentQuestion, setCurrentQuestion] = useState<string>("");
  const { testNum } = useParams();
  const decryptedTestNum = decryptString(testNum?.toString() ?? "");
  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const res = await dispatch(
        startMockTestQuestionsAction({
          type: "Writing",
          testNum: decryptedTestNum,
        }),
      );
      setQuestions(res.payload as WritingQuestion[]);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const setNextQuestion = useCallback(() => {
    setCurrentSection((prevSection) => prevSection + 1);
  }, []);

  const handleTimeUp = useCallback(() => {
    setNextQuestion();
  }, [setNextQuestion]);

  const getCurrentQuestions = JSON.parse(
    questions[0]?.data ?? "{}",
  ) as WritingQuestionData;

  useEffect(() => {
    if (currentSection === 1) {
      setSecondsLeft(parseInt(getCurrentQuestions.questionOneTime));
      setCurrentQuestion(getCurrentQuestions.questionOne);
    }
    if (currentSection === 2) {
      setSecondsLeft(parseInt(getCurrentQuestions.questionTwoTime));
      setCurrentQuestion(getCurrentQuestions.questionTwo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSection, getCurrentQuestions]);

  if (isLoading || questions.length < 1) {
    return <Spinner />;
  }

  if (questions.length < 1) {
    return (
      <>
        <Spinner />
        <Text>No questions available</Text>
      </>
    );
  }

  return (
    <Box minH="100vh" py={10}>
      <Container
        maxW="100%"
        bg="white"
        p={8}
        borderRadius="md"
        boxShadow="md"
        mt={9}
      >
        <Flex
          direction="row"
          justify="space-between"
          align="center"
          bg={"gray.200"}
          border={"1px #CCC solid"}
          p={3}
          h={"auto"}
        >
          <Heading size="md" fontWeight={300} fontSize={16}>
            Mock Test {" "} {decryptedTestNum} {" "}
            {currentSection === 1
              ? "Writing an Email "
              : "Responding to Survey Questions "}
            {addSpaceBeforeCaps(questions[0].questionType)}
          </Heading>
          <Box>
            <Flex>
              <CountdownTimer time={secondsLeft} onTimeUp={handleTimeUp} />
              <Button
                colorScheme="blue"
                fontSize={11}
                ml={4}
                h={3}
                p={4}
                onClick={setNextQuestion}
              >
                NEXT
              </Button>
            </Flex>
          </Box>
        </Flex>
        <HStack justify="space-between">
          <VStack
            align="start"
            spacing={5}
            w="full"
            p={3}
            borderLeft="1px #CCC solid"
            borderRight="1px #CCC solid"
            borderBottom="1px #CCC solid"
            h={"100vh"}
            overflowY="auto"
          >
            <HStack>
              <Icon as={InfoIcon} color="blue.500" />
              <Heading size="sm" color="blue.600">
                Read the following message.
              </Heading>
            </HStack>

            <DisplayQuestionContent content={currentQuestion} />
          </VStack>
          <VStack
            align="start"
            spacing={5}
            w="full"
            p={3}
            bg="#f7fafc"
            h={"100vh"}
            borderBottom="1px #CCC solid"
            ml={-2}
            overflowY="auto"
          >
            <UserWritingResponse
              question={getCurrentQuestions}
              part={currentSection}
            />
          </VStack>
        </HStack>
        {/* <HStack justify="space-between" mt={10}>
          <Button as={"a"} href="/answer-key" colorScheme="blue">
            Answer Key
          </Button>
          <Button colorScheme="red">BACK</Button>
        </HStack> */}
      </Container>
    </Box>
  );
};

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import readingTestService from "../../service/mockTestService";
import { handleRejected, initialState } from "./mockTestSlice";

export type speakingMockTestData = {
  data?:any
};

export type SpeakingFeedback = {
  userID: string,
  testID: string,
  testNum: string,
}
export const submitMockTestSpeakingAnswerAction = createAsyncThunk(
  'auth/send-speaking-mock-test',
  async (data: any, thunkAPI) => {
    try {
      return await readingTestService.sendUserSpeakingResponse(data, thunkAPI);
    } catch (error: any) {
      let message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);
export const getSpeakingAnswerFeedbackAction = createAsyncThunk('auth/speaking-feedback', async (data: SpeakingFeedback,thunkAPI) => {
  try {
    return await readingTestService.getSpeakingFeedback(data,thunkAPI);
  } catch (error: any) {
    let message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

    return message;
  }
});
export const speakingMockTestSlice = createSlice({
  name: "speakingMockTest",
  initialState,
  reducers: {
    resetState: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitMockTestSpeakingAnswerAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(submitMockTestSpeakingAnswerAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.data = action?.payload?.data as speakingMockTestData;
      })
      .addCase(submitMockTestSpeakingAnswerAction.rejected, (state, action) => {
        handleRejected(state, action);
      })
      .addCase(getSpeakingAnswerFeedbackAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSpeakingAnswerFeedbackAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.data = action?.payload?.data as speakingMockTestData;
      })
      .addCase(getSpeakingAnswerFeedbackAction.rejected, (state, action) => {
        handleRejected(state, action);
      });
  },
});

export const { resetState } = speakingMockTestSlice.actions;
export default speakingMockTestSlice.reducer;

import { useState, useEffect } from "react";
import {
  Box,
  Spinner,
  Stack,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Text,
  SimpleGrid,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Flex,
} from "@chakra-ui/react";
import {
  useAppSelector,
  useAppDispatch,
} from "../../../redux/hooks/useTypedSelector";
import { getQuestionsAction } from "../../../redux/slice/mock-test/questionBankSlice";

import { formatDate, setNextTestNum } from "../../../utils/helperFuntions";
import {
  WritingQuestion,
  WritingQuestionData,
} from "../../../types/writing-types";
import { ReadingQuestion } from "../reading/schema";
import { EditIcon } from "@chakra-ui/icons";
import { encryptString } from "../../../utils/encryption";
import { FaBook } from "react-icons/fa";

export const WritingQuestionBank = () => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const { isLoading } = useAppSelector((state) => state.questionBank);
  const [questions, setQuestions] = useState<WritingQuestion[]>([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const res = await dispatch(getQuestionsAction("Writing"));
      setQuestions(res.payload);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  if (isLoading) {
    return <Spinner />;
  }

  const sortedQuestions = [...(questions || [])].sort((a, b) => b.id - a.id);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  return (
    <Stack>
      <Box>
        <Button
          as={"a"}
          href={`/add-writing-question/${setNextTestNum(questions as unknown as ReadingQuestion[], "Writing")}`}
          colorScheme="teal"
          size="md"
          mb={9}
        >
          Add New Question
        </Button>
        <Heading size="md">Total Questions: {sortedQuestions.length}</Heading>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5} p={9}>
          {sortedQuestions.map((question, index) => {
            const parsedData = JSON.parse(question.data) as WritingQuestionData;
            return (
              <Card key={question.id} boxShadow="2xl">
                <Flex justify="flex-end" p={3}>
                  <Menu>
                    <MenuButton onClick={toggleMenu} as={Box} cursor="pointer">
                      <Text fontWeight={"bold"} fontSize={24}>
                        ...
                      </Text>
                    </MenuButton>
                    <MenuList minW="120px">
                      <MenuItem
                        icon={<FaBook />}
                        as={"a"}
                        target="_blank"
                        href={`/writing-test-instructions/${encryptString(question.testNum.toString())}`}
                        color={"blue.600"}
                      >
                        Test Mock Test
                      </MenuItem>
                      <MenuItem
                        icon={<EditIcon />}
                        as={"a"}
                        href={`/edit-writing-question/${question.id}`}
                      >
                        Edit
                      </MenuItem>
                      <MenuItem onClick={closeMenu}>
                        {/* <DeleteGame game={game} /> */}
                        Delete
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Flex>
                <CardHeader>
                  <Heading size="md">Question Number: {index + 1}</Heading>
                  <Heading size="md">Question ID: {question.id}</Heading>
                </CardHeader>
                <CardBody>
                  <Text>
                    <strong>Question Type:</strong> {question.questionType}
                  </Text>
                  <Text>
                    <strong>Created At:</strong> {formatDate(question.createAt)}
                  </Text>

                  <Heading size="md" color="blue.600" mb={3} mt={3}>
                    Question One
                  </Heading>
                  <Text>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: parsedData.questionOne,
                      }}
                    />
                  </Text>
                  <Text mt={3}>
                    <strong>Instructions:</strong>{" "}
                    <span
                      dangerouslySetInnerHTML={{
                        __html: parsedData.questionOneInstructionOne,
                      }}
                    />
                  </Text>
                  <Text p={5}>
                    <strong>Additional Instructions:</strong>{" "}
                    <span
                      dangerouslySetInnerHTML={{
                        __html: parsedData.questionOneInstructionTwo,
                      }}
                    />
                  </Text>
                  <Text p={1} color={"red.600"}>
                    <strong>Time:</strong> {parsedData.questionOneTime} Minutes
                  </Text>
                  <Heading size="md" color="blue.600" mb={3} mt={3}>
                    Question Two
                  </Heading>
                  <Text>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: parsedData.questionTwo,
                      }}
                    />
                  </Text>
                  <Text mt={5}>
                    <strong>Instructions:</strong>{" "}
                    <span
                      dangerouslySetInnerHTML={{
                        __html: parsedData.questionTwoInstruction,
                      }}
                    />
                  </Text>
                  <Text mt={5}>
                    <strong>Option A:</strong> {parsedData.questionTwoOptionA}
                  </Text>
                  <Text>
                    <strong>Option B:</strong> {parsedData.questionTwoOptionB}
                  </Text>
                  <Text p={1} color={"red.600"}>
                    <strong>Time:</strong> {parsedData.questionTwoTime} Minutes
                  </Text>
                </CardBody>
              </Card>
            );
          })}
        </SimpleGrid>
      </Box>
    </Stack>
  );
};

import {
  Box,
  Flex,
  Heading,
  Spacer,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { Student } from "../../../redux/slice/student/studentSlice";
import { useTeachers } from "../../../hooks/use-teachers";

export const Teachers = () => {
  const { isLoading, teachers } = useTeachers();

  if (isLoading) {
    return <Spinner />;
  }

  const sortedTeachers = [...(teachers || [])].sort((a, b) => b.id - a.id);

  return (
    <Stack>
      <Flex>
        <Box width={"100%"}>
          <Heading>Teachers</Heading>
        </Box>
        <Spacer />
        <Box width={"100%"}>
          <Heading mt={9} mb={5} textAlign={"center"}>
            Total Students: {sortedTeachers.length}
          </Heading>
        </Box>
        {/* <AddNewStudentModal /> */}
      </Flex>

      <TableContainer>
        <Table
          variant="simple"
          bg={"#FFF"}
          boxShadow={"2xl"}
          borderRadius={"md"}
        >
          <Thead bg={"gray.50"}>
            <Tr>
              <Th>ID</Th>
              <Th>First Name</Th>
              <Th>Last Name</Th>
              <Th>Email</Th>
              <Th>Created At</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {sortedTeachers &&
              sortedTeachers.map((student: Student, index: any) => (
                <Tr key={index}>
                  <Td>ST-{student.id}</Td>
                  <Td>{student.firstName}</Td>
                  <Td>{student.lastName}</Td>
                  <Td>{student.email}</Td>
                  <Td>{new Date(student.created_at).toLocaleDateString()}</Td>
                  <Td>
                    {/* <RemoveStudentModal student={student} /> */}
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

import React from "react";
import { VStack } from "@chakra-ui/react";
import { UserResponse } from "../UserResponse";
import {
  ListeningQuestionData,
  ListeningQuestionType,
} from "../../../../../types/listening-types";
import { MockQuestion } from "../../../../../types/reading-types";

type DailyLifeConversationResponseProps = {
  questionData: ListeningQuestionData;
  stage: number;
};

export const DailyLifeConversationUserResponse: React.FC<
  DailyLifeConversationResponseProps
> = ({ questionData, stage }) => {
  const renderUserResponse = (index: number) => {
    return (
      <>
        {index === 3 && (
          <UserResponse
            questions={questionData.questions[0] as MockQuestion}
            questionType={questionData.questionsType as ListeningQuestionType}
          />
        )}
        {index === 4 && (
          <UserResponse
            questions={questionData.questions[1] as MockQuestion}
            questionType={questionData.questionsType as ListeningQuestionType}
          />
        )}
        {index === 5 && (
          <UserResponse
            questions={questionData.questions[2] as MockQuestion}
            questionType={questionData.questionsType as ListeningQuestionType}
          />
        )}
        {index === 6 && (
          <UserResponse
            questions={questionData.questions[3] as MockQuestion}
            questionType={questionData.questionsType as ListeningQuestionType}
          />
        )}
        {index === 7 && (
          <UserResponse
            questions={questionData.questions[4] as MockQuestion}
            questionType={questionData.questionsType as ListeningQuestionType}
          />
        )}
      </>
    );
  };

  if (stage === 3 || stage === 4 || stage === 5 || stage === 6 || stage === 7) {
    return (
      <VStack
        align="start"
        spacing={5}
        w="full"
        p={3}
        bg="#f7fafc"
        minH="90vh"
        maxH="90vh"
        borderBottom="1px #CCC solid"
        ml={-2}
        overflowY="auto"
      >
        {renderUserResponse(stage)}
      </VStack>
    );
  }

  return null;
};

import {
  Stack,
  Flex,
  Text,
  Box,
  Container,
  Heading,
  Image,
  Icon,
  SimpleGrid,
  StackDivider,
  useColorModeValue,
} from "@chakra-ui/react";
import { ReactElement } from "react";
import {
  IoSchoolSharp,
  IoAnalyticsSharp,
  IoPersonSharp,
} from "react-icons/io5";

type FeatureProps = {
  text: string;
  description: string;
  iconBg: string;
  icon?: ReactElement;
};

const DisplayFeature = ({ text, icon, iconBg, description }: FeatureProps) => {
  return (
    <>
      <Stack direction={"row"} align={"center"}>
        <Flex
          w={8}
          h={8}
          align={"center"}
          justify={"center"}
          rounded={"full"}
          bg={iconBg}
        >
          {icon}
        </Flex>
        <Text fontWeight={600}>{text}</Text>
      </Stack>
      <Stack>
        <Text ml={9}>{description}</Text>
      </Stack>
    </>
  );
};

export const Feature = () => {
  return (
    <Box as="section" py={20}>
      <Container maxW="container.xl">
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
          <Stack spacing={4}>
            <Heading>Master CELPIP with PassEnglishTest</Heading>
            <Text color={"gray.500"} fontSize={"lg"}>
              Our platform provides a comprehensive approach to prepare you for
              the CELPIP test, offering tools and resources to enhance your
              learning experience.
            </Text>
            <Stack
              spacing={4}
              divider={
                <StackDivider
                  borderColor={useColorModeValue("gray.100", "gray.700")}
                />
              }
            >
              <DisplayFeature
                icon={
                  <Icon as={IoSchoolSharp} color={"purple.500"} w={5} h={5} />
                }
                iconBg={useColorModeValue("purple.100", "purple.900")}
                text={"Practice Tests"}
                description={
                  "Realistic practice tests to familiarize you with the actual exam format."
                }
              />
              <DisplayFeature
                icon={
                  <Icon as={IoAnalyticsSharp} color={"green.500"} w={5} h={5} />
                }
                iconBg={useColorModeValue("green.100", "green.900")}
                text={"Detailed Analytics"}
                description={
                  "Track your progress and pinpoint areas for improvement with our detailed analytics."
                }
              />
              {/* <DisplayFeature
                  icon={
                    <Icon as={IoSchoolSharp} color={"purple.500"} w={5} h={5} />
                  }
                  iconBg={useColorModeValue("purple.100", "purple.900")}
                  text={"Interactive Lessons"}
                /> */}
              <DisplayFeature
                icon={
                  <Icon as={IoPersonSharp} color={"blue.500"} w={5} h={5} />
                }
                iconBg={useColorModeValue("blue.100", "blue.900")}
                text={"Expert Tips"}
                description={
                  "Get tips and strategies from AI-CELPIP experts to boost your performance. writing and Speaking"
                }
              />
            </Stack>
          </Stack>
          <Flex>
            <Image
              rounded={"md"}
              alt={"feature image"}
              src={
                "https://images.unsplash.com/photo-1554200876-56c2f25224fa?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1350&q=80"
              }
              objectFit={"cover"}
            />
          </Flex>
        </SimpleGrid>
      </Container>
    </Box>
  );
};

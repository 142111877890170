import React from "react";
import {
  Box,
  Container,
  HStack,
  Heading,
  Image,
  Text,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { FaCheck, FaTimes } from "react-icons/fa";
import { addSpaceBeforeCaps } from "../../../../utils/helperFuntions";
import { ListeningSections } from "../../../../utils/scoring-format";
import { ReadingAndListeningResponseType } from "../../../../types/reading-types";

type ListeningAnswerTableProps = {
  totalCorrectAnswers: number;
  totalScore: number | string;
  responses: ReadingAndListeningResponseType[];
}

export const ListeningAnswerTable: React.FC<ListeningAnswerTableProps> = ({
  totalCorrectAnswers,
  totalScore,
  responses,
}) => {
  return (
    <Box minH="100vh" py={10}>
      <Container
        maxW="90%"
        bg="white"
        p={0}
        borderRadius="md"
        boxShadow="md"
        mt={9}
      >
        <Box p={9}>
          <Box mb={3}>
            <HStack>
              <Heading size="sm" color={"blue.600"}>
                Listening Mock Test Result
              </Heading>
            </HStack>
          </Box>
          <Box mb={3} width={"100%"} textAlign={"center"}>
            <HStack>
              <Heading size="sm" color={"blue.600"}>
                You answered:{" "}
                <Text
                  as={"span"}
                  bg={"green.600"}
                  color={"#FFF"}
                  p={1}
                  borderRadius={"sm"}
                >
                  {totalCorrectAnswers}
                </Text>{" "}
                correctly
              </Heading>
            </HStack>
          </Box>
          <Box mb={9} width={"100%"} textAlign={"center"}>
            <HStack>
              <Heading size="sm" color={"blue.600"}>
                CELPIP TOTAL SCORE:{" "}
                <Text
                  as={"span"}
                  bg={"green.600"}
                  color={"#FFF"}
                  p={1}
                  borderRadius={"sm"}
                >
                  {totalScore}
                </Text>
              </Heading>
            </HStack>
          </Box>
        </Box>
        <TableContainer w={"90%"}>
          <Table variant="simple" bg={"#FFF"} boxShadow={"2xl"} fontSize={10}>
            <Thead bg={"#CCC"}>
              <Tr>
                <Th>Question</Th>
                <Th>Answer Key</Th>
                <Th>Your Answer</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>
            {ListeningSections.map((section) => (
              <Tbody key={section}>
                {responses
                  .filter((response) => response.questionType === section)
                  .map((response: ReadingAndListeningResponseType, index) => (
                    <Tr key={index}>
                      <Td>
                        Q{index + 1} -{" "}
                        {addSpaceBeforeCaps(response.questionType ?? "")}
                      </Td>
                      <Td>
                        {response.correctAnswer.includes("firebasestorage") ? (
                          <Image
                            src={response.correctAnswer}
                            w={50}
                            mr={4}
                            mt={-1}
                          />
                        ) : (
                          response.correctAnswer
                        )}
                      </Td>
                      <Td>
                        {response.userAnswer.includes("firebasestorage") ? (
                          <Image
                            src={response.userAnswer}
                            w={50}
                            mr={4}
                            mt={-1}
                          />
                        ) : (
                          response.userAnswer
                        )}
                      </Td>
                      <Td>
                        {response.correctAnswer === response.userAnswer ? (
                          <FaCheck color="green" />
                        ) : (
                          <FaTimes color="red" />
                        )}
                      </Td>
                    </Tr>
                  ))}
              </Tbody>
            ))}
          </Table>
        </TableContainer>
      </Container>
    </Box>
  );
};

import { useRef, useEffect } from "react";
import { Box } from "@chakra-ui/react";


export const AudioPlayer: React.FC<{ src: string }> = ({ src }) => {
    const audioRef = useRef<HTMLAudioElement>(null);
  
    useEffect(() => {
      if (audioRef.current) {
        audioRef.current.play().catch(error => {
          console.log("Autoplay prevented:", error);
        });
      }
    }, [src]);
  
    return (
      <Box p={100}>
        <audio controls src={src} ref={audioRef} />
      </Box>
    );
};
export const VideoPlayer: React.FC<{ src: string }> = ({ src }) => {
    const videoRef = useRef<HTMLVideoElement>(null);
  
    useEffect(() => {
      if (videoRef.current) {
        videoRef.current.play().catch(error => {
          console.log("Autoplay prevented:", error);
        });
      }
    }, [src]);
  
    return (
      <Box p={100}>
        <video controls src={src} ref={videoRef} />
      </Box>
    );
};
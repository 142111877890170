import React from "react";
import { Box, Image, Flex } from "@chakra-ui/react";
import { DisplayQuestionContent } from "../../../../../components/DisplayQuestionContent";
import { ListeningQuestionData } from "../../../../../types/listening-types";
import {
  listeningDefaultInstruction,
  listeningDefaultInstructionTwo,
} from "../../../../../utils/helperFuntions";
import { InstructionHeading } from "./DailyLifeConversation";
import { AudioPlayer } from "../../../../../components/AudioAndVideoPlayer";

type ProblemSolvingSectionProps = {
  questionData: ListeningQuestionData;
  stage: number;
};

const ImageDisplay: React.FC<{ src: string }> = ({ src }) => (
  <Image
    alt={"Listening Image"}
    fit={"cover"}
    align={"center"}
    w={"100%"}
    h={"100%"}
    src={src}
  />
);

const NotificationBox: React.FC = () => (
  <Box boxShadow={"md"} bg={"gray.300"} p={9} borderRadius={"md"}>
    <Flex>
      You will hear the next conversation shortly. Click next to continue
      <Image src={"/images/timer-icon.svg"} w={9} mr={4} mt={-1} />
    </Flex>
  </Box>
);

export const ProblemSolvingSection: React.FC<ProblemSolvingSectionProps> = ({
  questionData,
  stage,
}) => {
  const audioOne = questionData.listeningToProblemSolvingAudioOne;
  const audioTwo = questionData.listeningToProblemSolvingAudioTwo;
  const audioThree = questionData.listeningToProblemSolvingAudioThree;

  const audioOneNumberOfQuestion =
    questionData.listeningToProblemSolvingAudioOneNumOfQuestion;
  const audioTwoNumberOfQuestion =
    questionData.listeningToProblemSolvingAudioTwoNumOfQuestion;
  const audioThreeNumberOfQuestion =
    questionData.listeningToProblemSolvingAudioThreeNumOfQuestion;


  const renderContentCaseOne = () => {
    switch (stage) {
      case 0:
        return (
          <DisplayQuestionContent
            content={questionData.listeningToProblemSolvingInstruction}
          />
        );
      case 1:
        return (
          <ImageDisplay src={questionData.listeningToProblemSolvingImage} />
        );
      case 2:
        return (
          <>
            {/* Discussion 1 */}
            <InstructionHeading text={listeningDefaultInstruction} />
            <AudioPlayer src={audioOne} />
          </>
        );
      case 3:
        return (
          <>
            {/* Question 1 */}
            <InstructionHeading text={listeningDefaultInstructionTwo} />
            <AudioPlayer src={questionData.questions[0].question} />
          </>
        );
      case 4:
        return (
          <>
            {/* Question 2 */}
            <InstructionHeading text={listeningDefaultInstructionTwo} />
            <AudioPlayer src={questionData.questions[1].question} />
          </>
        );
      case 5:
        return <NotificationBox />;
      case 6:
        return (
          <>
            {/* Discussion 2 */}
            <InstructionHeading text={listeningDefaultInstruction} />
            <AudioPlayer src={audioTwo} />
          </>
        );
      case 7:
        return (
          <>
            {/* Question 3 */}
            <InstructionHeading text={listeningDefaultInstructionTwo} />
            <AudioPlayer src={questionData.questions[2].question} />
          </>
        );
      case 8:
        return (
          <>
            {/* Question 4 */}
            <InstructionHeading text={listeningDefaultInstructionTwo} />
            <AudioPlayer src={questionData.questions[3].question} />
          </>
        );
      case 9:
        return (
          <>
            {/* Question 5 */}
            <InstructionHeading text={listeningDefaultInstructionTwo} />
            <AudioPlayer src={questionData.questions[4].question} />
          </>
        );
      case 10:
        return <NotificationBox />;
      case 11:
        return (
          <>
            <>
              {/* Discussion 3 */}
              <InstructionHeading text={listeningDefaultInstruction} />
              <AudioPlayer src={audioThree} />
            </>
          </>
        );
      case 12:
        return (
          <>
            {/* Question 6 */}
            <InstructionHeading text={listeningDefaultInstructionTwo} />
            <AudioPlayer src={questionData.questions[5].question} />
          </>
        );
      case 13:
        return (
          <>
            {/* Question 7 */}
            <InstructionHeading text={listeningDefaultInstructionTwo} />
            <AudioPlayer src={questionData.questions[6].question} />
          </>
        );
      case 14:
        return (
          <>
            {/* Question 8 */}
            <InstructionHeading text={listeningDefaultInstructionTwo} />
            <AudioPlayer src={questionData.questions[7].question} />
          </>
        );
      default:
        return null;
    }
  };
  const renderContentCaseTwo = () => {
    switch (stage) {
      case 0:
        return (
          <DisplayQuestionContent
            content={questionData.listeningToProblemSolvingInstruction}
          />
        );
      case 1:
        return (
          <ImageDisplay src={questionData.listeningToProblemSolvingImage} />
        );
      case 2:
        return (
          <>
            {/* Discussion 1 */}
            <InstructionHeading text={listeningDefaultInstruction} />
            <AudioPlayer src={audioOne} />
          </>
        );
      case 3:
        return (
          <>
            {/* Question 1 */}
            <InstructionHeading text={listeningDefaultInstructionTwo} />
            <AudioPlayer src={questionData.questions[0].question} />
          </>
        );
      case 4:
        return (
          <>
            {/* Question 2 */}
            <InstructionHeading text={listeningDefaultInstructionTwo} />
            <AudioPlayer src={questionData.questions[1].question} />
          </>
        );
      case 5:
        return (
          <>
            {/* Question 3 */}
            <InstructionHeading text={listeningDefaultInstructionTwo} />
            <AudioPlayer src={questionData.questions[2].question} />
          </>
        );
      case 6:
        return <NotificationBox />;
      case 7:
        return (
          <>
            {/* Discussion 2 */}
            <InstructionHeading text={listeningDefaultInstruction} />
            <AudioPlayer src={audioTwo} />
          </>
        );
      case 8:
        return (
          <>
            {/* Question 4 */}
            <InstructionHeading text={listeningDefaultInstructionTwo} />
            <AudioPlayer src={questionData.questions[3].question} />
          </>
        );
      case 9:
        return (
          <>
            {/* Question 5 */}
            <InstructionHeading text={listeningDefaultInstructionTwo} />
            <AudioPlayer src={questionData.questions[4].question} />
          </>
        );
      case 10:
        return <NotificationBox />;
      case 11:
        return (
          <>
            {/* Discussion 3 */}
            <InstructionHeading text={listeningDefaultInstruction} />
            <AudioPlayer src={audioThree} />
          </>
        );
      case 12:
        return (
          <>
            {/* Question 6 */}
            <InstructionHeading text={listeningDefaultInstructionTwo} />
            <AudioPlayer src={questionData.questions[5].question} />
          </>
        );
      case 13:
        return (
          <>
            {/* Question 7 */}
            <InstructionHeading text={listeningDefaultInstructionTwo} />
            <AudioPlayer src={questionData.questions[6].question} />
          </>
        );
      case 14:
        return (
          <>
            {/* Question 8 */}
            <InstructionHeading text={listeningDefaultInstructionTwo} />
            <AudioPlayer src={questionData.questions[7].question} />
          </>
        );
      default:
        return null;
    }
  };

  const renderContentCaseThree = () => {
    switch (stage) {
      case 0:
        return (
          <DisplayQuestionContent
            content={questionData.listeningToProblemSolvingInstruction}
          />
        );
      case 1:
        return (
          <ImageDisplay src={questionData.listeningToProblemSolvingImage} />
        );
      case 2:
        return (
          <>
            {/* Discussion 1 */}
            <InstructionHeading text={listeningDefaultInstruction} />
            <AudioPlayer src={audioOne} />
          </>
        );
      case 3:
        return (
          <>
            {/* Question 1 */}
            <InstructionHeading text={listeningDefaultInstructionTwo} />
            <AudioPlayer src={questionData.questions[0].question} />
          </>
        );
      case 4:
        return (
          <>
            {/* Question 2 */}
            <InstructionHeading text={listeningDefaultInstructionTwo} />
            <AudioPlayer src={questionData.questions[1].question} />
          </>
        );
      case 5:
        return (
          <>
            {/* Question 3 */}
            <InstructionHeading text={listeningDefaultInstructionTwo} />
            <AudioPlayer src={questionData.questions[2].question} />
          </>
        );
      case 6:
        return <NotificationBox />;
      case 7:
        return (
          <>
            {/* Discussion 2 */}
            <InstructionHeading text={listeningDefaultInstruction} />
            <AudioPlayer src={audioTwo} />
          </>
        );
      case 8:
        return (
          <>
            {/* Question 4 */}
            <InstructionHeading text={listeningDefaultInstructionTwo} />
            <AudioPlayer src={questionData.questions[3].question} />
          </>
        );
      case 9:
        return (
          <>
            {/* Question 5 */}
            <InstructionHeading text={listeningDefaultInstructionTwo} />
            <AudioPlayer src={questionData.questions[4].question} />
          </>
        );
      case 10:
        return <NotificationBox />;
      case 11:
        return (
          <>
            {/* Discussion 3 */}
            <InstructionHeading text={listeningDefaultInstruction} />
            <AudioPlayer src={audioThree} />
          </>
        );
      case 12:
        return (
          <>
            {/* Question 6 */}
            <InstructionHeading text={listeningDefaultInstructionTwo} />
            <AudioPlayer src={questionData.questions[5].question} />
          </>
        );
      case 13:
        return (
          <>
            {/* Question 7 */}
            <InstructionHeading text={listeningDefaultInstructionTwo} />
            <AudioPlayer src={questionData.questions[6].question} />
          </>
        );
      default:
        return null;
    }
  };

  if (
    Number(audioOneNumberOfQuestion) === 2 &&
    Number(audioTwoNumberOfQuestion) === 3 &&
    Number(audioThreeNumberOfQuestion) === 3
  ) {
    return <Box>{renderContentCaseOne()}</Box>;
  }
  if (
    Number(audioOneNumberOfQuestion) === 3 &&
    Number(audioTwoNumberOfQuestion) === 2 &&
    Number(audioThreeNumberOfQuestion) === 3
  ) {
    return <Box>{renderContentCaseTwo()}</Box>;
  }
  if (
    Number(audioOneNumberOfQuestion) === 3 &&
    Number(audioTwoNumberOfQuestion) === 3 &&
    Number(audioThreeNumberOfQuestion) === 2
  ) {
    return <Box>{renderContentCaseThree()}</Box>;
  }

  return null;
};

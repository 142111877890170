import create from 'zustand';

type AnswerState = {
  userId: string;
  responses: {
    userId: string;
    question: string;
    userAnswer: string;
    correctAnswer: string;
    questionType: string;
  }[];
  setUserId: (id: string) => void;
  addResponse: (response: {
    userId: string;
    question: string;
    userAnswer: string;
    correctAnswer: string;
    questionType: string;
  }) => void;
  updateResponse: (userId: string, question: string, userAnswer: string) => void;
};

export const useAnswerStore = create<AnswerState>((set) => ({
  userId: '',
  responses: [],
  setUserId: (id) => set({ userId: id }),
  addResponse: (response) =>{
    set((state) => ({
      responses: [...state.responses, response],
    }))
  },
  updateResponse: (userId, question, userAnswer) =>{
    set((state) => ({
      responses: state.responses.map((resp) =>
        resp.question === question && resp.userId === userId
          ? { ...resp, userAnswer }
          : resp
      ),
    }))
  },
}));
import { Box, Heading, Link, Text } from "@chakra-ui/react";

export const TermsConditions = () => {
  return (
    <Box width={"100%"} h={"100%"} p={9}>
      <Box p={20} mt={9} width={"80%"}>
        <Heading as="h1" size="2xl" mb={4}>
          Terms & Conditions
        </Heading>

        <Text mb={4}>
          Welcome to PassEnglishTest, a web application specifically designed
          for CELPIP test preparation. By accessing and using our services, you
          agree to the following terms and conditions. These terms govern the
          use of our platform, detailing user rights, responsibilities, and
          legal guidelines. We recommend that you read them thoroughly before
          using our services.
        </Text>

        <Heading as="h2" size="lg" mb={4}>
          1. Acceptance of Terms
        </Heading>
        <Text mb={4}>
          By accessing our platform, you agree to comply with and be bound by
          these Terms & Conditions. If you do not agree with these terms, you
          are prohibited from using our services. Your continued use of the
          platform constitutes acceptance of any changes made to these terms.
        </Text>

        <Heading as="h2" size="lg" mb={4}>
          2. Use of Platform
        </Heading>
        <Text mb={4}>
          Our platform is designed for legal use only. You are responsible for
          ensuring that your use of the platform complies with all applicable
          laws and regulations. Any misuse of the platform, including attempts
          to gain unauthorized access or introducing malicious software, will
          result in immediate termination of access and may lead to legal
          action.
        </Text>
        <Text mb={4}>
          Additionally, we reserve the right to alter, modify, or remove any
          content on the platform without prior notice. While we strive to
          maintain a bug-free and secure platform, we cannot guarantee that the
          platform will always be free from errors or interruptions.
        </Text>

        <Heading as="h2" size="lg" mb={4}>
          3. Intellectual Property Rights
        </Heading>
        <Text mb={4}>
          All content, graphics, logos, layout, design, and infographics on this
          website are protected by intellectual property laws. Unauthorized use
          or reproduction of this content is strictly prohibited and will be
          subject to legal action. The intellectual property rights associated
          with our platform remain our sole property, and users are not granted
          any rights to modify or distribute this content without prior written
          consent.
        </Text>

        <Heading as="h2" size="lg" mb={4}>
          4. AI-Powered Scoring and Feedback
        </Heading>
        <Text mb={4}>
          The AI-powered scoring for Writing and Speaking sections is based on
          the CELPIP scoring guide. While our system aims to provide accurate
          and consistent evaluations, we do not guarantee that the scores
          provided will match your official CELPIP test results. Our AI system
          is designed as a supplementary tool to help you prepare effectively,
          but it is not a substitute for official CELPIP assessments.
        </Text>

        <Heading as="h2" size="lg" mb={4}>
          5. Security
        </Heading>
        <Text mb={4}>
          We manage secure data networks designed to protect your personal
          information. We use all reasonable precautions to safeguard your
          data, but under certain circumstances, we reserve the right to
          disclose your information to trusted third parties or partners for
          legal purposes or to enhance our services. Despite our efforts,
          we cannot guarantee that our platform will be completely free from
          security breaches or unauthorized access.
        </Text>
        <Text mb={4}>
          We provide secure gateways for any debit/credit card transactions.
          However, it is your responsibility to ensure that your financial
          information is handled securely. Any issues arising from payment
          processing should be addressed with your financial institution.
        </Text>

        <Heading as="h2" size="lg" mb={4}>
          6. Refund Policy
        </Heading>
        <Text mb={4}>
          We do not offer refunds once a purchase has been made. Digital goods
          are categorized differently under Consumer Contracts Regulations and
          do not offer the same protections as physical goods. Once a purchase
          is completed, it is final, and no switching between products or
          packages is permitted.
        </Text>
        <Text mb={4}>
          Account deletions are permanent, and any remaining subscription time
          or services will be forfeited. Users are responsible for ensuring that
          they understand and agree to this policy before making any purchases.
        </Text>

        <Heading as="h2" size="lg" mb={4}>
          7. Cancellation of Account
        </Heading>
        <Text mb={4}>
          If you choose to cancel your account, all access to the platform will
          be revoked. Account deletions are permanent, and any remaining
          subscription time or services will be forfeited. Deleting your account
          will not entitle you to a refund for any unused portion of the package
          you purchased.
        </Text>

        <Heading as="h2" size="lg" mb={4}>
          8. Cookies
        </Heading>
        <Text mb={4}>
          Our platform uses cookies to enhance your user experience. Cookies are
          small text files that are stored on your device to help us improve our
          services and provide personalized content. You can manage your cookie
          preferences through your browser settings, but please note that some
          features of our website may not function properly if cookies are
          disabled.
        </Text>

        <Heading as="h2" size="lg" mb={4}>
          9. Third-Party Links
        </Heading>
        <Text mb={4}>
          Our platform may contain links to third-party websites. These links
          are provided for your convenience, but we do not control or endorse
          the content or privacy practices of these websites. We encourage you
          to review the terms and privacy policies of any third-party websites
          you visit. We are not responsible for any harm or damage resulting
          from your use of these third-party websites.
        </Text>

        <Heading as="h2" size="lg" mb={4}>
          10. Modification of Terms
        </Heading>
        <Text mb={4}>
          We reserve the right to modify or update these terms at any time
          without prior notice. Your continued use of the platform constitutes
          acceptance of any changes made. It is your responsibility to review
          these terms periodically to stay informed about any updates or
          modifications.
        </Text>

        <Heading as="h2" size="lg" mb={4}>
          11. Contact Us
        </Heading>
        <Text mb={4}>
          If you have any questions or concerns about these Terms & Conditions,
          please contact us at{" "}
          <Link href="mailto:admin@passenglishtest.com" color={"blue.700"}>
            admin@passenglishtest.com
          </Link>
          . We are here to help and provide any necessary clarifications
          regarding our terms and your rights as a user of our platform.
        </Text>
      </Box>
    </Box>
  );
};

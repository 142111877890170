import * as yup from 'yup';

export type SignUp = {
  company_id?: string;
  firstName: string;
  lastName: string;
  location?: string;
  email: string;
  password: string;
  account_type?: string;
  isActive?: boolean;
  isGoogleLogIn?: boolean;
}

export type SignIn = {
  email: string;
  password: string;
  firstName?: string;
  lastName?: string;
}


export const SignInValidationSchema = yup.object().shape({
  email: yup.string().required('Email is required'),
  password: yup.string().required('Password  is required'),
});
export const SignUpValidationSchema = yup.object().shape({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  email: yup.string().required('Email is required'),
  accountType: yup.string().required('Account Type is required'),
  password: yup.string().required('Password  is required'),
});
export const ForgetPasswordValidationSchema = yup.object().shape({
  email: yup.string().required('Email is required'),
});
export const ResetPasswordValidationSchema = yup.object().shape({
  password: yup.string().required('Password is required'),
  email: yup.string().required('email is required'),
  token: yup.string().required('token is required'),

});
export type RecoverPassword = {
  email: string;
}
export type ResetPassword = {
  password: string;
  email: string;
  token: string;
}
export type ContactUs = {
  subject: string;
  fullName: string;
  email: string;
  message: string;
}

export const contactUsValidationSchema = yup.object().shape({
  subject: yup.string().required('Subject is required'),
  fullName: yup.string().required('Full Name is required'),
  email: yup.string().required('Email is required'),
  message: yup.string().required('Message is required'),
});
import {
  Box,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
  HStack,
  VStack,
  Select,
  Container,
  Flex,
  Icon,
  SimpleGrid,
  FormErrorMessage,
  useToast,
  Textarea,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { WritingTestFormData, WritingTestValidationSchema } from "../schema";
import {  InfoIcon } from "@chakra-ui/icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  addSpaceBeforeCaps,
  testTimeOptions,
} from "../../../../utils/helperFuntions";
import { useParams } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/hooks/useTypedSelector";
import { QType, createQuestionAction, updateQuestionAction } from "../../../../redux/slice/mock-test/questionBankSlice";
import { useUser } from "../../../../hooks/use-user";
import { useQuestionData } from "../../../../hooks/use-question-data";

const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video"],
    ["clean"],
  ],
  clipboard: {
    matchVisual: false,
  },
};

const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
];

export const CreateWritingTestForm = () => {
  const user = useUser();
  const { isSuccess, isError, isLoading, errorMessage } = useAppSelector(
    (state) => state.questionBank,
  );

  const { questionType, testNum } = useParams();
  const dispatch = useAppDispatch();
  const toast = useToast();

  const {
    handleSubmit,
    register,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm<WritingTestFormData>({
    resolver: yupResolver(WritingTestValidationSchema),
    defaultValues: {
      questionsType: questionType || "null",
      questionOne: "",
      questionOneInstructionOne: `Write an email to the department store manager in about 150–200 words. Your email should do the following things:`,
      questionOneInstructionTwo: "",
      questionTwo: "",
      questionTwoInstruction: "Choose the option that you prefer. Why do you prefer your choice? Explain the reasons for your choice. Write about 150-200 words.",
      questionTwoOptionA: "",
      questionTwoOptionB: "",
      questionOneTime: "",
      questionTwoTime: "",
      testNum: testNum,
    },
  });
  const { question, questionData } = useQuestionData(reset,"Writing");


  const handleEditQuestionOne = (content: string) => {
    setValue("questionOne", content, { shouldValidate: true });
  };

  const handleEditQuestionOneInstructionTwo = (content: string) => {
    setValue("questionOneInstructionTwo", content, { shouldValidate: true });
  };

  const handleEditQuestionTwo = (content: string) => {
    setValue("questionTwo", content, { shouldValidate: true });
  };


  const onSubmitHandler = async (data: WritingTestFormData) => {
    try {
      const payLoad = {
        id: question?.id ?? null,
        user: user.id,
        type: "Writing" as QType,
        questionType: "Writing",
        testNum: testNum ?? "",
        data: JSON.stringify({
          questionOne: data.questionOne,
          questionOneInstructionOne: data.questionOneInstructionOne,
          questionOneInstructionTwo: data.questionOneInstructionTwo,
          questionTwo: data.questionTwo,
          questionTwoInstruction: data.questionTwoInstruction,
          questionTwoOptionA: data.questionTwoOptionA,
          questionTwoOptionB: data.questionTwoOptionB,
          questionOneTime: data.questionOneTime,
          questionTwoTime: data.questionTwoTime,
        }),
      };
      
      if (questionData) {
        await dispatch(updateQuestionAction(payLoad));
      } else {
        await dispatch(createQuestionAction(payLoad));
      }
  
      if (isSuccess) {
        toast({
          title: "Question saved successfully.",
          status: "success",
          position: "top-right",
          duration: 9000,
          isClosable: true,
        });
        setTimeout(() => {
          window.location.href = "/admin-writing";
        }, 1000);
      } else if (isError) {
        toast({
          title: "Error",
          description: errorMessage,
          status: "error",
          position: "top-right",
          duration: 9000,
          isClosable: true,
        });
      }
    } catch (error: any) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        position: "top-right",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Box bg="gray.50" minH="100vh">
      <Container
        maxW="container.2xlg"
        bg="white"
        p={8}
        borderRadius="md"
        boxShadow="md"
        mt={9}
        as="form"
        onSubmit={handleSubmit(onSubmitHandler)}
      >
        <Box mb={4}>
          <Heading fontSize={30} mb={2} color="blue.600">
            Part 1 - Writing an Email
          </Heading>
          <Flex
            direction="row"
            justify="space-between"
            align="center"
            bg="gray.200"
            border="1px #CCC solid"
            p={3}
            h={10}
          >
            <Heading size="md">
              {addSpaceBeforeCaps(questionType || "")}
            </Heading>
            <Select
              autoComplete="Question Time"
              placeholder="Select question time"
              bg="#FFF"
              {...register("questionOneTime")}
              width={200}
              required
            >
              {testTimeOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
            <FormErrorMessage>
              {errors.questionOneTime?.message}
            </FormErrorMessage>
          </Flex>

          <Box width={"100%"} boxShadow={"md"} mb={2}>
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8} p={0}>
              {/* Content Box */}
              <VStack
                align="start"
                spacing={5}
                w="full"
                p={3}
                borderRight="1px #CCC solid"
                padding={9}
                overflowY="auto"
              >
                <HStack>
                  <Icon as={InfoIcon} color="blue.500" />
                  <Heading size="sm" color="blue.600">
                    Read the following message.
                  </Heading>
                </HStack>
                <Box w="100%" p={4}>
                  <FormControl mt={4} isInvalid={!!errors.questionOne}>
                    <FormLabel>Question One</FormLabel>
                    <ReactQuill
                      theme="snow"
                      value={watch("questionOne")}
                      onChange={handleEditQuestionOne}
                      modules={modules}
                      formats={formats}
                      style={{ height: 200 }}
                    />
                    <FormErrorMessage>
                      {errors.questionOne?.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
              </VStack>

              {/* Options Box */}
              <VStack
                align="start"
                spacing={5}
                w="full"
                p={3}
                padding={9}
                overflowY="auto"
              >
                <Box pb={50}>
                  <VStack spacing={4} mt={4}>
                    <FormControl mt={2}>
                      <FormLabel>Instruction One</FormLabel>
                      <Textarea
                        placeholder="Instruction"
                        bg={"#FFF"}
                        {...register("questionOneInstructionOne")}
                        required
                      >
                      </Textarea>
                      <FormErrorMessage>
                        {errors.questionOneInstructionOne?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl mt={2}>
                      <FormLabel>Instruction Two</FormLabel>
                      <ReactQuill
                        theme="snow"
                        value={watch("questionOneInstructionTwo")}
                        onChange={handleEditQuestionOneInstructionTwo}
                        modules={modules}
                        formats={formats}
                        style={{ height: 200 }}
                      />
                      <FormErrorMessage>
                        {errors.questionOneInstructionTwo?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </VStack>
                </Box>
              </VStack>
            </SimpleGrid>
          </Box>
        </Box>

        <Box mb={4}>
          <Heading fontSize={30} mb={2} color="blue.600">
            Part 2 - Responding to Survey Questions
          </Heading>
          <Flex
            direction="row"
            justify="space-between"
            align="center"
            bg="gray.200"
            border="1px #CCC solid"
            p={3}
            h={10}
          >
            <Heading size="md">
              {addSpaceBeforeCaps(questionType || "")}
            </Heading>
            <Select
              autoComplete="Question Time"
              placeholder="Select question time"
              bg="#FFF"
              {...register("questionTwoTime")}
              width={200}
              required
            >
              {testTimeOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
            <FormErrorMessage>
              {errors.questionTwoTime?.message}
            </FormErrorMessage>
          </Flex>

          <Box width={"100%"} boxShadow={"md"} mb={2}>
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8} p={0}>
              {/* Content Box */}
              <VStack
                align="start"
                spacing={5}
                w="full"
                borderRight="1px #CCC solid"
                overflowY="auto"
                pb={19}
              >
                <HStack ml={9} mt={3}>
                  <Icon as={InfoIcon} color="blue.500" />
                  <Heading size="sm" color="blue.600">
                    Read the following message.
                  </Heading>
                </HStack>
                <Box w="100%" p={4}>
                  <FormControl mt={4} isInvalid={!!errors.questionTwo}>
                    <FormLabel>Question One</FormLabel>
                    <ReactQuill
                      theme="snow"
                      value={watch("questionTwo")}
                      onChange={handleEditQuestionTwo}
                      modules={modules}
                      formats={formats}
                      style={{ height: 200 }}
                    />
                    <FormErrorMessage>
                      {errors.questionTwo?.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
              </VStack>

              {/* Options Box */}
              <VStack
                align="start"
                spacing={5}
                w="full"
                p={3}
                padding={9}
                overflowY="auto"
              >
                <Box pb={50} width={"100%"}>
                  <VStack spacing={4} mt={4}>
                    <FormControl mt={2}>
                      <FormLabel>Instruction </FormLabel>
                      <Textarea
                        placeholder="Instruction"
                        bg={"#FFF"}
                        {...register("questionTwoInstruction")}
                        required
                      >
                      </Textarea>
                      <FormErrorMessage>
                        {errors.questionTwoInstruction?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl mt={2}>
                      <FormLabel>Option A</FormLabel>
                      <Input
                          placeholder="Option A"
                          {...register("questionTwoOptionA")}
                          required
                        />
                      <FormErrorMessage>
                        {errors.questionTwoOptionA?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl mt={2}>
                      <FormLabel>Option B</FormLabel>
                      <Input
                          placeholder="Option B"
                          {...register("questionTwoOptionB")}
                          required
                        />
                      <FormErrorMessage>
                        {errors.questionTwoOptionB?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </VStack>
                </Box>
              </VStack>
            </SimpleGrid>
          </Box>
        </Box>

        <HStack justify="space-between" mt={10}>
          <Button colorScheme="red" type="reset">
            Reset Form
          </Button>
          <Button type="submit" colorScheme="teal" isLoading={isLoading}>
            Submit Form
          </Button>
        </HStack>
      </Container>
    </Box>
  );
};

import axios from "axios";

import { config } from "../../config";
import { AddNewStudentFormData } from "../../apps/students/schema";
import { getTokenAndConfig } from "./authService";
import { GetStudents } from "../slice/student/studentSlice";

const API_URL = config.baseUrl.default;

const create = async (payload: AddNewStudentFormData, thunkAPI: any) => {
  const response = await axios.post(
    API_URL + "student",
    payload,
    getTokenAndConfig(thunkAPI),
  );
  return response.data;
};
const students = async (thunkAPI: any) => {
  const response = await axios.get(
      API_URL +
        `students`,
      getTokenAndConfig(thunkAPI),
    );
    return response.data;
};
const get = async (payload: GetStudents, thunkAPI: any) => {
    const response = await axios.get(
        API_URL +
          `student/${payload.teacherId}`,
        getTokenAndConfig(thunkAPI),
      );
      return response.data;
};
const remove = async (payload: GetStudents, thunkAPI: any) => {
    const response = await axios.delete(
        API_URL +
          `student/${payload.teacherId}/${payload.studentId}`,
        getTokenAndConfig(thunkAPI),
      );
      return response.data;
};

const studentService = {
  create,
  get,
  remove,
  students,
};

export default studentService;

import React from "react";
import { Box, HStack, Heading, Icon, Spinner } from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";
import {
  MockQuestion,
} from "../../../../types/reading-types";

import { useAppSelector } from "../../../../redux/hooks/useTypedSelector";
import { RadioResponse } from "./RadioResponse";
import { ListeningQuestionType } from "../../../../types/listening-types";

type UserResponseProps = {
  questions: MockQuestion;
  questionType: ListeningQuestionType;
};


export const UserResponse: React.FC<UserResponseProps> = ({ questions, questionType }) => {
  const { user } = useAppSelector((state: { auth: any }) => state.auth);

  if (!questions) {
    return <Spinner />;
  }

  return (
    <Box w="100%">
      <Box mb={9}>
        <HStack>
          <Icon as={InfoIcon} color="blue.500" />
          <Heading size="sm" color={"blue.600"}>
          Choose the sentence that is closest in meaning to the statement.
          </Heading>
        </HStack>
        <Box borderRadius="sm" w="full" mt={2}>
            <React.Fragment>
              <Box w="full">
                  <Box mt={2}>
                  <RadioResponse
                      userId={user.user.id.toString()} 
                      question={questions}
                      questionType={questionType}
                    />
                  </Box>
                </Box>
            </React.Fragment>
         
        </Box>
      </Box>
    </Box>
  );
};
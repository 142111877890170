import { useCallback } from "react";

export const useScrollToPricing = () => {
  const scrollToPricing = useCallback(() => {
    if (window.pricingRef?.current) {
      window.pricingRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, []);

  return scrollToPricing;
};

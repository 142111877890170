import { useState, useEffect } from "react";
import {
  Box,
  Heading,
  FormControl,
  FormLabel,
  Image,
  Button,
  HStack,
  VStack,
  Select,
  Container,
  Flex,
  Icon,
  SimpleGrid,
  FormErrorMessage,
  useToast,
  Textarea,
  Center,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import { SpeakingTestFormData, SpeakingTestValidationSchema } from "../schema";
import { InfoIcon } from "@chakra-ui/icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  addSpaceBeforeCaps,
  testTimeOptionsInSeconds,
} from "../../../../utils/helperFuntions";
import { useParams } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/hooks/useTypedSelector";
import {
  QType,
  createQuestionAction,
  updateQuestionAction,
} from "../../../../redux/slice/mock-test/questionBankSlice";
import { ImageUploader } from "../../../../components/ImageUpload";
import { SpeakingSectionName } from "../../../../types/speaking-type";
import { useUser } from "../../../../hooks/use-user";
import { useQuestionData } from "../../../../hooks/use-question-data";

const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video"],
    ["clean"],
  ],
  clipboard: {
    matchVisual: false,
  },
};

const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
];

export const CreateSpeakingTestForm = () => {
  const user = useUser();
  const { isSuccess, isError, isLoading, errorMessage } = useAppSelector(
    (state) => state.questionBank,
  );
  const [speakingTaskThreeImageUrl, setSpeakingTaskThreeImageUrl] = useState<
    string | null
  >(null);
  const [speakingTaskFourImageUrl, setSpeakingTaskFourImageUrl] = useState<
    string | null
  >(null);
  const [speakingTaskFiveImageUrlOne, setSpeakingTaskFiveImageUrlOne] =
    useState<string | null>(null);
  const [speakingTaskFiveImageUrlTwo, setSpeakingTaskFiveImageUrlTwo] =
    useState<string | null>(null);
  const [speakingTaskFiveImageUrlThree, setSpeakingTaskFiveImageUrlThree] =
    useState<string | null>(null);
  const [speakingTaskNineImageUrl, setSpeakingTaskNineImageUrl] = useState<
    string | null
  >(null);

  const { questionType, testNum } = useParams();
  const dispatch = useAppDispatch();
  const toast = useToast();

  const {
    handleSubmit,
    register,
    setValue,
    watch,
    reset,
    control,
    formState: { errors },
  } = useForm<SpeakingTestFormData>({
    resolver: yupResolver(SpeakingTestValidationSchema),
    defaultValues: {
      questionsType: questionType || "null",
      questionPracticeTask: "",
      questionSpeakingTaskOne: "",
      questionSpeakingTaskTwo: "",
      questionSpeakingTaskThree: "",
      questionSpeakingTaskThreePhoto: "",
      questionSpeakingTaskFour: "",
      questionSpeakingTaskFourPhoto: "",
      questionSpeakingTaskFive: "",
      questionSpeakingTaskFiveInstruction: "",
      questionSpeakingTaskFivePhotoOne: "",
      questionSpeakingTaskFiveTwo: "",
      questionSpeakingTaskFivePhotoTwo: "",
      questionSpeakingTaskFivePhotoThree: "",
      questionSpeakingTaskSix: "",
      questionSpeakingTaskSixInstructionOne: "",
      questionSpeakingTaskSeven: "",
      questionSpeakingTaskSevenInstruction: "",
      questionSpeakingTaskEight: "",
      questionSpeakingTaskEightPhoto: "",
      questionPracticeTaskTime: "",
      questionSpeakingTaskOneTime: "",
      questionSpeakingTaskTwoTime: "",
      questionSpeakingTaskThreeTime: "",
      questionSpeakingTaskFourTime: "",
      questionSpeakingTaskFiveTime: "",
      questionSpeakingTaskSixTime: "",
      questionSpeakingTaskSevenTime: "",
      questionSpeakingTaskEightTime: "",
    },
  });
  const { question, questionData } = useQuestionData(reset, "Speaking");

  const handleEdit = (field: keyof SpeakingTestFormData, content: string) => {
    setValue(field, content, { shouldValidate: true });
  };

  const onSubmitHandler = async (data: SpeakingTestFormData) => {
    const payLoad = {
      id: question?.id ?? null,
      user: user.id,
      type: "Speaking" as QType,
      testNum: testNum ?? "",
      questionType: "Speaking",
      data: JSON.stringify(data),
    };

    if (questionData) {
      await dispatch(updateQuestionAction(payLoad));
    } else {
      await dispatch(createQuestionAction(payLoad));
    }

    if (isSuccess) {
      toast({
        title: "Question saved successfully.",
        status: "success",
        position: "top-right",
        duration: 9000,
        isClosable: true,
      });
      setTimeout(() => {
        window.location.href = "/admin-speaking";
      }, 1000);
    } else if (isError) {
      toast({
        title: "Error",
        description: errorMessage,
        status: "error",
        position: "top-right",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    setValue("questionSpeakingTaskThreePhoto", speakingTaskThreeImageUrl ?? "");
  }, [speakingTaskThreeImageUrl, setValue]);

  useEffect(() => {
    setValue("questionSpeakingTaskFourPhoto", speakingTaskFourImageUrl ?? "");
  }, [speakingTaskFourImageUrl, setValue]);

  useEffect(() => {
    setValue(
      "questionSpeakingTaskFivePhotoOne",
      speakingTaskFiveImageUrlOne ?? "",
    );
  }, [speakingTaskFiveImageUrlOne, setValue]);

  useEffect(() => {
    setValue(
      "questionSpeakingTaskFivePhotoTwo",
      speakingTaskFiveImageUrlTwo ?? "",
    );
  }, [speakingTaskFiveImageUrlTwo, setValue]);

  useEffect(() => {
    setValue(
      "questionSpeakingTaskFivePhotoThree",
      speakingTaskFiveImageUrlThree ?? "",
    );
  }, [speakingTaskFiveImageUrlThree, setValue]);

  useEffect(() => {
    setValue("questionSpeakingTaskEightPhoto", speakingTaskNineImageUrl ?? "");
  }, [speakingTaskNineImageUrl, setValue]);

  useEffect(() => {
    if (questionData) {
      setSpeakingTaskThreeImageUrl(
        questionData?.questionSpeakingTaskThreePhoto ?? "",
      );
      setSpeakingTaskFourImageUrl(
        questionData?.questionSpeakingTaskFourPhoto ?? "",
      );
      setSpeakingTaskFiveImageUrlOne(
        questionData?.questionSpeakingTaskFivePhotoOne ?? "",
      );
      setSpeakingTaskFiveImageUrlTwo(
        questionData?.questionSpeakingTaskFivePhotoTwo ?? "",
      );
      setSpeakingTaskFiveImageUrlThree(
        questionData?.questionSpeakingTaskFivePhotoThree ?? "",
      );
      setSpeakingTaskFiveImageUrlTwo(
        questionData?.questionSpeakingTaskFivePhotoTwo ?? "",
      );
      setSpeakingTaskNineImageUrl(
        questionData?.questionSpeakingTaskEightPhoto ?? "",
      );
    }
  }, [questionData]);

  return (
    <Box bg="gray.50" minH="100vh">
      <Container
        maxW="container.2xlg"
        bg="white"
        p={8}
        borderRadius="md"
        boxShadow="md"
        mt={9}
        as="form"
        onSubmit={handleSubmit(onSubmitHandler)}
      >
        <Heading fontSize={30} mb={12} color="blue.600">
          Create Speaking Test
        </Heading>
        {/* Part 1 */}
        <Box mb={4}>
          <Heading fontSize={30} mb={2} color="blue.600">
            Practice Task
          </Heading>
          <Flex
            direction="row"
            justify="space-between"
            align="center"
            bg="gray.200"
            border="1px #CCC solid"
            p={3}
            h={10}
          >
            <Heading size="md">
              {addSpaceBeforeCaps(questionType || "")}
            </Heading>
            <Select
              autoComplete="Question Time"
              placeholder="Select question time"
              bg="#FFF"
              {...register("questionPracticeTaskTime")}
              width={200}
              required
            >
              {testTimeOptionsInSeconds.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
            <FormErrorMessage>
              {errors.questionPracticeTaskTime?.message}
            </FormErrorMessage>
          </Flex>

          <Box width={"100%"} boxShadow={"md"} mb={2}>
            <VStack
              align="center"
              spacing={5}
              w="full"
              p={3}
              borderRight="1px #CCC solid"
              padding={9}
              overflowY="auto"
            >
              <HStack>
                <Icon as={InfoIcon} color="blue.500" />
                <Heading size="sm" color="blue.600">
                  Read the following message.
                </Heading>
              </HStack>
              <Center>
                <Box w="100%" p={4}>
                  <FormControl mt={4} isInvalid={!!errors.questionPracticeTask}>
                    <FormLabel>Practice Task</FormLabel>
                    <ReactQuill
                      theme="snow"
                      value={watch("questionPracticeTask")}
                      onChange={(content) =>
                        handleEdit("questionPracticeTask", content)
                      }
                      modules={modules}
                      formats={formats}
                      style={{ height: 200 }}
                    />
                    <FormErrorMessage>
                      {errors.questionPracticeTask?.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
              </Center>
            </VStack>
          </Box>
        </Box>

        {/* Part 1 */}
        <Box mb={4}>
          <Heading fontSize={30} mb={2} color="blue.600">
            Task 1: {SpeakingSectionName[1]}
          </Heading>
          <Flex
            direction="row"
            justify="space-between"
            align="center"
            bg="gray.200"
            border="1px #CCC solid"
            p={3}
            h={10}
          >
            <Heading size="md">
              {addSpaceBeforeCaps(questionType || "")}
            </Heading>
            <Select
              autoComplete="Question Time"
              placeholder="Select question time"
              bg="#FFF"
              {...register("questionSpeakingTaskOneTime")}
              width={200}
              required
            >
              {testTimeOptionsInSeconds.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
            <FormErrorMessage>
              {errors.questionSpeakingTaskOneTime?.message}
            </FormErrorMessage>
          </Flex>

          <Box width={"100%"} boxShadow={"md"} mb={2}>
            <VStack
              align="center"
              spacing={5}
              w="full"
              p={3}
              borderRight="1px #CCC solid"
              padding={9}
              overflowY="auto"
            >
              <HStack>
                <Icon as={InfoIcon} color="blue.500" />
                <Heading size="sm" color="blue.600">
                  Read the following message.
                </Heading>
              </HStack>
              <Center>
                <Box w="100%" p={4}>
                  <FormControl
                    mt={4}
                    isInvalid={!!errors.questionSpeakingTaskOne}
                  >
                    <FormLabel>Speaking Task One</FormLabel>
                    <ReactQuill
                      theme="snow"
                      value={watch("questionSpeakingTaskOne")}
                      onChange={(content) =>
                        handleEdit("questionSpeakingTaskOne", content)
                      }
                      modules={modules}
                      formats={formats}
                      style={{ height: 200 }}
                    />
                    <FormErrorMessage>
                      {errors.questionSpeakingTaskOne?.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
              </Center>
            </VStack>
          </Box>
        </Box>

        {/* Part 2 */}
        <Box mb={4}>
          <Heading fontSize={30} mb={2} color="blue.600">
            Task 2: {SpeakingSectionName[2]}
          </Heading>
          <Flex
            direction="row"
            justify="space-between"
            align="center"
            bg="gray.200"
            border="1px #CCC solid"
            p={3}
            h={10}
          >
            <Heading size="md">
              {addSpaceBeforeCaps(questionType || "")}
            </Heading>
            <Select
              autoComplete="Question Time"
              placeholder="Select question time"
              bg="#FFF"
              {...register("questionSpeakingTaskTwoTime")}
              width={200}
              required
            >
              {testTimeOptionsInSeconds.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
            <FormErrorMessage>
              {errors.questionSpeakingTaskTwoTime?.message}
            </FormErrorMessage>
          </Flex>

          <Box width={"100%"} boxShadow={"md"} mb={2}>
            <VStack
              align="center"
              spacing={5}
              w="full"
              p={3}
              borderRight="1px #CCC solid"
              padding={9}
              overflowY="auto"
            >
              <HStack>
                <Icon as={InfoIcon} color="blue.500" />
                <Heading size="sm" color="blue.600">
                  Read the following message.
                </Heading>
              </HStack>
              <Center>
                <Box w="100%" p={4}>
                  <FormControl
                    mt={4}
                    isInvalid={!!errors.questionSpeakingTaskTwo}
                  >
                    <FormLabel>Speaking Task Two</FormLabel>
                    <ReactQuill
                      theme="snow"
                      value={watch("questionSpeakingTaskTwo")}
                      onChange={(content) =>
                        handleEdit("questionSpeakingTaskTwo", content)
                      }
                      modules={modules}
                      formats={formats}
                      style={{ height: 200 }}
                    />
                    <FormErrorMessage>
                      {errors.questionSpeakingTaskTwo?.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
              </Center>
            </VStack>
          </Box>
        </Box>

        {/* Part 3 */}
        <Box mb={4}>
          <Heading fontSize={30} mb={2} color="blue.600">
            Task 3: {SpeakingSectionName[3]}
          </Heading>
          <Flex
            direction="row"
            justify="space-between"
            align="center"
            bg="gray.200"
            border="1px #CCC solid"
            p={3}
            h={10}
          >
            <Heading size="md">
              {addSpaceBeforeCaps(questionType || "")}
            </Heading>
            <Select
              autoComplete="Question Time"
              placeholder="Select question time"
              bg="#FFF"
              {...register("questionSpeakingTaskThreeTime")}
              width={200}
              required
            >
              {testTimeOptionsInSeconds.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
            <FormErrorMessage>
              {errors.questionSpeakingTaskThreeTime?.message}
            </FormErrorMessage>
          </Flex>

          <Box width={"100%"} boxShadow={"md"} mb={2}>
            <VStack
              align="center"
              spacing={5}
              w="full"
              p={3}
              borderRight="1px #CCC solid"
              padding={9}
              overflowY="auto"
            >
              <HStack>
                <Icon as={InfoIcon} color="blue.500" />
                <Heading size="sm" color="blue.600">
                  Read the following message.
                </Heading>
              </HStack>
              <Center>
                <Box w="100%" p={4}>
                  <FormControl
                    mt={4}
                    isInvalid={!!errors.questionSpeakingTaskThree}
                  >
                    <FormLabel>Speaking Task Three</FormLabel>
                    <ReactQuill
                      theme="snow"
                      value={watch("questionSpeakingTaskThree")}
                      onChange={(content) =>
                        handleEdit("questionSpeakingTaskThree", content)
                      }
                      modules={modules}
                      formats={formats}
                      style={{ height: 200 }}
                    />
                    <FormErrorMessage>
                      {errors.questionSpeakingTaskThree?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    mt={14}
                    isInvalid={!!errors.questionSpeakingTaskThreePhoto}
                  >
                    <FormLabel>Photo</FormLabel>
                    <Controller
                      control={control}
                      name="questionSpeakingTaskThreePhoto"
                      defaultValue={speakingTaskThreeImageUrl ?? ""}
                      render={({ field }) => (
                        <ImageUploader
                          setImageUrl={(url) => {
                            field.onChange(url);
                            setSpeakingTaskThreeImageUrl(url);
                          }}
                          oldImage={field.value}
                        />
                      )}
                    />
                    <FormErrorMessage>
                      {errors.questionSpeakingTaskThreePhoto?.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
              </Center>
            </VStack>
          </Box>
        </Box>

        {/* Part 4 */}
        <Box mb={4}>
          <Heading fontSize={30} mb={2} color="blue.600">
            Task 4: {SpeakingSectionName[4]}
          </Heading>
          <Flex
            direction="row"
            justify="space-between"
            align="center"
            bg="gray.200"
            border="1px #CCC solid"
            p={3}
            h={10}
          >
            <Heading size="md">
              {addSpaceBeforeCaps(questionType || "")}
            </Heading>
            <Select
              autoComplete="Question Time"
              placeholder="Select question time"
              bg="#FFF"
              {...register("questionSpeakingTaskFourTime")}
              width={200}
              required
            >
              {testTimeOptionsInSeconds.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
            <FormErrorMessage>
              {errors.questionSpeakingTaskFourTime?.message}
            </FormErrorMessage>
          </Flex>

          <Box width={"100%"} boxShadow={"md"} mb={2}>
            <VStack
              align="center"
              spacing={5}
              w="full"
              p={3}
              borderRight="1px #CCC solid"
              padding={9}
              overflowY="auto"
            >
              <HStack>
                <Icon as={InfoIcon} color="blue.500" />
                <Heading size="sm" color="blue.600">
                  Read the following message.
                </Heading>
              </HStack>
              <Center>
                <Box w="100%" p={4}>
                  <FormControl
                    mt={4}
                    isInvalid={!!errors.questionSpeakingTaskFour}
                  >
                    <FormLabel>Speaking Task Four</FormLabel>
                    <ReactQuill
                      theme="snow"
                      value={watch("questionSpeakingTaskFour")}
                      onChange={(content) =>
                        handleEdit("questionSpeakingTaskFour", content)
                      }
                      modules={modules}
                      formats={formats}
                      style={{ height: 200 }}
                    />
                    <FormErrorMessage>
                      {errors.questionSpeakingTaskFour?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    mt={14}
                    isInvalid={!!errors.questionSpeakingTaskFourPhoto}
                  >
                    <FormLabel>Photo</FormLabel>
                    <Controller
                      control={control}
                      name="questionSpeakingTaskFourPhoto"
                      defaultValue={speakingTaskFourImageUrl ?? ""}
                      render={({ field }) => (
                        <ImageUploader
                          setImageUrl={(url) => {
                            field.onChange(url);
                            setSpeakingTaskThreeImageUrl(url);
                          }}
                          oldImage={field.value}
                        />
                      )}
                    />
                    <FormErrorMessage>
                      {errors.questionSpeakingTaskFourPhoto?.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
              </Center>
            </VStack>
          </Box>
        </Box>

        {/* Part 5 */}
        <Box mb={4}>
          <Heading fontSize={30} mb={2} color="blue.600">
            Task 5: {SpeakingSectionName[5]}
          </Heading>
          <Flex
            direction="row"
            justify="space-between"
            align="center"
            bg="gray.200"
            border="1px #CCC solid"
            p={3}
            h={10}
          >
            <Heading size="md">
              {addSpaceBeforeCaps(questionType || "")}
            </Heading>
            <Select
              autoComplete="Question Time"
              placeholder="Select question time"
              bg="#FFF"
              {...register("questionSpeakingTaskFiveTime")}
              width={200}
              required
            >
              {testTimeOptionsInSeconds.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
            <FormErrorMessage>
              {errors.questionSpeakingTaskFiveTime?.message}
            </FormErrorMessage>
          </Flex>

          <Box width={"100%"} boxShadow={"md"} mb={2}>
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8} p={0}>
              <VStack
                align="start"
                spacing={5}
                w="full"
                p={3}
                borderRight="1px #CCC solid"
                padding={9}
                overflowY="auto"
              >
                <HStack>
                  <Icon as={InfoIcon} color="blue.500" />
                  <Heading size="sm" color="blue.600">
                    Read the following message.
                  </Heading>
                </HStack>
                <Box w="100%" p={4}>
                  <FormControl
                    mt={4}
                    isInvalid={!!errors.questionSpeakingTaskFiveInstruction}
                  >
                    <FormLabel>Speaking Task Five - Instruction</FormLabel>
                    <ReactQuill
                      theme="snow"
                      value={watch("questionSpeakingTaskFiveInstruction")}
                      onChange={(content) =>
                        handleEdit(
                          "questionSpeakingTaskFiveInstruction",
                          content,
                        )
                      }
                      modules={modules}
                      formats={formats}
                      style={{ height: 200 }}
                    />
                    <FormErrorMessage>
                      {errors.questionSpeakingTaskFiveInstruction?.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
                <Box w="100%" p={4}>
                  <FormControl
                    mt={14}
                    isInvalid={!!errors.questionSpeakingTaskFive}
                  >
                    <FormLabel>Speaking Task Five - Question</FormLabel>
                    <Textarea
                      placeholder="Question Speaking Task Five"
                      bg={"#FFF"}
                      {...register("questionSpeakingTaskFive")}
                      required
                    ></Textarea>
                    <FormErrorMessage>
                      {errors.questionSpeakingTaskFive?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    mt={14}
                    isInvalid={!!errors.questionSpeakingTaskFive}
                  >
                    <FormLabel>
                      Speaking Task Five - Suggested Question
                    </FormLabel>
                    <Textarea
                      placeholder="Question Speaking Task Five"
                      bg={"#FFF"}
                      {...register("questionSpeakingTaskFiveTwo")}
                      required
                    ></Textarea>
                    <FormErrorMessage>
                      {errors.questionSpeakingTaskFive?.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
              </VStack>
              <VStack
                align="start"
                spacing={5}
                w="full"
                p={3}
                borderRight="1px #CCC solid"
                padding={9}
                overflowY="auto"
              >
                <FormControl
                  mt={4}
                  isInvalid={!!errors.questionSpeakingTaskFivePhotoOne}
                >
                  <FormLabel>Photo Option One</FormLabel>
                  <Controller
                    control={control}
                    name="questionSpeakingTaskFivePhotoOne"
                    defaultValue={speakingTaskFiveImageUrlOne ?? ""}
                    render={({ field }) => (
                      <ImageUploader
                        setImageUrl={(url) => {
                          field.onChange(url);
                          setSpeakingTaskThreeImageUrl(url);
                        }}
                        oldImage={field.value}
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.questionSpeakingTaskFivePhotoOne?.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  mt={4}
                  isInvalid={!!errors.questionSpeakingTaskFivePhotoTwo}
                >
                  <FormLabel>Photo Option Two</FormLabel>
                  <Controller
                    control={control}
                    name="questionSpeakingTaskFivePhotoTwo"
                    defaultValue={speakingTaskFiveImageUrlTwo ?? ""}
                    render={({ field }) => (
                      <ImageUploader
                        setImageUrl={(url) => {
                          field.onChange(url);
                          setSpeakingTaskThreeImageUrl(url);
                        }}
                        oldImage={field.value}
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.questionSpeakingTaskFivePhotoTwo?.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  mt={4}
                  isInvalid={!!errors.questionSpeakingTaskFivePhotoThree}
                >
                  <FormLabel>Suggested Photo</FormLabel>

                  <Controller
                    control={control}
                    name="questionSpeakingTaskFivePhotoThree"
                    defaultValue={speakingTaskFiveImageUrlThree ?? ""}
                    render={({ field }) => (
                      <ImageUploader
                        setImageUrl={(url) => {
                          field.onChange(url);
                          setSpeakingTaskThreeImageUrl(url);
                        }}
                        oldImage={field.value}
                      />
                    )}
                  />
                  {speakingTaskFiveImageUrlThree && (
                    <Image
                      src={speakingTaskFiveImageUrlThree}
                      mt={4}
                      width={300}
                      height={300}
                    />
                  )}
                  <FormErrorMessage>
                    {errors.questionSpeakingTaskFivePhotoThree?.message}
                  </FormErrorMessage>
                </FormControl>
              </VStack>
            </SimpleGrid>
          </Box>
        </Box>

        {/* Part 6 */}
        <Box mb={4}>
          <Heading fontSize={30} mb={2} color="blue.600">
            Task 6: {SpeakingSectionName[6]}
          </Heading>
          <Flex
            direction="row"
            justify="space-between"
            align="center"
            bg="gray.200"
            border="1px #CCC solid"
            p={3}
            h={10}
          >
            <Heading size="md">
              {addSpaceBeforeCaps(questionType || "")}
            </Heading>
            <Select
              autoComplete="Question Time"
              placeholder="Select question time"
              bg="#FFF"
              {...register("questionSpeakingTaskSixTime")}
              width={200}
              required
            >
              {testTimeOptionsInSeconds.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
            <FormErrorMessage>
              {errors.questionSpeakingTaskSixTime?.message}
            </FormErrorMessage>
          </Flex>

          <Box width={"100%"} boxShadow={"md"} mb={2}>
            <VStack
              align="center"
              spacing={5}
              w="full"
              p={3}
              borderRight="1px #CCC solid"
              padding={9}
              overflowY="auto"
            >
              <HStack>
                <Icon as={InfoIcon} color="blue.500" />
                <Heading size="sm" color="blue.600">
                  Read the following message.
                </Heading>
              </HStack>
              <Center>
                <Box w="100%" p={4}>
                  <FormControl
                    mt={14}
                    isInvalid={!!errors.questionSpeakingTaskSixInstructionOne}
                  >
                    <FormLabel>Instruction</FormLabel>
                    <Textarea
                      placeholder="Instruction"
                      bg={"#FFF"}
                      {...register("questionSpeakingTaskSixInstructionOne")}
                      required
                    ></Textarea>
                    <FormErrorMessage>
                      {errors.questionSpeakingTaskSixInstructionOne?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    mt={4}
                    isInvalid={!!errors.questionSpeakingTaskSix}
                  >
                    <FormLabel>Speaking Task Six - Options</FormLabel>
                    <ReactQuill
                      theme="snow"
                      value={watch("questionSpeakingTaskSix")}
                      onChange={(content) =>
                        handleEdit("questionSpeakingTaskSix", content)
                      }
                      modules={modules}
                      formats={formats}
                      style={{ height: 200 }}
                    />
                    <FormErrorMessage>
                      {errors.questionSpeakingTaskSix?.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
              </Center>
            </VStack>
          </Box>
        </Box>

        {/* Part 7 */}
        <Box mb={4}>
          <Heading fontSize={30} mb={2} color="blue.600">
            Task 7: {SpeakingSectionName[7]}
          </Heading>
          <Flex
            direction="row"
            justify="space-between"
            align="center"
            bg="gray.200"
            border="1px #CCC solid"
            p={3}
            h={10}
          >
            <Heading size="md">
              {addSpaceBeforeCaps(questionType || "")}
            </Heading>
            <Select
              autoComplete="Question Time"
              placeholder="Select question time"
              bg="#FFF"
              {...register("questionSpeakingTaskSevenTime")}
              width={200}
              required
            >
              {testTimeOptionsInSeconds.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
            <FormErrorMessage>
              {errors.questionSpeakingTaskSevenTime?.message}
            </FormErrorMessage>
          </Flex>

          <Box width={"100%"} boxShadow={"md"} mb={2}>
            <VStack
              align="center"
              spacing={5}
              w="full"
              p={3}
              borderRight="1px #CCC solid"
              padding={9}
              overflowY="auto"
            >
              <HStack>
                <Icon as={InfoIcon} color="blue.500" />
                <Heading size="sm" color="blue.600">
                  Read the following message.
                </Heading>
              </HStack>
              <Center>
                <Box w="100%" p={4}>
                  <FormControl
                    mt={14}
                    isInvalid={!!errors.questionSpeakingTaskSevenInstruction}
                  >
                    <FormLabel>Instruction</FormLabel>
                    <Textarea
                      placeholder="Instruction"
                      bg={"#FFF"}
                      {...register("questionSpeakingTaskSevenInstruction")}
                      required
                    ></Textarea>
                    <FormErrorMessage>
                      {errors.questionSpeakingTaskSevenInstruction?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    mt={4}
                    isInvalid={!!errors.questionSpeakingTaskSeven}
                  >
                    <FormLabel>Speaking Task Seven</FormLabel>
                    <ReactQuill
                      theme="snow"
                      value={watch("questionSpeakingTaskSeven")}
                      onChange={(content) =>
                        handleEdit("questionSpeakingTaskSeven", content)
                      }
                      modules={modules}
                      formats={formats}
                      style={{ height: 200 }}
                    />
                    <FormErrorMessage>
                      {errors.questionSpeakingTaskSeven?.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
              </Center>
            </VStack>
          </Box>
        </Box>

        {/* Part 8 */}
        <Box mb={4}>
          <Heading fontSize={30} mb={2} color="blue.600">
            Task 8: {SpeakingSectionName[8]}
          </Heading>
          <Flex
            direction="row"
            justify="space-between"
            align="center"
            bg="gray.200"
            border="1px #CCC solid"
            p={3}
            h={10}
          >
            <Heading size="md">
              {addSpaceBeforeCaps(questionType || "")}
            </Heading>
            <Select
              autoComplete="Question Time"
              placeholder="Select question time"
              bg="#FFF"
              {...register("questionSpeakingTaskEightTime")}
              width={200}
              required
            >
              {testTimeOptionsInSeconds.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
            <FormErrorMessage>
              {errors.questionSpeakingTaskEightTime?.message}
            </FormErrorMessage>
          </Flex>

          <Box width={"100%"} boxShadow={"md"} mb={2}>
            <VStack
              align="center"
              spacing={5}
              w="full"
              p={3}
              borderRight="1px #CCC solid"
              padding={9}
              overflowY="auto"
            >
              <HStack>
                <Icon as={InfoIcon} color="blue.500" />
                <Heading size="sm" color="blue.600">
                  Read the following message.
                </Heading>
              </HStack>
              <Center>
                <Box w="100%" p={4}>
                  <FormControl
                    mt={4}
                    isInvalid={!!errors.questionSpeakingTaskEight}
                  >
                    <FormLabel>Speaking Task Eight</FormLabel>
                    <ReactQuill
                      theme="snow"
                      value={watch("questionSpeakingTaskEight")}
                      onChange={(content) =>
                        handleEdit("questionSpeakingTaskEight", content)
                      }
                      modules={modules}
                      formats={formats}
                      style={{ height: 200 }}
                    />
                    <FormErrorMessage>
                      {errors.questionSpeakingTaskEight?.message}
                    </FormErrorMessage>
                  </FormControl>
                  <FormControl
                    mt={14}
                    isInvalid={!!errors.questionSpeakingTaskEightPhoto}
                  >
                    <FormLabel>Photo</FormLabel>
                    <Controller
                      control={control}
                      name="questionSpeakingTaskEightPhoto"
                      defaultValue={speakingTaskNineImageUrl ?? ""}
                      render={({ field }) => (
                        <ImageUploader
                          setImageUrl={(url) => {
                            field.onChange(url);
                            setSpeakingTaskThreeImageUrl(url);
                          }}
                          oldImage={field.value}
                        />
                      )}
                    />
                    <FormErrorMessage>
                      {errors.questionSpeakingTaskEightPhoto?.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
              </Center>
            </VStack>
          </Box>
        </Box>

        <HStack justify="space-between" mt={10}>
          <Button colorScheme="red" type="reset">
            Reset Form
          </Button>
          <Button type="submit" colorScheme="teal" isLoading={isLoading}>
            Submit Form
          </Button>
        </HStack>
      </Container>
    </Box>
  );
};

import { ChakraProvider } from "@chakra-ui/react";
import React from "react";
import { Outlet } from "react-router-dom";
import { Footer } from "./Footer";
import PublicNabBar from "./PublicNabBar";
import theme from "../theme";



const PublicLayout: React.FC = () => (
  <ChakraProvider theme={theme}>
    <PublicNabBar />
    <Outlet />
    <Footer />
  </ChakraProvider>
);

export default PublicLayout;

import { Route } from "react-router-dom";
import { PackagePlan } from "../apps/package-plan";
import { Students } from "../apps/students";
import { TestResult } from "../apps/result";
import { StudentSettings } from "../apps/settings/StudentSettings";
import { StudentFAQS } from "../apps/settings/StudentFAQS";
import { StudentContactUs } from "../apps/settings/StudentContactUs";


// eslint-disable-next-line import/no-anonymous-default-export
export default [
  <>
   <Route path="/package-plan" element={<PackagePlan />} />
   <Route path="/students" element={<Students />} />
   <Route path="/test-result" element={<TestResult />} />
   <Route path="/student-settings" element={<StudentSettings />} />
   <Route path="/student-contact-us" element={<StudentContactUs />} />
   <Route path="/student-faq" element={<StudentFAQS />} />
   <Route path="/teacher/settings" element={<StudentSettings />} />

  </>,
];
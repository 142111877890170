import React, { FC } from "react";
import { Box } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { ListeningQuestionBankType } from "../schema";
import { CreateListeningPracticeQuestionForm } from "./CreateListeningPracticeQuestionForm";
import { CreateListeningToProblemSolvingForm } from "./CreateListeningToProblemSolvingForm";
import { ListeningToADailyLifeConversationForm } from "./ListeningToADailyLifeConversationForm";
import { ListeningForInformationForm } from "./ListeningForInformationForm";
import { ListeningToANewsItemForm } from "./ListeningToANewsItemForm";
import { ListeningToADiscussionForm } from "./ListeningToADiscussionForm";
import { ListeningToViewpointsForm } from "./ListeningToViewpointsForm";

export const CreateListeningTestForm = () => {
  const { questionType } = useParams<{
    questionType: ListeningQuestionBankType;
    testNum: string;
  }>();

  let FormComponent: FC<any>;

  switch (questionType) {
    case "PracticeQuestion":
      FormComponent = CreateListeningPracticeQuestionForm;
      break;
    case "ListeningToProblemSolving":
      FormComponent = CreateListeningToProblemSolvingForm;
      break;
    case "ListeningToADailyLifeConversation":
      FormComponent = ListeningToADailyLifeConversationForm;
      break;
    case "ListeningForInformation":
      FormComponent = ListeningForInformationForm;
      break;
    case "ListeningToANewsItem":
      FormComponent = ListeningToANewsItemForm;
      break;
    case "ListeningToADiscussion":
      FormComponent = ListeningToADiscussionForm;
      break;
      case "ListeningToViewpoints":
      FormComponent = ListeningToViewpointsForm;
      break;
    default:
      FormComponent = () => <Box>Form not found</Box>;
      break;
  }

  return (
    <Box pr={3}>
      <FormComponent />
    </Box>
  );
};

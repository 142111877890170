import React, { useState, useEffect } from "react";
import { Text } from "@chakra-ui/react";

type CountdownTimerProps = {
  time: number;
  onTimeUp: () => void;
  timeType?: "Minute" | "Second";
  customText?:string;
  stage?:number;
};

export const CountdownTimer: React.FC<CountdownTimerProps> = ({
  time,
  onTimeUp,
  timeType = "Minute",
  customText = "",
  stage,
}) => {
  const initialTime = timeType === "Minute" ? time * 60 : time;
  const [secondsLeft, setSecondsLeft] = useState(initialTime);

  useEffect(() => {
    setSecondsLeft(initialTime);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [time, timeType, stage]);

  useEffect(() => {
    const timerId = setInterval(() => {
      setSecondsLeft((prevSeconds) => prevSeconds - 1);
    }, 1000);

    return () => clearInterval(timerId);
  }, []);

  useEffect(() => {
    if (secondsLeft <= 0) {
      setSecondsLeft(0);
      onTimeUp();
    }
  }, [secondsLeft, onTimeUp]);

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  return (
    <Text>
      {timeType === "Minute" ? "Time remaining" : `${customText} Time`}:{" "}
      <Text
        as={"span"}
        fontWeight={600}
        color={secondsLeft < 60 ? "red" : "#000"}
      >
        {timeType === "Minute" ? formatTime(secondsLeft) : `${secondsLeft} seconds`}
      </Text>
    </Text>
  );
};

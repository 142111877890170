import React from "react";
import { Box, HStack, Heading, Text, VStack } from "@chakra-ui/react";
import { DisplayQuestionContent } from "../../../components/DisplayQuestionContent";
import { Feedback } from "../../../types/speaking-type";

type SpeakingResultAnswerProps = {
  feedback: Feedback;
};

export const SpeakingResultAnswer: React.FC<SpeakingResultAnswerProps> = ({
  feedback
}) => {
  return (
    <Box p={4}>
    <Box mb={3}>
      <HStack>
        <Heading size="lg" color={"blue.600"}>
          Speaking Mock Test Result
        </Heading>
      </HStack>
    </Box>
    {feedback && (
      <>
        <Box mb={5}>
        <Heading size="sm" color={"blue.600"} mt={2} mb={2}>
        Overall Score:{" "}
        <Text
          as={"span"}
          bg={"green.600"}
          color={"#FFF"}
          p={1}
          borderRadius={"sm"}
        >
          {Math.round(feedback.overallScore)}
        </Text>
      </Heading>
          <Heading size="sm" color={"blue.600"} mb={3}>
            Overall Feedback
          </Heading>
          <Text mb={3}>{feedback.overallFeedback}</Text>
          <Text fontWeight="bold">Suggestions:</Text>
          <DisplayQuestionContent
            content={feedback.overallSuggestions}
          />
        </Box>
        <VStack spacing={4} align="start">
          {feedback.allScore.map((item, index) => (
            <Box
              key={index}
              p={4}
              borderWidth="1px"
              borderRadius="md"
              w="full"
            >
              <Heading size="sm" color={"blue.600"} mb={3}>
                Question {item.questionNumber}
              </Heading>
              <Text>
                <strong>Score:</strong> {item.score}
              </Text>
              {/* <Text>
                <strong>Feedback:</strong> {item.feedback}
              </Text>
              <Text fontWeight="bold">Suggestions:</Text> */}
              <DisplayQuestionContent content={item.suggestions} />
            </Box>
          ))}
        </VStack>
      </>
    )}
  </Box>
  );
};

import {
  Box,
  Button,
  Heading,
  SimpleGrid,
  Text,
  Stack,
  Icon,
  Center,
} from "@chakra-ui/react";
import { useState } from "react";
import { formatNumber } from "../../utils/helperFuntions";
import { FaCheck, FaTimes } from "react-icons/fa";

type PackageCardProps = {
  title: string;
  value: number;
  pricePerStudent: number;
  price: number;
  selected: boolean;
  onClick: () => void;
  bgColor: string;
  isPopular?: boolean;
};

const PackageCard: React.FC<PackageCardProps> = ({
  title,
  value,
  pricePerStudent,
  price,
  selected,
  onClick,
  bgColor,
  isPopular,
}) => {
  return (
    <Box
      as="button"
      onClick={onClick}
      borderWidth={selected ? "2px" : ""}
      borderColor={selected ? "#319795" : ""}
      borderRadius="md"
      p={7}
      textAlign="left"
      boxShadow="2xl"
      bgColor={bgColor}
      position="relative"
      _before={{
        content: isPopular ? '"POPULAR"' : '""',
        position: "absolute",
        top: "-10px",
        left: "50%",
        transform: "translateX(-50%)",
        bgColor: isPopular ? "#d53f8c" : "",
        color: "white",
        padding: "2px 5px",
        borderRadius: "md",
        fontSize: "sm",
      }}
    >
      <Heading fontSize="xl" mb={4} textAlign={"center"}>
        {title}
      </Heading>
      <Text fontWeight="bold" fontSize="2xl" textAlign={"center"}>
        USD {formatNumber(price)}
      </Text>
      <Text fontSize="md" mb={4} textAlign={"center"}>
        USD {pricePerStudent} Per student
      </Text>
      <Text mb={2}>
        <Icon as={FaCheck} color={"green"} /> 60 Days Student Validity
      </Text>
      <Text mb={2}>
        <Icon as={FaCheck} color={"green"} /> Scored Mock Test
      </Text>
      <Text mb={2}>
        {title === "Basic" ? (
          <Icon as={FaTimes} color={"red"} />
        ) : (
          <Icon as={FaCheck} color={"green"} />
        )}{" "}
        Manage Questions
      </Text>
      <Text mb={2}>
        {title === "Basic" ? (
          <Icon as={FaTimes} color={"red"} />
        ) : (
          <Icon as={FaCheck} color={"green"} />
        )}{" "}
        Manage Student Test Result
      </Text>
      <Text mb={2}>
        <Icon as={FaCheck} color={"green"} /> 1 Year Plan Validity
      </Text>
      <Center>
        <Button mt={4} bg="#319795" color="white">
          Upgrade Plan
        </Button>
      </Center>
    </Box>
  );
};

export const TeacherPackagePlan: React.FC = () => {
  const [selectedPlan, setSelectedPlan] = useState<number>(200);

  const plans = [
    {
      title: "Basic",
      value: 60,
      pricePerStudent: 15,
      price: 200,
      bgColor: "pink.50",
    },
    {
      title: "Essential",
      value: 60,
      pricePerStudent: 12,
      price: 400,
      bgColor: "blue.50",
      isPopular: true,
    },
    {
      title: "Growth",
      value: 60,
      pricePerStudent: 8,
      price: 1000,
      bgColor: "green.50",
    },
  ];

  return (
    <Stack spacing={8} p={4} w={["full", "90%"]} mx="auto">
      <Box boxShadow="md" p={[6, 8]} rounded="md" bg="white">
        <Heading as="h1" size="xl" textAlign="center" mb={20}>
          Select Package
        </Heading>
        <SimpleGrid columns={[1, null, 3]} spacing={5}>
          {plans.map((plan) => (
            <PackageCard
              key={plan.price}
              title={plan.title}
              value={plan.value}
              pricePerStudent={plan.pricePerStudent}
              price={plan.price}
              selected={selectedPlan === plan.price}
              onClick={() => setSelectedPlan(plan.price)}
              bgColor={plan.bgColor}
              isPopular={plan.isPopular}
            />
          ))}
        </SimpleGrid>
      </Box>
    </Stack>
  );
};

import { Box, Heading, Link, Text } from "@chakra-ui/react";

export const PrivacyPolicy = () => {
  return (
    <Box width={"100%"} h={"100%"} p={9}>
      <Box p={8} mt={9}>
        <Heading as="h1" size="2xl" mb={4}>
          Privacy Policy
        </Heading>

        <Text mb={4}>
          Welcome to PassEnglishTest, a web application specifically designed
          for CELPIP test preparation. We are committed to protecting your
          personal information and your right to privacy. If you have any
          questions or concerns about this policy or our practices with regards
          to your personal information, please contact us at{" "}
          <Link href="mailto:admin@passenglishtest.com" color={"blue.700"}>
            admin@passenglishtest.com
          </Link>
          .
        </Text>
        <Text mb={4}>
          When you visit our website and use our services, you trust us with
          your personal information. We take your privacy seriously and strive
          to explain how we collect, use, and protect your data in the clearest
          way possible. Please take some time to read through this policy
          carefully, as it is important. If you do not agree with any terms in
          this policy, please discontinue using our services.
        </Text>

        <Heading size="md" mb={4}>
          1. What Information Do We Collect?
        </Heading>
        <Text mb={4}>
          We collect personal information that you voluntarily provide to us
          when registering for our services, expressing interest in obtaining
          information about our products, or otherwise contacting us. The
          personal information we collect may include your name, email address,
          payment information, and social media login data.
        </Text>
        <Text mb={4}>
          Additionally, we automatically collect certain information when you
          visit our services, such as IP address, browser and device
          characteristics, operating system, and usage data. This information is
          primarily needed to maintain the security and operation of our
          services and for our internal analytics and reporting purposes.
        </Text>

        <Heading size="md" mb={4}>
          2. How Do We Use Your Information?
        </Heading>
        <Text mb={4}>
          We process your information for purposes based on legitimate business
          interests, the fulfillment of our contract with you, compliance with
          our legal obligations, and/or your consent. We use the information we
          collect or receive to facilitate account creation, send you marketing
          and promotional communications, manage your orders, post testimonials,
          request feedback, protect our services, and respond to legal requests.
        </Text>

        <Heading size="md" mb={4}>
          3. Will Your Information Be Shared With Anyone?
        </Heading>
        <Text mb={4}>
          We only share information with your consent, to comply with laws, to
          provide you with services, to protect your rights, or to fulfill
          business obligations. We may share your data with third-party vendors,
          service providers, or agents who perform services for us or on our
          behalf, and require access to such information to do that work.
        </Text>

        <Heading size="md" mb={4}>
          4. Who Will Your Information Be Shared With?
        </Heading>
        <Text mb={4}>
          We only share and disclose your information with the following third
          parties: payment processors (e.g., Stripe, PayPal), cloud computing
          services (e.g., AWS), social media login providers (e.g., Google,
          Facebook), and other service providers necessary for our operations.
        </Text>

        <Heading size="md" mb={4}>
          5. Do We Use Cookies and Other Tracking Technologies?
        </Heading>
        <Text mb={4}>
          Yes, we may use cookies and similar tracking technologies to collect
          and store your information. You can manage your cookie preferences
          through your browser settings, but disabling cookies may affect your
          experience on our platform.
        </Text>

        <Heading size="md" mb={4}>
          6. How Do We Handle Your Social Logins?
        </Heading>
        <Text mb={4}>
          If you choose to register or log in to our services using a social
          media account, we may have access to certain information about you,
          such as your name, email address, and profile picture. We use this
          information only for the purposes described in this policy.
        </Text>

        <Heading size="md" mb={4}>
          7. How Long Do We Keep Your Information?
        </Heading>
        <Text mb={4}>
          We retain your personal information for as long as necessary to
          fulfill the purposes outlined in this privacy policy unless otherwise
          required by law. When we have no ongoing legitimate business need to
          process your personal information, we will delete or anonymize it.
        </Text>

        <Heading size="md" mb={4}>
          8. How Do We Keep Your Information Safe?
        </Heading>
        <Text mb={4}>
          We aim to protect your personal information through a system of
          organizational and technical security measures. However, we cannot
          guarantee that the internet is 100% secure, and you should only access
          the services within a secure environment.
        </Text>

        <Heading size="md" mb={4}>
          9. What Are Your Privacy Rights?
        </Heading>
        <Text mb={4}>
          Depending on your region, you may have certain rights regarding your
          personal information, such as the right to access, rectify, or delete
          your data. You can exercise these rights by contacting us at{" "}
          <Link href="mailto:admin@passenglishtest.com" color={"blue.700"}>
            admin@passenglishtest.com
          </Link>
          .
        </Text>

        <Heading size="md" mb={4}>
          10. Data Breach
        </Heading>
        <Text mb={4}>
          In the event of a data breach, we will promptly investigate and notify
          the applicable Supervisory Authority within 72 hours, if required by
          law. We will also inform affected users if they are likely to be at
          risk or serious harm due to the breach.
        </Text>

        <Heading size="md" mb={4}>
          11. Controls for Do-Not-Track Features
        </Heading>
        <Text mb={4}>
          Most web browsers and some mobile operating systems include a
          Do-Not-Track (“DNT”) feature or setting. As there is no uniform
          technology standard for recognizing DNT signals, we do not currently
          respond to DNT browser signals.
        </Text>

        <Heading size="md" mb={4}>
          12. Do California Residents Have Specific Privacy Rights?
        </Heading>
        <Text mb={4}>
          Yes, if you are a resident of California, you are granted specific
          rights regarding access to your personal information. California Civil
          Code Section 1798.83 allows you to request and obtain information
          about our disclosure of your personal data to third parties for their
          direct marketing purposes.
        </Text>

        <Heading size="md" mb={4}>
          13. Do We Make Updates to This Policy?
        </Heading>
        <Text mb={4}>
          Yes, we will update this policy as necessary to stay compliant with
          relevant laws. The updated version will be effective as soon as it is
          accessible. We encourage you to review this policy frequently to stay
          informed about how we are protecting your information.
        </Text>

        <Heading size="md" mb={4}>
          14. How Can You Contact Us About This Policy?
        </Heading>
        <Text mb={4}>
          If you have questions or comments about this policy, you may email us
          at{" "}
          <Link href="mailto:admin@passenglishtest.com" color={"blue.700"}>
            admin@passenglishtest.com
          </Link>{" "}
          or by post to:
          <br />
          PassEnglishTest
          <br />
          London, Ontario, Canada
        </Text>

        <Heading size="md" mb={4}>
          15. How Can You Review, Update, or Delete the Data We Collect From You?
        </Heading>
        <Text mb={4}>
          Based on the laws of some countries, you may have the right to request
          access to the personal information we collect from you, change that
          information, or delete it in some circumstances. To request to review,
          update, or delete your personal information, please submit a request
          via email to{" "}
          <Link href="mailto:admin@passenglishtest.com" color={"blue.700"}>
            admin@passenglishtest.com
          </Link>
          . We will respond to your request within 30 days.
        </Text>
      </Box>
    </Box>
  );
};

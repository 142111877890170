import React, { FC } from "react";
import { TestResult } from "../../types/test-result-type";
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Spacer,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Feedback } from "../../types/speaking-type";
import { SpeakingResultAnswer } from "../test/speaking/SpeakingResultAnswer";

type SpeakingResultPreviewProps = {
  result: TestResult;
};

export const SpeakingResultPreview: FC<SpeakingResultPreviewProps> = ({
  result,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const feedbackData = JSON.parse(
    result.feedback,
  ) as unknown as Feedback;

  return (
    <Box>
      <Button onClick={onOpen} variant='outline' colorScheme='blue' w={170}>
        <Text ml={1} mr={1}>
          Speaking Result
        </Text>
      </Button>

      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        size={"3xl"}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
          <SpeakingResultAnswer feedback={feedbackData} />
          </ModalBody>

          <ModalFooter>
            <Flex
              width={"100%"}
              borderRadius="lg"
              align="center"
              justify="space-between"
            >
              <Button variant="outline" padding={"0px 40px"} onClick={onClose}>
                Cancel
              </Button>
              <Spacer />
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

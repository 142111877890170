import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import readingTestService from "../../service/mockTestService";
import { UserWritingResponseSchemaType } from "../../../apps/test/writing/user-response/schema";
import { handleRejected, initialState } from "./mockTestSlice";

export type WritingMockTestData = {
  data?:any
  questionOneScore: string;
  questionOneFeedback: string;
  questionOneSuggestions: string;
  questionTwoScore: string;
  questionTwoFeedback: string;
  questionTwoSuggestions: string;
};

export const submitMockTestWritingAnswerAction = createAsyncThunk(
  'auth/send-mock-test-answer',
  async (data: UserWritingResponseSchemaType, thunkAPI) => {
    try {
      return await readingTestService.sendUserWritingResponse(data, thunkAPI);
    } catch (error: any) {
      let message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();

      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const writingMockTestSlice = createSlice({
  name: "writingMockTest",
  initialState,
  reducers: {
    resetState: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(submitMockTestWritingAnswerAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(submitMockTestWritingAnswerAction.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.data = action?.payload?.data as WritingMockTestData;
      })
      .addCase(submitMockTestWritingAnswerAction.rejected, (state, action) => {
        handleRejected(state, action);
      });
  },
});

export const { resetState } = writingMockTestSlice.actions;
export default writingMockTestSlice.reducer;

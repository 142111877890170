import { Route } from "react-router-dom";
import { CancelPayment } from "../apps/stripe/CancelPayment";
import { PaymentSuccess } from "../apps/stripe/PaymentSuccess";


// eslint-disable-next-line import/no-anonymous-default-export
export default [
  <>
   <Route path="/payment-success" element={<PaymentSuccess />} />
   <Route path="/cancel-payment" element={<CancelPayment />} />
  </>,
];

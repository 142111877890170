import { useEffect, useState } from "react";

export const useDeviceType = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    //@ts-ignore
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const isMobileDevice =
      /android|iPad|iPhone|iPod|blackberry|iemobile|opera mini/i.test(userAgent);

    setIsMobile(isMobileDevice);
  }, []);

  return isMobile;
};

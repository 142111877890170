import { ComponentStyleConfig } from "@chakra-ui/react";

export const ReiButton: ComponentStyleConfig = {
  baseStyle: {
    fontSize: "14px",
    padding: "24px 20px",
  },
  variants: {
    outline: {
      borderColor: "rei.black",
    },
    unstyled: {
      color: "rei.black",
      _hover: {
        color: "rei.dark_navy",
      },
    },
    white: {
      bg: "rei.white",
      color: "rei.black",
      _hover: {
        bg: "#edf2f7",
      },
    },
    gray: {
      bg: "#edf2f7",
      color: "#000",
      _hover: {
        bg: "#edf2f7",
      },
    },
    solid: {
      color: "rei.white",
      bg: "#319795",
      _hover: {
        bg: "teal.600",
      },
    },
    custom: {
      bg: "#334267",
      color: "#FFF",
      _hover: {
        bg: "#0C0C33",
      },
    },
  },
};

import React from "react";
import { Box, Flex, HStack, Heading, Icon, VStack } from "@chakra-ui/react";
import { ListeningQuestionData, ListeningQuestionType } from "../../../../../types/listening-types";
import { listeningDefaultInstructionThree } from "../../../../../utils/helperFuntions";
import { useAppSelector } from "../../../../../redux/hooks/useTypedSelector";
import { InfoIcon } from "@chakra-ui/icons";
import { DropDownResponse } from "../DropDownResponse";

type ListeningToANewsItemUserResponseProps = {
  questionData: ListeningQuestionData;
  stage: number;
};

export const ListeningToANewsItemUserResponse: React.FC<
  ListeningToANewsItemUserResponseProps
> = ({ questionData, stage }) => {
  const { user } = useAppSelector((state: { auth: any }) => state.auth);

  const renderUserResponse = (index: number) => {
    return (
      <>
        {index === 3 && (
          <>
            <HStack>
                  <Icon as={InfoIcon} color="blue.500" />
                  <Heading size="sm" color="blue.600">
                  {listeningDefaultInstructionThree}
                  </Heading>
                </HStack>
            {questionData.questions.map((question, index) => (
              <React.Fragment key={index}>
                <Box w="full">
                  <Flex mt={0}>
                    {index+1}.
                   <Box mt={2} ml={-3}>
                   <DropDownResponse
                      userId={user.user.id.toString()}
                      question={question}
                      questionType={questionData.questionsType as ListeningQuestionType}
                    />
                   </Box>
                  </Flex>
                </Box>
              </React.Fragment>
            ))}
          </>
        )}
      </>
    );
  };

  if (stage === 3) {
    return (
      <VStack
        align="start"
        spacing={5}
        w="full"
        p={3}
        bg="#f7fafc"
        minH="90vh"
        maxH="90vh"
        borderBottom="1px #CCC solid"
        ml={-2}
        overflowY="auto"
      >
        {renderUserResponse(stage)}
      </VStack>
    );
  }

  return null;
};

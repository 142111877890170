import { Flex } from "@chakra-ui/react";
import { GlobalContactUs } from "../contactus/GlobalContactUs";

export const StudentContactUs = () => {
  return (
    <Flex minH={"100vh"} pt={0} direction="column">
      <GlobalContactUs />
    </Flex>
  );
};

import React, { useMemo, useState } from "react";
import { Box, Flex, Select, Text } from "@chakra-ui/react";
import { MockQuestion } from "../../../../types/reading-types";
import { shuffleOptions } from "../../../../utils/helperFuntions";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useAnswerStore } from "../../../../redux/zustand-store";
import { userAnswerSchema, UserAnswerSchema } from "../../reading/schema";
import { ListeningQuestionType } from "../../../../types/listening-types";

type StaticSentenceProps = {
  userId: string;
  question: MockQuestion;
  isDisplayOptions?: boolean;
  questionType: ListeningQuestionType;
};

export const DropDownResponse: React.FC<StaticSentenceProps> = ({
  userId,
  question,
  isDisplayOptions = true,
  questionType,
}) => {
  const { responses, addResponse, updateResponse } = useAnswerStore();
  const { handleSubmit, setValue } = useForm({
    resolver: yupResolver(userAnswerSchema),
  });

  const [selectedOption, setSelectedOption] = useState<string | null>(null);

  const options = useMemo(() => {
    let optionsArray = [
      question.correctAnswer,
      question.optionB,
      question.optionC,
      question.optionD,
      question.optionE ?? "",
    ];
    optionsArray = shuffleOptions(optionsArray);
    return optionsArray;
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [question, questionType]);

  const onSubmit = (data: UserAnswerSchema) => {

    const existingResponse = responses.find(
      (response) =>
        response.userId === userId &&
        response.question === question.question,
    );
    

    if (existingResponse) {
      updateResponse(userId, question.question, data.userAnswer);
    } else {
      addResponse({
        userId,
        question: question.question,
        userAnswer: data.userAnswer,
        correctAnswer: question.correctAnswer,
        questionType: questionType || "",
      });
    }
  };

  const handleChange = (value: string) => {
    setSelectedOption(value);
    setValue("userAnswer", value);
    handleSubmit(onSubmit)();
  };

  return (
    <Box p={1} mt={-5}>
      <Flex p={3}>
      <Text mb={2} mr={1} fontSize={14}>
            {question.question}
          </Text>
        {isDisplayOptions ? (
          <Select
            placeholder=" "
            maxW={200}
            fontSize={12}
            h={7}
            borderRadius={"sm"}
            bg={"#FFF"}
            style={{
              color: selectedOption ? "green" : "black",
            }}
            onChange={(e) => handleChange(e.target.value)}
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            overflow="hidden"
          >
            {options.map((option, index) => (
              <option
                key={index}
                value={option}
              >
                {option}
              </option>
            ))}
          </Select>
        ) : (
          ""
        )}
      </Flex>
    </Box>
  );
};

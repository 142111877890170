import { Flex} from "@chakra-ui/react";
import { FaqContent } from "../home/FaqContent";

export const StudentFAQS = () => {
  return (
    <Flex minH={"100vh"} pt={0} direction="column">
      <FaqContent />
    </Flex>
  );
};

import * as yup from 'yup';

export type ListeningQuestionBankType = 
  | "PracticeQuestion" 
  | "ListeningToProblemSolving" 
  | "ListeningToADailyLifeConversation" 
  | "ListeningForInformation" 
  | "ListeningToANewsItem" 
  | "ListeningToADiscussion" 
  | "ListeningToViewpoints" 
  

export type ListeningQuestion = {
  id: number,
  questionType: string,
  testNum: string,
  user: number,
  data: any;
  createAt: string,
  UpdateAt: string,
};
export type Question = {
  question: string;
  correctAnswer: string;
  optionB: string;
  optionC: string;
  optionD: string;
  optionE?: string;
};

export type QuestionData = {
  questionsType: string;
  bodyContent: string;
  listeningToProblemSolvingInstruction: string;
  listeningToProblemSolvingImage: string;
  listeningToProblemSolvingAudioOne: string;
  listeningToProblemSolvingAudioTwo: string;
  listeningToProblemSolvingAudioThree: string;
  time: string;
  questions: Question[];
};


export const ListeningTestValidationSchema = yup.object().shape({
  questionsType: yup.string().optional(),
  testNum: yup.string().optional(),
  bodyContent: yup.string().optional(),
  listeningToProblemSolvingInstruction: yup.string().optional(),
  listeningToProblemSolvingImage: yup.string().optional(),
  listeningToProblemSolvingAudioOne: yup.string().optional(),
  listeningToProblemSolvingAudioOneNumOfQuestion: yup.string().optional(),
  listeningToProblemSolvingAudioTwo: yup.string().optional(),
  listeningToProblemSolvingAudioTwoNumOfQuestion: yup.string().optional(),
  listeningToProblemSolvingAudioThree: yup.string().optional(),
  listeningToProblemSolvingAudioThreeNumOfQuestion: yup.string().optional(),

  listeningDefaultNumOfQuestion: yup.string().optional(),
  listeningInstructionOne: yup.string().optional(),
  listeningInstructionTwo: yup.string().optional(),


  time: yup.string().required("Time is required"),
  questions: yup.array().of(
    yup.object().shape({
      question: yup.string().optional(),
      correctAnswer: yup.string().optional(),
      optionB: yup.string().optional(),
      optionC: yup.string().optional(),
      optionD: yup.string().optional(),
      optionE: yup.string().optional(),
    })
  ),
});

export type ListeningTestFormData = yup.InferType<typeof ListeningTestValidationSchema>;

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import readingTestService from "../../service/mockTestService";

export type CreateMockTestState = {
  data: any;
  isError: boolean;
  isSuccess: boolean;
  isLoading: boolean;
  errorMessage: string;
};

export const initialState: CreateMockTestState = {
  data: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  errorMessage: "",
};

export type ResultPayload = {
   testID?: string,
   testNum: string,
   userID: string,
   testType: string,
   section: string,
   score: string,
   overallScore: string,
   feedback: string,
}

export type GetMockTestResult = {
  testNum: string,
  userID: string,
}
export const handleRejected = (state: CreateMockTestState, action: any) => {
  state.isLoading = false;
  state.isError = true;
  state.errorMessage = action.payload as string;
};


export const sendMockTestResultAction = createAsyncThunk('post/mock-test-result', async (payload: ResultPayload, thunkAPI) => {
  try {
    return await readingTestService.sendMockTestResult(payload,thunkAPI);
  } catch (error: any) {
    let message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

    return message;
  }
});
export const getMockTestResultAction = createAsyncThunk('get/mock-test-result', async (payload: GetMockTestResult, thunkAPI) => {
  try {
    return await readingTestService.getMockTestResult(payload,thunkAPI);
  } catch (error: any) {
    let message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

    return message;
  }
});

export const mockTestResultSlice = createSlice({
  name: "mockTestResult",
  initialState,
  reducers: {
    MockTestTestResult: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(sendMockTestResultAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(sendMockTestResultAction.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.isError = false
        state.data = action?.payload?.data;
      })
      .addCase(sendMockTestResultAction.rejected, (state, action) => {
        handleRejected(state, action);
      })
      .addCase(getMockTestResultAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getMockTestResultAction.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.isError = false
        state.data = action?.payload?.data;
      })
      .addCase(getMockTestResultAction.rejected, (state, action) => {
        handleRejected(state, action);
      });
  },
});

export const { MockTestTestResult } = mockTestResultSlice.actions;
export default mockTestResultSlice.reducer;
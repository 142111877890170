import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Heading,
  VStack,
  HStack,
  Icon,
  Button,
  Flex,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";
import { useParams } from "react-router-dom";
import { useMockTestList } from "../../hooks/use-mock-test-list";

export const StartMockTest: React.FC = () => {
  const { testNum } = useParams();
  const [testLink, setTestLink] = useState<string>("#");
  const { getNextStepHref } = useMockTestList();


  useEffect(() => {
      setTestLink(`${getNextStepHref("Start")}/${testNum}`);
      localStorage.setItem("isCompleteMockTest", "true");
  }, [getNextStepHref,testNum]);

  
  return (
    <Box minH="100vh" py={10}>
      <Container
        maxW="100%"
        bg="white"
        p={8}
        borderRadius="md"
        boxShadow="md"
        mt={90}
      >
        <Flex direction="row" justify="space-between" align="center">
          <Heading size="md" mb={6}>
            Practice Test Instructions
          </Heading>
          <Button
            as={"a"}
            href={`${testLink}`}
            colorScheme="blue"
            h={7}
          >
            NEXT
          </Button>
        </Flex>
        <HStack>
          <Heading size="sm">
            Welcome to your Practice Test! Follow these instructions to complete
            each section of the test
          </Heading>
        </HStack>
        <VStack align="start" spacing={5} mt={10}>
          <HStack>
            <Icon as={InfoIcon} color="blue.500" />
            <Heading size="sm">CELPIP Practice Test Instructions</Heading>
          </HStack>
          <VStack align="start" spacing={3} color={"blue.600"}>
            <UnorderedList spacing={3}>
              <ListItem>
                Welcome to the complete CELPIP practice test. This test includes
                all four sections: Listening, Reading, Writing, and Speaking.
              </ListItem>
              <ListItem>
                You can navigate freely between the sections and questions in
                this practice test. This flexibility is provided to help you
                familiarize yourself with the test format and timing.
              </ListItem>
              <ListItem>
                Each section is designed to closely replicate the official
                CELPIP test. However, the practice test may not include
                additional experimental questions found in the official test.
              </ListItem>
              <ListItem>
                Please note that the order of question types on the official
                test may differ from the order presented here.
              </ListItem>
              <ListItem>
                We recommend taking the test in a quiet environment to simulate
                real test conditions and to time yourself for each section to
                practice managing your time effectively.
              </ListItem>
              <ListItem>
                Use this practice test to identify areas where you need
                improvement and to build confidence before taking the official
                test. Good luck!
              </ListItem>
            </UnorderedList>
          </VStack>
        </VStack>
      </Container>
    </Box>
  );
};
import React, { useCallback, useEffect, useState } from "react";
import { useDropzone, Accept } from "react-dropzone";
import { Box, useToast, Text, Image } from "@chakra-ui/react";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../utils/firebaseConfig";

type ImageUploaderProps = {
  setImageUrl: (url: string | null) => void;
  oldImage?: string;
};

export const ImageUploader: React.FC<ImageUploaderProps> = ({
  setImageUrl,
  oldImage,
}) => {
  const [uploading, setUploading] = useState<boolean>(false);
  const [imageLink, setImageLink] = useState<string | null>(oldImage ?? null);
  const toast = useToast();

  useEffect(() => {
    if (oldImage) {
      setImageLink(oldImage);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oldImage]);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    uploadImage(file);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: "image/*" as unknown as Accept,
  });

  const uploadImage = (file: File) => {
    const storageRef = ref(storage, `images/${file.name}`);
    const uploadTask = uploadBytesResumable(storageRef, file);

    setUploading(true);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Handle progress
        setUploading(true);
      },
      (error) => {
        toast({
          title: "Upload failed.",
          description: error.message,
          status: "error",
          duration: 2000,
          isClosable: true,
        });
        setUploading(false);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          setImageUrl(downloadURL);
          setImageLink(downloadURL);
          toast({
            title: "Upload successful.",
            description: "Image has been uploaded successfully.",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
          setUploading(false);
        });
      },
    );
  };

  return (
    <Box
      {...getRootProps()}
      border="2px dashed"
      borderColor={isDragActive ? "teal.500" : "gray.200"}
      borderRadius="md"
      p={4}
      textAlign="center"
      cursor={"pointer"}
    >
      <input {...getInputProps()} />

      <Box textAlign={"center"} lineHeight={10} cursor={"pointer"}>
        {isDragActive ? (
          <Text>Drop the photo file here ...</Text>
        ) : (
          <Text>Drag 'n' drop a photo here, or click to select files</Text>
        )}
        <Text color="#FEAF63" fontWeight={520} as="span">
          browse file
        </Text>
      </Box>
      {uploading && <Text>Uploading...</Text>}
      {imageLink && (
        <Image src={imageLink} alt="Uploaded image" mt={4} w={500} h={350} />
      )}
    </Box>
  );
};

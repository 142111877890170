

export const readingScoringAnswer = (correctAnswers: number) => {
    if (correctAnswers >= 33 && correctAnswers <= 38) return "10 to 12";
    if (correctAnswers >= 31 && correctAnswers <= 33) return 9;
    if (correctAnswers >= 28 && correctAnswers <= 31) return 8;
    if (correctAnswers >= 24 && correctAnswers <= 28) return 7;
    if (correctAnswers >= 19 && correctAnswers <= 25) return 6;
    if (correctAnswers >= 15 && correctAnswers <= 20) return 5;
    if (correctAnswers >= 10 && correctAnswers <= 16) return 4;
    if (correctAnswers >= 8 && correctAnswers <= 11) return 3;
    if (correctAnswers >= 0 && correctAnswers <= 7) return "N/A";
    return 0;
};

export const readingSections = [
    "PracticeQuestion",
    "ReadingCorrespondence",
    "ReadingToApplyADiagram",
    "ReadingForInformation",
    "ReadingForViewpoints",
  ];



export const listeningScoringAnswer = (correctAnswers: number) => {
    if (correctAnswers >= 35 && correctAnswers <= 38) return "10 to 12";
    if (correctAnswers >= 33 && correctAnswers < 35) return 9;
    if (correctAnswers >= 30 && correctAnswers < 33) return 8;
    if (correctAnswers >= 27 && correctAnswers < 31) return 7;
    if (correctAnswers >= 22 && correctAnswers < 28) return 6;
    if (correctAnswers >= 17 && correctAnswers < 23) return 5;
    if (correctAnswers >= 11 && correctAnswers < 18) return 4;
    if (correctAnswers >= 7 && correctAnswers < 12) return 3;
    if (correctAnswers >= 0 && correctAnswers < 7) return "N/A";
    return 0;
};

export const ListeningSections = [
  "PracticeQuestion",
  "ListeningToProblemSolving",
  "ListeningToADailyLifeConversation",
  "ListeningForInformation",
  "ListeningToANewsItem",
  "ListeningToADiscussion",
  "ListeningToViewpoints",
];

export   const addMissingCommas = (jsonString: string): string  =>{
   
  const lines = jsonString.split('\n');
  
  const fixedLines = lines.map((line, index) => {
  const trimmedLine = line.trim();

    if (
      trimmedLine === '' ||
      trimmedLine.endsWith(',') ||
      trimmedLine.endsWith('{') ||
      trimmedLine.endsWith('[') ||
      trimmedLine.endsWith('}') ||
      trimmedLine.endsWith(']')
    ) {
      return line;
    }

    const nextLine = lines[index + 1]?.trim();
    if (nextLine && (nextLine.startsWith('}') || nextLine.startsWith(']'))) {
      return line; 
    }

    return line + ',';
  });

  return fixedLines.join('\n');
}

export const calculateWritingTotalScore = (scoreOne: number, scoreTwo: number): number => {
  // Calculate the average of the two scores
  const averageScore = (scoreOne + scoreTwo) / 2;

  // Round the result to the nearest whole number
  let totalScore = Math.round(averageScore);

  // Ensure the score is within the valid range
  if (totalScore < 4) {
    totalScore = 4;
  } else if (totalScore > 12) {
    totalScore = 12;
  }

  return totalScore;
};

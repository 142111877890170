import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Spinner,
} from "@chakra-ui/react";
import {
  useAppDispatch,
} from "../../../redux/hooks/useTypedSelector";
import { useParams } from "react-router-dom";
import { getSpeakingAnswerFeedbackAction } from "../../../redux/slice/mock-test/speakingTestSlice";
import { UserSpeakingFeedback, Feedback } from "../../../types/speaking-type";
import { useUser } from "../../../hooks/use-user";
import { decryptString } from "../../../utils/encryption";
import { SpeakingResultAnswer } from "./SpeakingResultAnswer";

export const SpeakingTestResult: React.FC = () => {
  const user  = useUser();
  const [parsedFeedback, setParsedFeedback] = useState<Feedback | null>(null);
  const { testID, testNum } = useParams();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const res = await dispatch(
        getSpeakingAnswerFeedbackAction({
          userID: user.id.toString(),
          testID: testID ?? "",
          testNum: decryptString(testNum?.toString() ?? ""),
        }),
      );
      const feedbackData: UserSpeakingFeedback = res.payload[0];
      setParsedFeedback(JSON.parse(feedbackData.feedback));
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    const isCompleteMockTest = localStorage.getItem("isCompleteMockTest");
    if(isCompleteMockTest === "true"){
      window.location.href = `/complete-test-result/${testNum}`;
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
}, []);

if(!parsedFeedback){
  return <Spinner />
}

  return (
    <Box minH="100vh" py={10}>
      <Container
        maxW="100%"
        bg="white"
        p={0}
        borderRadius="md"
        boxShadow="md"
        mt={9}
      >
       <SpeakingResultAnswer feedback={parsedFeedback} />
      </Container>
    </Box>
  );
};

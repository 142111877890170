import * as yup from "yup";


export const UserWritingResponseSchema = yup.object().shape({
  questionOne: yup.string().optional(),
  questionOneAnswer: yup.string().optional(),
  questionTwo: yup.string().optional(),
  questionTwoAnswer: yup.string().optional(),
  questionOption: yup.string().optional(),
});
export type UserWritingResponseSchemaType = yup.InferType<typeof UserWritingResponseSchema>;

import React from "react";
import {
  Box,
  Container,
  Heading,
  VStack,
  HStack,
  Icon,
  Button,
  Flex,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";
import { useNavigate, useParams } from "react-router-dom";

export const ReadingTestInstructions: React.FC = () => {
  const { testNum } = useParams();
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate(-1);
  };


  return (
    <Box minH="100vh" py={10}>
      <Container
        maxW="100%"
        bg="white"
        p={8}
        borderRadius="md"
        boxShadow="md"
        mt={90}
      >
        <Flex direction="row" justify="space-between" align="center">
          <Heading size="md" mb={6}>
            Mock Test - Reading
          </Heading>
          <Button as={"a"} href={`/reading-test/${testNum}`} colorScheme="blue" h={7}>
            NEXT
          </Button>
        </Flex>
        <VStack align="start" spacing={5}>
          <HStack>
            <Icon as={InfoIcon} color="blue.500" />
            <Heading size="sm">Reading Test Instructions</Heading>
          </HStack>
          <VStack align="start" spacing={3} color={"blue.600"}>
            <UnorderedList spacing={3}>
              <ListItem>
                On the official test, once you leave a page, you cannot go back
                to it to change your answers. However, in this practice test,
                you can.
              </ListItem>
              <ListItem>
                Watch the timer in the top right corner to make sure that you
                complete the Reading Test before the time is up.
              </ListItem>
              <ListItem>
                This Reading Test is identical in format to the official test
                except that the Reading section of the official test may be
                slightly longer as it might contain additional questions
                included for research and development purposes.
              </ListItem>
            </UnorderedList>
          </VStack>
        </VStack>
        <HStack justify="space-between" mt={10}>
           <Button colorScheme="red" h={7} onClick={handleBackClick}>
            BACK
          </Button>
        </HStack>
      </Container>
    </Box>
  );
};

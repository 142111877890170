import React, { FC } from "react";
import { ReadingAnswerTable } from "../test/reading/answer/ReadingAnswerTable";
import { TestResult } from "../../types/test-result-type";
import { ReadingAndListeningResponseType } from "../../types/reading-types";
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Spacer,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { useListeningAndReadingScoring } from "../../hooks/use-listening-and-reading-scoring";

type ReadingResultPreviewProps = {
  result: TestResult;
};

export const ReadingResultPreview: FC<ReadingResultPreviewProps> = ({
  result,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const { responses, totalCorrectAnswers, totalScore } = useListeningAndReadingScoring({
    feedback: result.feedback,
  });
  return (
    <Box>
      <Button onClick={onOpen} variant='outline' colorScheme='blue' w={170}>
        <Text ml={1} mr={1}>
          Reading Result
        </Text>
      </Button>

      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        size={"5xl"}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <ReadingAnswerTable
              totalCorrectAnswers={totalCorrectAnswers}
              totalScore={totalScore}
              responses={responses as ReadingAndListeningResponseType[]}
            />
          </ModalBody>

          <ModalFooter>
            <Flex
              width={"100%"}
              borderRadius="lg"
              align="center"
              justify="space-between"
            >
              <Button variant="outline" padding={"0px 40px"} onClick={onClose}>
                Cancel
              </Button>
              <Spacer />
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

import { FC } from "react";
import { TestResult } from "../../types/test-result-type";
import {
  Box,
  HStack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Tr,
  VStack,
} from "@chakra-ui/react";
import { useListeningAndReadingScoring } from "../../hooks/use-listening-and-reading-scoring";
import { Feedback } from "../../types/speaking-type";
import { WritingMockTestData } from "../../redux/slice/mock-test/writingTestSlice";
import { calculateWritingTotalScore } from "../../utils/scoring-format";

const boxStyle = {
  bg: "gray.200",
  fontSize: "18px",
  p: "3",
  borderRadius: "sm",
  w: "20",
  align: "center",
  color: "blue.500",
  fontWeight: "bold",
};

type FinalResultPreviewProps = {
  listeningResult: TestResult;
  readingResult: TestResult;
  writingResult: TestResult;
  speakingResult: TestResult;
};

export const FinalResultPreview: FC<FinalResultPreviewProps> = ({
  listeningResult,
  readingResult,
  writingResult,
  speakingResult,
}) => {
  const { totalScore: listeningScore } = useListeningAndReadingScoring({
    feedback: listeningResult?.feedback,
  });

  const { totalScore: readingScore } = useListeningAndReadingScoring({
    feedback: readingResult?.feedback,
  });

  
  let writingScore = 0;
  try {
    const writing = JSON.parse(writingResult?.feedback) as WritingMockTestData;
    writingScore = calculateWritingTotalScore(
      parseInt(writing?.questionOneScore),
      parseInt(writing?.questionTwoScore)
    );
  } catch (error) {
    console.error("Error parsing writing feedback JSON:", error);
  }


  let speaking = {} as Feedback;
  try {
    
   speaking = JSON.parse(
    speakingResult?.feedback,
  ) as unknown as Feedback;
  } catch (error) {
    console.error("Error parsing writing feedback JSON:", error);
  }
  
  return (
    <Box>
      <VStack spacing={4} align="stretch" w="45%">
         <TableContainer>
          <Table variant="simple">
            <Tbody>
              <Tr>
                <Td>
                  <Text fontWeight="bold">Listening</Text>
                </Td>
                <Td>
                  <Box {...boxStyle}>{listeningScore}</Box>
                </Td>
              </Tr>
              <Tr>
                <Td>
                  <Text fontWeight="bold">Reading</Text>
                </Td>
                <Td>
                  <Box {...boxStyle}>{readingScore}</Box>
                </Td>
              </Tr>
              <Tr>
                <Td>
                  <Text fontWeight="bold">Writing</Text>
                </Td>
                <Td>
                  <Box {...boxStyle}>{writingScore}</Box>
                </Td>
              </Tr>
              <Tr>
                <Td>
                  <Text fontWeight="bold">Speaking</Text>
                </Td>
                <Td>
                  <Box {...boxStyle}>{speaking.overallScore ? Math.round(speaking.overallScore) : "N/A"}</Box>
                </Td>
              </Tr>
            </Tbody>
          </Table>
        </TableContainer>  
        <HStack justifyContent="center" mt={6}>
          <Text color="red.600" fontSize="sm">
            Note: This information is not valid for applying to any college or
            visa purposes.
          </Text>
        </HStack>
      </VStack>
    </Box>
  );
};



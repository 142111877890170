import { extendTheme } from "@chakra-ui/react";
import { ReiButton as Button } from "./components/button";
import { ReiContainer as Container } from "./components/container";
import { ReiFormLabel as FormLabel } from "./components/form-label";
import { ReiHeading as Heading } from "./components/heading";
import { ReiInput as Input } from "./components/input";
import { ReiModal as Modal } from "./components/modal";

// For theme reference: https://chakra-ui.com/docs/styled-system/theming/customize-theme

const theme = extendTheme({
  fonts: {
    heading: `system-ui, sans-serif`,
    body: `system-ui, sans-serif`,
    // line-height: 1.5;
    // -webkit-text-size-adjust: 100%;
    // font-family: system-ui, sans-serif;
    // -webkit-font-smoothing: antialiased;
    // text-rendering: optimizeLegibility;
  },

  styles: {
    global: {
      body: {
        color: "rei.black",
        fontWeight: 400,
        fontSize: "16px",
        lineHeight: "24px",
      },
    },
  },

  colors: {
    rei: {
      black: "#0C0C33",
      dark_navy: "#092152",
      navy: "#334267",
      light_navy: "#68738B",
      turquoise: "#309ABA",
      orange: "#0C0C33",
      yellow: "#FFC84E",
      white: "#FFFFFF",
      light_grey: "#D1D6E0",
      success: "#3BBA30",
      red: "#E75945",
      linear: "#F6F8FC",
    },
  },

  components: {
    Modal,
    Button,
    Container,
    Input,
    FormLabel,
    Heading,
  },
});

export default theme;

import React from "react";
import { VStack } from "@chakra-ui/react";
import { UserResponse } from "../UserResponse";
import {
  ListeningQuestionData,
  ListeningQuestionType,
} from "../../../../../types/listening-types";
import { MockQuestion } from "../../../../../types/reading-types";

type ProblemSolvingUserResponseProps = {
  questionData: ListeningQuestionData;
  stage: number;
};

export const ProblemSolvingUserResponse: React.FC<
  ProblemSolvingUserResponseProps
> = ({ questionData, stage }) => {
  const audioOneNumberOfQuestion =
    questionData.listeningToProblemSolvingAudioOneNumOfQuestion;
  const audioTwoNumberOfQuestion =
    questionData.listeningToProblemSolvingAudioTwoNumOfQuestion;
  const audioThreeNumberOfQuestion =
    questionData.listeningToProblemSolvingAudioThreeNumOfQuestion;

  const renderUserResponseCaseOne = (index: number) => {
    return (
      <>
        {index === 3 && (
          <UserResponse
            questions={questionData.questions[0] as MockQuestion}
            questionType={questionData.questionsType as ListeningQuestionType}
          />
        )}
        {index === 4 && (
          <UserResponse
            questions={questionData.questions[1] as MockQuestion}
            questionType={questionData.questionsType as ListeningQuestionType}
          />
        )}
        {index === 7 && (
          <UserResponse
            questions={questionData.questions[2] as MockQuestion}
            questionType={questionData.questionsType as ListeningQuestionType}
          />
        )}
        {index === 8 && (
          <UserResponse
            questions={questionData.questions[3] as MockQuestion}
            questionType={questionData.questionsType as ListeningQuestionType}
          />
        )}
        {index === 9 && (
          <UserResponse
            questions={questionData.questions[4] as MockQuestion}
            questionType={questionData.questionsType as ListeningQuestionType}
          />
        )}
        {index === 12 && (
          <UserResponse
            questions={questionData.questions[5] as MockQuestion}
            questionType={questionData.questionsType as ListeningQuestionType}
          />
        )}{" "}
        {index === 13 && (
          <UserResponse
            questions={questionData.questions[6] as MockQuestion}
            questionType={questionData.questionsType as ListeningQuestionType}
          />
        )}{" "}
        {index === 14 && (
          <UserResponse
            questions={questionData.questions[7] as MockQuestion}
            questionType={questionData.questionsType as ListeningQuestionType}
          />
        )}
      </>
    );
  };
  const renderUserResponseCaseTwo = (index: number) => {
    return (
      <>
        {index === 3 && (
          <UserResponse
            questions={questionData.questions[0] as MockQuestion}
            questionType={questionData.questionsType as ListeningQuestionType}
          />
        )}
        {index === 4 && (
          <UserResponse
            questions={questionData.questions[1] as MockQuestion}
            questionType={questionData.questionsType as ListeningQuestionType}
          />
        )}
        {index === 5 && (
          <UserResponse
            questions={questionData.questions[2] as MockQuestion}
            questionType={questionData.questionsType as ListeningQuestionType}
          />
        )}
        {index === 8 && (
          <UserResponse
            questions={questionData.questions[3] as MockQuestion}
            questionType={questionData.questionsType as ListeningQuestionType}
          />
        )}
        {index === 9 && (
          <UserResponse
            questions={questionData.questions[4] as MockQuestion}
            questionType={questionData.questionsType as ListeningQuestionType}
          />
        )}
        {index === 12 && (
          <UserResponse
            questions={questionData.questions[5] as MockQuestion}
            questionType={questionData.questionsType as ListeningQuestionType}
          />
        )}
        {index === 13 && (
          <UserResponse
            questions={questionData.questions[6] as MockQuestion}
            questionType={questionData.questionsType as ListeningQuestionType}
          />
        )}
        {index === 14 && (
          <UserResponse
            questions={questionData.questions[7] as MockQuestion}
            questionType={questionData.questionsType as ListeningQuestionType}
          />
        )}
      </>
    );
  };
  const renderUserResponseCaseThree = (index: number) => {
    return (
      <>
        {index === 3 && (
          <UserResponse
            questions={questionData.questions[0] as MockQuestion}
            questionType={questionData.questionsType as ListeningQuestionType}
          />
        )}
        {index === 4 && (
          <UserResponse
            questions={questionData.questions[1] as MockQuestion}
            questionType={questionData.questionsType as ListeningQuestionType}
          />
        )}
        {index === 5 && (
          <UserResponse
            questions={questionData.questions[2] as MockQuestion}
            questionType={questionData.questionsType as ListeningQuestionType}
          />
        )}
        {index === 8 && (
          <UserResponse
            questions={questionData.questions[3] as MockQuestion}
            questionType={questionData.questionsType as ListeningQuestionType}
          />
        )}
        {index === 9 && (
          <UserResponse
            questions={questionData.questions[4] as MockQuestion}
            questionType={questionData.questionsType as ListeningQuestionType}
          />
        )}
        {index === 12 && (
          <UserResponse
            questions={questionData.questions[5] as MockQuestion}
            questionType={questionData.questionsType as ListeningQuestionType}
          />
        )}
        {index === 13 && (
          <UserResponse
            questions={questionData.questions[6] as MockQuestion}
            questionType={questionData.questionsType as ListeningQuestionType}
          />
        )}
      </>
    );
  };

  if (
    stage === 3 ||
    stage === 4 ||
    stage === 7 ||
    stage === 8 ||
    stage === 9 ||
    stage === 12 ||
    stage === 13 ||
    stage === 14
  ) {
    return (
      <VStack
        align="start"
        spacing={5}
        w="full"
        p={3}
        bg="#f7fafc"
        minH="90vh"
        maxH="90vh"
        borderBottom="1px #CCC solid"
        ml={-2}
        overflowY="auto"
      >
        {Number(audioOneNumberOfQuestion) === 2 &&
          Number(audioTwoNumberOfQuestion) === 3 &&
          Number(audioThreeNumberOfQuestion) === 3 &&
          renderUserResponseCaseOne(stage)}

        {Number(audioOneNumberOfQuestion) === 3 &&
          Number(audioTwoNumberOfQuestion) === 2 &&
          Number(audioThreeNumberOfQuestion) === 3 &&
          renderUserResponseCaseTwo(stage)}

        {Number(audioOneNumberOfQuestion) === 3 &&
          Number(audioTwoNumberOfQuestion) === 3 &&
          Number(audioThreeNumberOfQuestion) === 2 &&
          renderUserResponseCaseThree(stage)}
      </VStack>
    );
  }

  return null;
};

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AuthLayout from "./layouts/AuthLayout";
import { SignIn } from "./apps/auth/SignIn";
import PublicLayout from "./layouts/PublicLayout";
import { SignUp } from "./apps/auth/SignUp";
import AdminRoutes from "./Routes/AdminRoutes";
import React from "react";
import QuestionBankRoutes from "./Routes/QuestionBankRoutes";
import MockTestRoutes from "./Routes/MockTestRoutes";
import TeacherAndStudentRoute from "./Routes/TeacherAndStudentRoute";
import PaymentRoutes from "./Routes/PaymentRoutes";
import { Home } from "./apps/home";
import { PrivacyPolicy } from "./apps/policy-and-conditions/PrivacyPolicy";
import { TermsConditions } from "./apps/policy-and-conditions/TermsConditions";
import { ContactUs } from "./apps/contactus";
import { ForgetPassword } from "./apps/auth/ForgetPassword";
import { ResetPassword } from "./apps/auth/ResetPassword";

function App() {
  return (
    <Router>
      <Routes>
        {/* PublicRoutes */}
        <Route element={<PublicLayout />}>
          <Route path="/" element={<Home />} />
          {/* <Route path="/" element={<SignIn />} /> */}
          <Route path="/sign-in" element={<SignIn />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/terms-conditions" element={<TermsConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/forget-password"  element={<ForgetPassword />} />
          <Route path='/email-password-reset/:email/:token' element={<ResetPassword />} />
        </Route>

        {/* Auth routes */}
        <Route element={<AuthLayout />}>
          {AdminRoutes.map((route, index) =>
            React.cloneElement(route, { key: `admin-route-${index}` }),
          )}

          {/* Question Bank */}
          {QuestionBankRoutes.map((route, index) =>
            React.cloneElement(route, { key: `question-bank-route-${index}` }),
          )}

          {/* Mock Test */}
          {MockTestRoutes.map((route, index) =>
            React.cloneElement(route, { key: `mock-test-route-${index}` }),
          )}

          {/* Teacher and Student */}
           {TeacherAndStudentRoute.map((route, index) =>
            React.cloneElement(route, { key: `teacher-and-student-route-${index}` }),
          )}

          {/*  Payment */}
          {PaymentRoutes.map((route, index) =>
            React.cloneElement(route, { key: `payment-route-${index}` }),
          )}


        </Route>
      </Routes>
    </Router>
  );
}

export default App;

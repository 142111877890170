import React, { useEffect, useRef, useState } from "react";
import {
  Box,
  Container,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Heading,
  Icon,
  Radio,
  RadioGroup,
  Stack,
  Textarea,
  VStack,
  useToast,
  Text,
  Spinner,
} from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";
import { WritingQuestionData } from "../../../../types/writing-types";
import { DisplayQuestionContent } from "../../../../components/DisplayQuestionContent";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import {
  UserWritingResponseSchema,
  UserWritingResponseSchemaType,
} from "./schema";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks/useTypedSelector";
import { submitMockTestWritingAnswerAction } from "../../../../redux/slice/mock-test/writingTestSlice";
import { cleanString } from "../../../../utils/helperFuntions";
import { useParams } from "react-router-dom";

type PracticeProps = {
  question: WritingQuestionData;
  part: number;
};


export const UserWritingResponse: React.FC<PracticeProps> = ({
  question,
  part,
}) => {
  const { isLoading, isSuccess, isError, errorMessage } = useAppSelector((state) => state.writingMockTest);
  const dispatch = useAppDispatch();
  const { testNum } = useParams();
  const toast = useToast();
  const setQuestionOne = `
  ${cleanString(question.questionOne)}.
  ${cleanString(question.questionOneInstructionOne)}.
  ${cleanString(question.questionOneInstructionTwo)}`;

  const setQuestionTwo = `
  ${cleanString(question.questionOneInstructionTwo)}.
  ${cleanString(question.questionTwoInstruction)}.
  ${cleanString(question.questionTwoOptionA)}
  ${cleanString(question.questionTwoOptionB)}`;

  const [questionOneWordCount, setQuestionOneWordCount] = useState(0);
  const [questionTwoWordCount, setQuestionTwoWordCount] = useState(0);

  const {
    handleSubmit,
    register,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(UserWritingResponseSchema),
    defaultValues: {
      questionOne: setQuestionOne,
      questionOneAnswer: "",
      questionTwo: setQuestionTwo,
      questionTwoAnswer: "",
      questionOption: "",
    },
  });

  const updateWordCount = (e: React.ChangeEvent<HTMLTextAreaElement>, setWordCount: (count: number) => void) => {
    const wordCount = e.target.value.trim().split(/\s+/).length;
    setWordCount(wordCount);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSubmitHandler = async (payLoad: UserWritingResponseSchemaType) => {
    await dispatch(submitMockTestWritingAnswerAction(payLoad));

    if (isSuccess) {
      toast({
        title: "Question saved successfully.",
        status: "success",
        position: "top-right",
        duration: 9000,
        isClosable: true,
      });
      setTimeout(() => {
        window.location.href = `/writing-test-result/${testNum}`;
      }, 1000);
    } else if (isError) {
      toast({
        title: "Error",
        description: errorMessage,
        status: "error",
        position: "top-right",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const hasSubmittedRef = useRef(false);

  useEffect(() => {
    if (part === 3 && !hasSubmittedRef.current) {
      hasSubmittedRef.current = true;
      handleSubmit(onSubmitHandler)();
    }
  }, [part, handleSubmit, onSubmitHandler]);

  const setOptionValue = (value: string) => {
    setValue("questionOption", value);
  };

  return (
    <Container
      maxW="container.2xlg"
      bg="white"
      p={8}
      borderRadius="md"
      boxShadow="md"
      mt={9}
      as="form"
      onSubmit={handleSubmit(onSubmitHandler)}
    >
      {/* Part One */}
      {part === 1 && (
        <Box p={1}>
          <HStack>
            <Icon as={InfoIcon} color="blue.500" />
            <Heading size="sm" color={"blue.600"}>
              {question.questionOneInstructionOne}
            </Heading>
          </HStack>
          <Box pl={4} mt={7}>
            <DisplayQuestionContent content={question.questionOneInstructionTwo} />
          </Box>
          <Box>
            <VStack spacing={4} mt={4}>
              <FormControl mt={2}>
                <FormLabel color={"blue.600"}>
                  Type in your answer in the box below
                </FormLabel>
                <Textarea
                  placeholder="Your Answer"
                  bg={"#FFF"}
                  h={500}
                  spellCheck="true"
                  {...register("questionOneAnswer", {
                    onChange: (e) => updateWordCount(e, setQuestionOneWordCount),
                  })}
                  required
                ></Textarea>
                <Text color="gray.500" mt={2}>
                  Word count: {questionOneWordCount}
                </Text>
                <FormErrorMessage>
                  {errors.questionOneAnswer?.message}
                </FormErrorMessage>
              </FormControl>
            </VStack>
          </Box>
        </Box>
      )}

      {/* Part Two */}
      {part === 2 && (
        <Box p={1}>
          <HStack>
            <Icon as={InfoIcon} color="blue.500" />
            <Heading size="sm" color={"blue.600"}>
              {question.questionTwoInstruction}
            </Heading>
          </HStack>
          <Box pl={4} mt={7}>
            <RadioGroup>
              <Stack>
                <Radio value={question.questionTwoOptionA} onChange={() => setOptionValue(question.questionTwoOptionA)}>
                  Option A: {question.questionTwoOptionA}
                </Radio>
                <Radio value={question.questionTwoOptionB} onChange={() => setOptionValue(question.questionTwoOptionB)}>
                  Option B: {question.questionTwoOptionB}
                </Radio>
              </Stack>
            </RadioGroup>
            <FormErrorMessage>
              {errors.questionOption?.message}
            </FormErrorMessage>
          </Box>
          {watch("questionOption") && (
            <Box>
              <VStack spacing={4} mt={4}>
                <FormControl mt={2}>
                  <FormLabel color={"blue.600"}>
                    Explain the reasons for your choice. Write about 150-200
                    words.
                  </FormLabel>
                  <Textarea
                    placeholder="Your Answer"
                    bg={"#FFF"}
                    h={500}
                    spellCheck="true"
                    {...register("questionTwoAnswer", {
                      onChange: (e) => updateWordCount(e, setQuestionTwoWordCount),
                    })}
                    required
                  ></Textarea>
                  <Text color="gray.500" mt={2}>
                    Word count: {questionTwoWordCount}
                  </Text>
                  <FormErrorMessage>
                    {errors.questionTwoAnswer?.message}
                  </FormErrorMessage>
                </FormControl>
              </VStack>
            </Box>
          )}
        </Box>
      )}

      {isLoading ? 
      <>
      <Heading size="sm" color={"green.600"}>
       Please wait. sending your answer ......
     </Heading>
     <Spinner /> 
     </>
       : " "}
    </Container>
  );
};

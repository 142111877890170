import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { googleLogout } from '@react-oauth/google';
import { SignUp, SignIn, RecoverPassword, ResetPassword } from '../../../apps/auth/schema';
import authService from '../../service/authService';

export type AuthUser = {
  id: number;
  handle: string;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  accountType: "student" | "teacher" | "admin"; 
  isActive: boolean;
  exp_date: string;
  created_at: string;
  updated_at: string;
}


type SignUpState = {
  user: any;
  isError: boolean;
  isSuccess: boolean;
  isLoading: boolean;
  errorMessage: string;
}

const initialState: SignUpState = {
  user: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  errorMessage: '',
};

export const signUpAction = createAsyncThunk('auth/signUp', async (data: SignUp, thunkAPI) => {
  try {
    return await authService.signUp(data);
  } catch (error: any) {
    let message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

    if (error.response?.status === 400) {
      message = error.response.data.data;
    }
    return thunkAPI.rejectWithValue(message);
  }
});

export const signInAction = createAsyncThunk('auth/signIn', async (data: SignIn, thunkAPI) => {
  try {
    return await authService.signIn(data);
  } catch (error: any) {
    let message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

    if (error.response?.status === 400) {
      message = error.response.data.data;
    }
    return thunkAPI.rejectWithValue(message);
  }
});

export const verifyEmailAction = createAsyncThunk('auth/verifyEmail', async (token: string, thunkAPI) => {
  try {
    return await authService.verifyEmail(token);
  } catch (error: any) {
    let message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

    if (error.response?.status === 400) {
      message = error.response.data.data;
    }
    return thunkAPI.rejectWithValue(message);
  }
});

export const updateAccountAction = createAsyncThunk('auth/updateAccount', async (data: SignUp, thunkAPI) => {
  try {
    return await authService.updateAccount(data,thunkAPI);
  } catch (error: any) {
    let message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

    if (error.response?.status === 400) {
      message = error.response.data.data;
    }
    return thunkAPI.rejectWithValue(message);
  }
});
export const updatePasswordAction = createAsyncThunk('auth/updatePassword', async (data: SignUp, thunkAPI) => {
  try {
    return await authService.updatePassword(data,thunkAPI);
  } catch (error: any) {
    let message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

    if (error.response?.status === 400) {
      message = error.response.data.data;
    }
    return thunkAPI.rejectWithValue(message);
  }
});

export const recoverPasswordAction = createAsyncThunk('auth/recoverPassword', async (email: RecoverPassword, thunkAPI) => {
  try {
    return await authService.recoverPassword(email);
  } catch (error: any) {
    let message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

    if (error.response?.status === 400) {
      message = error.response.data.data;
    }
    return thunkAPI.rejectWithValue(message);
  }
});
export const resetPasswordAction = createAsyncThunk('auth/resetPassword', async (payload: ResetPassword, thunkAPI) => {
  try {
    return await authService.resetPassword(payload);
  } catch (error: any) {
    let message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

    if (error.response?.status === 400) {
      message = error.response.data.data;
    }
    return thunkAPI.rejectWithValue(message);
  }
});

export const signOutAction = createAsyncThunk('auth/signOut', async (_, thunkAPI) => {
  try {
    thunkAPI.dispatch(authSlice.actions.authLogout());
    googleLogout();
    // clearStorage();
    window.location.href = '/';
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.message);
  }
});

const handleRejected = (state: SignUpState, action: any) => {
  state.isLoading = false;
  state.isSuccess = false;
  state.isError = true;
  state.errorMessage = action.payload as string;
};
const handleFulfilled = (state: SignUpState, action: any) => {
  if(action?.payload?.status !== 500 || action?.payload?.status !== 511){
    state.isLoading = false
    state.isSuccess = true
    state.isError = false
    state.user = action?.payload?.user ?  action.payload as SignUp : null;
  }else{
    state.isLoading = false
    state.isSuccess = false
    state.isError = true
    state.errorMessage = action.payload.data.errorMessage
    state.user = null
  }
};
const handleUpdatePasswordFulfilled = (state: SignUpState, action: any) => {
  if(action?.payload?.status !== 500 || action?.payload?.status !== 511){
    state.isLoading = false
    state.isSuccess = true
    state.isError = false
  }else{
    state.isLoading = false
    state.isSuccess = false
    state.isError = true
    state.errorMessage = action.payload.data.errorMessage
    state.user = null
  }
};
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetAuth: (state) => {
      state.user = null
      state.isError = false
      state.isSuccess = false
      state.isLoading = false
      state.errorMessage = ''
    },
    updateUser: (state, action: PayloadAction<AuthUser>) => {
      state.user = action.payload;
    },
    authLogout: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(signUpAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(signUpAction.fulfilled, (state, action) => {
        handleFulfilled(state, action); 
      })
      .addCase(signUpAction.rejected, (state, action) => {
        handleRejected(state, action);
      })
      .addCase(verifyEmailAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(verifyEmailAction.fulfilled, (state, action) => {
        handleFulfilled(state, action); 
      })
      .addCase(verifyEmailAction.rejected, (state, action) => {
        handleRejected(state, action);
      })
      .addCase(signInAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(signInAction.fulfilled, (state, action) => {
        handleFulfilled(state, action); 
      })
      .addCase(signInAction.rejected, (state, action) => {
        handleRejected(state, action);
      })
      .addCase(updateAccountAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateAccountAction.fulfilled, (state, action) => {
        handleFulfilled(state, action); 
      })
      .addCase(updateAccountAction.rejected, (state, action) => {
        handleRejected(state, action);
      })
      .addCase(updatePasswordAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updatePasswordAction.fulfilled, (state, action) => {
        handleUpdatePasswordFulfilled(state, action); 
      })
      .addCase(updatePasswordAction.rejected, (state, action) => {
        handleRejected(state, action);
      })
      .addCase(recoverPasswordAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(recoverPasswordAction.fulfilled, (state, action) => {
        handleFulfilled(state, action); 
      })
      .addCase(recoverPasswordAction.rejected, (state, action) => {
        handleRejected(state, action);
      })
      .addCase(resetPasswordAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(resetPasswordAction.fulfilled, (state, action) => {
        handleFulfilled(state, action); 
      })
      .addCase(resetPasswordAction.rejected, (state, action) => {
        handleRejected(state, action);
      });
  },
});



export const { resetAuth, authLogout, updateUser } = authSlice.actions;
export default authSlice.reducer;
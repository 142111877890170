import { ComponentStyleConfig } from "@chakra-ui/react";

export const ReiInput: ComponentStyleConfig = {
  baseStyle: {
    field: {
      borderColor: "rei.black",
      border: "1px solid",
      _readOnly: {
        backgroundColor: "gray.200", // Example gray-out effect
        color: "gray.500", // Dim the text color for readonly
        cursor: "not-allowed", // Optional: Change cursor to indicate non-interactivity
      },
    },
  },
  variants: {
    outline: {
      field: {
        borderColor: "rei.black",
        borderWidth: "1px",

        borderRadius: "5px",
        _placeholder: {
          color: "rei.light_navy",
        },
        _hover: {
          borderColor: "rei.turquoise",
        },
      },
    },
  },

  defaultProps: {
    variant: "outline",
  },
};

import React from "react";
import {
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalFooter,
  Flex,
  Spacer,
  ModalCloseButton,
  Stack,
} from "@chakra-ui/react";
import { useStudentPlan } from "../../hooks/use-student-plan";
import { Elements } from "@stripe/react-stripe-js";
import { AmountDetails } from "../package-plan/student-plan/AmountDetails";
import { PackageSelection } from "../package-plan/student-plan/PackageSelection";

export const PlanSelection = () => {
  const { stripePromise, days, updatePrice, handleCheckout } =
    useStudentPlan();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);

  return (
    <>
      <Button
        mt={4}
        colorScheme="teal"
        variant="outline"
        _hover={{ bg: "#319795", color: "#FFF", borderColor: "#319795" }}
        width={"full"}
        onClick={onOpen}
      >
        Sign Up
      </Button>

      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        size={"7xl"}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Elements stripe={stripePromise}>
              <Stack spacing={8} p={4} w={["full", "90%"]} mx="auto">
                <PackageSelection days={days} updatePrice={updatePrice}  />
                <AmountDetails
                  days={days}
                  handleCheckout={handleCheckout}
                  btnTitle={"Sign Up"}
                />
              </Stack>
            </Elements>
          </ModalBody>

          <ModalFooter>
            <Flex
              width={"100%"}
              borderRadius="lg"
              align="center"
              justify="space-between"
            >
              {/* <Button variant="outline" padding={"0px 40px"} onClick={onClose}>
                Cancel
              </Button> */}
              <Spacer />
              <Button variant="outline" padding={"0px 40px"} onClick={onClose}>
                Cancel
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

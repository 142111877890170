import React, { FC } from "react";
import {
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  Text,
  useToast,
  ModalFooter,
  Flex,
  Spacer,
  ModalCloseButton,
  IconButton,
} from "@chakra-ui/react";

import { FaTrash } from "react-icons/fa";
import { useUser } from "../../../../hooks/use-user";
import { useAppSelector, useAppDispatch } from "../../../../redux/hooks/useTypedSelector";
import { Student, removeStudentAction } from "../../../../redux/slice/student/studentSlice";

type RemoveStudentModalProps = {
  student: Student;
};
export const RemoveStudentModal: FC<RemoveStudentModalProps> = ({
  student,
}) => {
  const { isLoading } = useAppSelector((state) => state.students);
  const user = useUser();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const dispatch = useAppDispatch();
  const toast = useToast();

  const handleRemoveStudent = async () => {
    
    try {
      await dispatch(
        removeStudentAction({
          studentId: student.id.toString(),
          teacherId: user.id.toString(),
        }),
      );
      toast({
        title: "Student Removed",
        description: "Student Removed Successful",
        status: "success",
        position: "top-right",
        duration: 9000,
        isClosable: true,
      });
      setTimeout(() => {
        // eslint-disable-next-line no-restricted-globals
        location.reload();
      }, 1000);
    } catch (error: any) {
      toast({
        title: "Error Removing Student",
        description: error.message,
        status: "error",
        position: "top-right",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Text onClick={onOpen}>
        <IconButton
          aria-label="Remove Student"
          size={"sm"}
          bg={"red.700"}
          p={1}
          icon={<FaTrash />}
        />
        <Text as={"span"} ml={2} cursor={"pointer"}>
          Remove
        </Text>
      </Text>

      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        size={"xl"}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader> Remove Student</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            Are you sure you want to remove this student 
            {" "}<Text as={"span"} color={"green.600"} fontWeight={600}>{student.firstName} {student.lastName}</Text>? 
          </ModalBody>

          <ModalFooter>
            <Flex
              width={"100%"}
              borderRadius="lg"
              align="center"
              justify="space-between"
            >
              <Button padding={"0px 40px"}  isLoading={isLoading} onClick={handleRemoveStudent}>
                Yes, Remove
              </Button>
              <Spacer />
              <Button variant="outline" padding={"0px 40px"} onClick={onClose}>
                Cancel
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

import { AnyAction, Reducer, combineReducers } from "@reduxjs/toolkit";
import signUpReducer from "./auth/authSlice";
import questionBankReducer from "./mock-test/questionBankSlice";
import mockTestReducer from "./mock-test/mockTestSlice";
import mockTestResultReducer from "./mock-test/mockTestResultSlice";
import mockTestViewReducer from "./mock-test/mockTestsViewSlice";
import writingMockTestReducer from "./mock-test/writingTestSlice";
import speakingMockTestReducer from "./mock-test/writingTestSlice";
import studentReducer from "./student/studentSlice";
import teacherReducer from "./student/teacherSlice";
import contactUsReducer from "./contact-us/contactUsSlice";





const rootReducer = combineReducers({
  auth: signUpReducer,
  questionBank: questionBankReducer,
  mockTestView: mockTestViewReducer,
  mockTest: mockTestReducer,
  mockTestResult: mockTestResultReducer,
  writingMockTest: writingMockTestReducer,
  speakingMockTest: speakingMockTestReducer,
  students: studentReducer,
  teachers: teacherReducer,
  contactUs: contactUsReducer,
});

export const resetAllReducers = () => ({
  type: "RESET_ALL_REDUCERS",
});

const rootReducerWithReset: Reducer<any, AnyAction> = (state: any, action: AnyAction) => {
    if (action.type === "RESET_ALL_REDUCERS") {
      state = undefined;
    }
    return rootReducer(state, action);
  };

export default rootReducerWithReset;

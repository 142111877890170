import {
  Box,
  Heading,
  Button,
  HStack,
  Container,
  Flex,
  SimpleGrid,
} from "@chakra-ui/react";
import { addSpaceBeforeCaps } from "../../../../utils/helperFuntions";
import { useListeningForm } from "../../../../hooks/use-listening-form";
import { ListeningQuestionsSection } from "./sections/ListeningQuestionsSection";
import { ListeningQuestionInstructionsSection } from "./sections/ListeningQuestionInstructionsSection";

export const ListeningToANewsItemForm = () => {
  const {
    handleSubmit,
    register,
    setValue,
    watch,
    fields,
    errors,
    optionValues,
    isLoading,
    onSubmitHandler,
    handleOptionChange,
    getAudioLabel,
    questionType,
  } = useListeningForm();

  return (
    <Box bg="gray.50" minH="100vh">
      <Container
        maxW="container.2xlg"
        bg="white"
        p={8}
        borderRadius="md"
        boxShadow="md"
        mt={9}
        as="form"
        onSubmit={handleSubmit(onSubmitHandler)}
      >
        <Flex
          direction="row"
          justify="space-between"
          align="center"
          bg="gray.200"
          border="1px #CCC solid"
          p={3}
          h={10}
        >
          <Heading size="md">
            Create {addSpaceBeforeCaps(questionType || "")} Question
          </Heading>
        </Flex>

        <SimpleGrid columns={2} spacing={8} p={0}>
          <ListeningQuestionInstructionsSection
            register={register}
            errors={errors}
            watch={watch}
            setValue={setValue}
          />
          <ListeningQuestionsSection
            fields={fields}
            errors={errors}
            register={register}
            setValue={setValue}
            watch={watch}
            handleOptionChange={handleOptionChange}
            optionValues={optionValues}
            getAudioLabel={getAudioLabel}
          />
        </SimpleGrid>

        <HStack justify="space-between" mt={10}>
          <Button colorScheme="red" type="reset">
            Reset Form
          </Button>
          <Button type="submit" colorScheme="teal" isLoading={isLoading}>
            Submit Form
          </Button>
        </HStack>
      </Container>
    </Box>
  );
};

import React from "react";
import { Box, HStack, Heading, Icon } from "@chakra-ui/react";
import { DisplayQuestionContent } from "../../../../../components/DisplayQuestionContent";
import { ListeningQuestionData } from "../../../../../types/listening-types";
import { InfoIcon } from "@chakra-ui/icons";
import {
  listeningDefaultInstruction,
  listeningDefaultInstructionTwo,
} from "../../../../../utils/helperFuntions";
import { AudioPlayer } from "../../../../../components/AudioAndVideoPlayer";

type ListeningForInformationProps = {
  questionData: ListeningQuestionData;
  stage: number;
};

export const InstructionHeading: React.FC<{ text: string }> = ({ text }) => (
  <HStack>
    <Icon as={InfoIcon} color="blue.500" />
    <Heading size="sm" color="blue.600">
      {text}
    </Heading>
  </HStack>
);


export const ListeningForInformation: React.FC<ListeningForInformationProps> = ({
  questionData,
  stage,
}) => {
  const renderContent = () => {
    switch (stage) {
      case 0:
        return <DisplayQuestionContent content={questionData.listeningInstructionOne} />;
      case 1:
        return (
          <Box mt={9}>
            <DisplayQuestionContent content={questionData.listeningInstructionTwo} />
          </Box>
        );
      case 2:
        return (
          <>
            <InstructionHeading text={listeningDefaultInstruction} />
            <AudioPlayer src={questionData.listeningToProblemSolvingAudioOne} />
          </>
        );
      case 3:
      case 4:
      case 5:
      case 6:
      case 7:
      case 8:
        return (
          <>
            <InstructionHeading text={listeningDefaultInstructionTwo} />
            <AudioPlayer src={questionData.questions[stage - 3].question} />
          </>
        );
      default:
        return null;
    }
  };

  return <Box>{renderContent()}</Box>;
};

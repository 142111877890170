import {
  Text,
  Box,
  Container,
  Heading,
  VStack,
  SimpleGrid,
} from "@chakra-ui/react";
import { Logo } from "../../components/Logo";

type StepProps = {
  title: string;
  description: string;
};

const Step: React.FC<StepProps> = ({ title, description }) => (
  <VStack spacing={4} textAlign="center">
    <Heading size="md">{title}</Heading>
    <Text>{description}</Text>
  </VStack>
);

export const Steps = () => {
  return (
    <Box as="section" py={20} bg="gray.50">
      <Container maxW="container.xl">
        <Heading size="xl" textAlign="center" mb={20}>
          How  <Logo size={39} /> Works
        </Heading>
        <SimpleGrid columns={{ base: 1, md: 2, lg: 4 }} spacing={10}>
          <Step
            title="Sign Up"
            description="Create an account and choose your plan."
          />
          <Step
            title="Take Practice Tests"
            description="Access a variety of practice tests and interactive lessons."
          />
          <Step
            title="Track Progress"
            description="Use our detailed analytics to monitor your improvement."
          />
          <Step
            title="Achieve Success"
            description="Be fully prepared and confident for your CELPIP test."
          />
        </SimpleGrid>
      </Container>
    </Box>
  );
};

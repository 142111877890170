import {
  Box,
  Heading,
  SimpleGrid,
  Text,
  Button,
  Flex,
  IconButton,
  Spacer,
} from "@chakra-ui/react";
import { FiCalendar, FiDollarSign, FiShoppingCart } from "react-icons/fi";
import { planPrice } from "../../../utils/helperFuntions";

type AmountDetailsProps = {
  days: number;
  handleCheckout: () => void;
  btnTitle: string;
};

export const AmountDetails: React.FC<AmountDetailsProps> = ({
  days,
  handleCheckout,
  btnTitle,
}) => {
  const totalPrice = planPrice(days);
  return (
    <Box textAlign="center" mt={6}>
      <Heading as="h2" size="lg">
        Amount Details
      </Heading>
      <SimpleGrid columns={[1, 2]} spacing={5} mt={5}>
        <Box bg="white" borderRadius="md" p={4} boxShadow="xl">
          <Flex>
            <Box width={"100%"}>
              <Text>
                <IconButton
                  icon={<FiCalendar />}
                  aria-label="Days"
                  bg="teal.500"
                  color={"#FFF"}
                  isRound
                  size="lg"
                />{" "}
                Days
              </Text>
            </Box>
            <Spacer />
            <Box width={"100%"}>
              <Text fontSize="4xl" fontWeight="bold">
                {days}
              </Text>
            </Box>
          </Flex>
        </Box>
        <Box bg="white" borderRadius="md" p={4} boxShadow="xl">
          <Flex>
            <Box width={"100%"}>
              <Text>
                <IconButton
                  icon={<FiDollarSign />}
                  aria-label="Amount"
                  bg="teal.500"
                  color={"#FFF"}
                  isRound
                  size="lg"
                />{" "}
                Amount
              </Text>
            </Box>
            <Spacer />
            <Box width={"100%"}>
              <Text fontSize="4xl" fontWeight="bold">
                $ {totalPrice}
              </Text>
            </Box>
          </Flex>
        </Box>
      </SimpleGrid>
      <Box textAlign="center" mt={9}>
        {window.location.href.includes("package-plan") ? (
           <Button
           bg="#319795"
           color="white"
           border={"3px #ffd814 solid"}
           fontSize={"lg"}
           _hover={{ bg: "#ffd814", color: "#319795" }}
           onClick={handleCheckout}
         >
           <IconButton
             icon={<FiShoppingCart />}
             aria-label="Check out"
             bg={"transparent"}
             _hover={{ bg: "transparent" }}
             size="lg"
            
           />
           {btnTitle}
         </Button>) : (
            <Button
            mt={4}
            as={"a"}
            href="/sign-up"
            _hover={{ bg: "#319795", color: "#FFF", borderColor: "#319795"}}
          >
            Sign Up
          </Button>
         )}
       
      </Box>
    </Box>
  );
};

import React, { useEffect } from "react";
import {
  Box,
  Heading,
  Text,
  Button,
  VStack,
  Icon,
  Center,
} from "@chakra-ui/react";
import { useUser } from "../../hooks/use-user";
import { FiCheckCircle } from "react-icons/fi";
import { config } from "../../config";
import { StripeObject } from "./type";
import { updateUser } from "../../redux/slice/auth/authSlice";
import { useAppDispatch } from "../../redux/hooks/useTypedSelector";

export const PaymentSuccess: React.FC = () => {
  const user = useUser();
  const API_URL = config.baseUrl.default;
  const dispatch = useAppDispatch();

  useEffect(() => {
    const verifyPayment = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const sessionId = urlParams.get("session_id");

      // if (!sessionId) {
      //   window.location.href = "/dashboard";
      //   return;
      // }
      if (sessionId) {
        const response = await fetch(API_URL + `verify-payment/${sessionId}/${user.id}/${user.exp_date}`);
        const res = await response.json();
        const data = res.session as StripeObject;
        // const amountInDollars = (data.amount_total / 100).toFixed(2);
  
        if (!res.success) {
          window.location.href = "/cancel-payment";
          return;
        }
        dispatch(updateUser(res.user));
  
        // Delete session_id from the URL after updating the user
        urlParams.delete("session_id");
        window.history.replaceState({}, "", `${window.location.pathname}?${urlParams}`);
      }

    };

    verifyPayment();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGoHome = () => {
    window.location.href = "/mock-test";
  };

  return (
    <Box minH="100vh" bg="gray.50" py={12} px={6}>
      <Center>
        <VStack
          spacing={6}
          maxW="lg"
          bg="white"
          p={8}
          borderRadius="lg"
          boxShadow="lg"
        >
          <Icon as={FiCheckCircle} w={20} h={20} color="green.400" />
          <Heading as="h1" size="2xl" textAlign="center">
            Payment Successful!
          </Heading>
          <Text fontSize="xl" color="gray.600" textAlign="center">
            Thank you, {`${user?.firstName} ${user?.lastName}` || ""}! Your
            payment has been processed successfully.
          </Text>
          <Text fontSize="md" color="gray.500" textAlign="center">
            You will receive a confirmation email shortly. If you have any
            questions, feel free to contact our support team.
          </Text>
          <Button size="lg" colorScheme="teal" onClick={handleGoHome}>
            Go to Mock Test
          </Button>
        </VStack>
      </Center>
    </Box>
  );
};

import {
  QuestionData,
  ReadingQuestion,
} from "../apps/question-bank/reading/schema";
import {
  PracticeQuestion,
  ReadingCorrespondence,
  ReadingToApplyADiagram,
  ReadingForInformation,
  ReadingForViewpoints,
} from "../apps/test/question-bank/question-bank";

export const reactQuillModules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video"],
    ["clean"],
  ],
  clipboard: {
    matchVisual: false,
  },
};

export const reactQuillFormats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
];

export const readingQuestionsSections = [
  {
    id: 0,
    value: "PracticeQuestion",
    section: PracticeQuestion,
    label: "Practice Question",
  },
  {
    id: 1,
    value: "ReadingCorrespondence",
    section: ReadingCorrespondence,
    label: "Reading Correspondence",
  },
  {
    id: 2,
    value: "ReadingToApplyADiagram",
    section: ReadingToApplyADiagram,
    label: "Reading To Apply A Diagram",
  },
  {
    id: 3,
    value: "ReadingForInformation",
    section: ReadingForInformation,
    label: "Reading For Information",
  },
  {
    id: 4,
    value: "ReadingForViewpoints",
    section: ReadingForViewpoints,
    label: "Reading For Viewpoints",
  },
];

export const listeningQuestionsSections = [
  {
    value: "PracticeQuestion",
    label: "Practice Question",
  },
  {
    value: "ListeningToProblemSolving",
    label: "Listening to Problem Solving",
  },
  {
    value: "ListeningToADailyLifeConversation",
    label: "Listening to a Daily Life Conversation",
  },
  {
    value: "ListeningForInformation",
    label: "Listening for Information",
  },
  {
    value: "ListeningToANewsItem",
    label: "Listening to a News Item",
  },
  {
    value: "ListeningToADiscussion",
    label: "Listening to a Discussion",
  },
  {
    value: "ListeningToViewpoints",
    label: "Listening to Viewpoints",
  },
];

export const setNextTestNum = (
  questions: ReadingQuestion[],
  questionType: string,
): number => {
  if (!questions || !Array.isArray(questions)) return 1;

  const filteredQuestions = questions.filter(
    (question) => question && question.questionType === questionType,
  );

  if (filteredQuestions.length === 0) return 1;

  const maxTestNum = Math.max(
    ...filteredQuestions.map((question) => Number(question.testNum)),
  );

  return maxTestNum + 1;
};
export const testTimeOptions = Array.from({ length: 30 }, (_, index) => ({
  value: `${index + 1}`,
  label: `${index + 1} Minutes`,
}));
export const testTimeOptionsInSeconds = Array.from(
  { length: 120 },
  (_, index) => ({
    value: `${index + 1}`,
    label: `${index + 1} Seconds`,
  }),
);

export const practiceQuestionInstruction = `Canada is bordered by oceans on three sides. The Arctic Ocean is to the north, adjacent to Yukon, Northwest Territories, and Nunavut. The Pacific Ocean lies off the west coast of British Columbia. To the east, the Atlantic Ocean borders Canada's Maritime provinces, which consist of Nova Scotia, New Brunswick, and Prince Edward Island.`;

export const instructionSectionOne =
  "Using the drop-down menu (▼), choose the best option according to the information given in the message";
export const instructionSectionImage =
  "Using the drop-down menu (▼), choose the best option according to the information given in the picture";
export const ReadingForInformationInstruction =
  "Decide which paragraph, A to D, has the information given in each statement below. Select E if the information is not given in any of the paragraphs.";
export const instructionSectionTwo =
  "Here is a response to the message. Complete the response by filling in the blanks. Select the best choice for each blank from the drop-down";
export const ReadingForViewpointsInstruction =
  "The following is a comment by a visitor to the website page. Complete the comment by choosing the best option to fill in each blank.";
export const defaultInstructionSection =
  "Using the drop-down menu (▼), choose the best option";

export const dynamicInstructionsField = "Please type your message and make sure to include **** after each question or point where you expect a dropdown option to appear. If you forget to add ****, the dropdown menu will not be displayed for that question.";

export const listeningDefaultInstruction =
  "Listen to the conversation. You will hear the conversation only once. It is about 1.5 to 2 minutes long.";
export const listeningDefaultInstructionTwo =
  "Listen to the question. You will hear it only once.";
export const listeningDefaultInstructionThree =
  "Choose the best way to complete each statement from the drop-down menu (  ).";

export const addSpaceBeforeCaps = (str: string) => {
  return str?.replace(/([A-Z])/g, " $1").trim();
};
export const formatDate = (dateString: string): string => {
  const date = new Date(dateString);
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
    // hour: '2-digit',
    // minute: '2-digit',
    // second: '2-digit',
    // hour12: false,
    // timeZoneName: 'short'
  };

  const formattedDate = date.toLocaleDateString("en-US", options);

  return `${formattedDate}`;
};

export const getTotalNumberOfQuestions = (data: string): number => {
  const parsedData: QuestionData = JSON.parse(data);
  return parsedData.questions.length;
};
export const getTestTime = (data: string): string => {
  const parsedData: QuestionData = JSON.parse(data);
  return parsedData.time;
};

export const shuffleOptions = (options: string[]): string[] => {
  const filteredOptions = options.filter((option) => option.trim() !== "");
  for (let i = filteredOptions.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [filteredOptions[i], filteredOptions[j]] = [
      filteredOptions[j],
      filteredOptions[i],
    ];
  }
  return filteredOptions;
};

export const cleanString = (input: string) => {
  let cleanText = input.replace(/<\/?[^>]+(>|$)/g, "\n");
  cleanText = cleanText.replace(/[^\w\s]/gi, "\n");

  return cleanText;
};

export const formatNumber = (num: number) => {
  return num.toLocaleString('en-US');
};

export const isDateExpired = (expDate: string) => {
  const today = new Date();
  const expirationDate = new Date(validateDate(expDate));
  today.setHours(0, 0, 0, 0);
  expirationDate.setHours(0, 0, 0, 0);
  return expirationDate < today;
};

export const validateDate = (dateString: string) => {
  const date = new Date(dateString);
  date.setDate(date.getDate());

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  return `${year}-${month}-${day}`;
};

export const planPrice = (days: number) => {
  let price = 0;
  switch (days) {
    case 14:
      price = 9.99;
      break;
    case 30:
      price = 15.99;
      break;
    case 60:
      price = 25.99;
      break;
    case 90:
      price = 40.99;
      break;
    default:
      price = 30; 
  }
  return price;
};

export const daysRemaining = (targetDate: string): number  =>{
  const today = new Date();
  const target = new Date(targetDate);
  const diffTime = target.getTime() - today.getTime();

  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  
  return diffDays;
}

import {
  Box,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
  HStack,
  VStack,
  Container,
  Flex,
  Icon,
  SimpleGrid,
  FormErrorMessage,
  useToast,
  Select,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFieldArray, useForm } from "react-hook-form";
import { ReadingTestFormData, ReadingTestValidationSchema } from "../schema";
import { InfoIcon } from "@chakra-ui/icons";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  reactQuillFormats,
  reactQuillModules,
  testTimeOptions,
} from "../../../../utils/helperFuntions";
import { useParams } from "react-router-dom";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/hooks/useTypedSelector";
import {
  QType,
  createQuestionAction,
  updateQuestionAction,
} from "../../../../redux/slice/mock-test/questionBankSlice";
import { useUser } from "../../../../hooks/use-user";
import { useQuestionData } from "../../../../hooks/use-question-data";

export const CreateReadingForInformationForm = () => {
  const user = useUser();
  const { isSuccess, isError, isLoading, errorMessage } = useAppSelector(
    (state) => state.questionBank,
  );

  const { testNum } = useParams();
  const dispatch = useAppDispatch();
  const toast = useToast();

  const {
    handleSubmit,
    register,
    control,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm<ReadingTestFormData>({
    resolver: yupResolver(ReadingTestValidationSchema),
    defaultValues: {
      questionsType: "ReadingForInformation",
      testNum: testNum,
      bodyContent: "",
      time: "",
      questions: Array(9).fill({
        question: "",
        correctAnswer: "",
        optionB: "",
        optionC: "",
        optionD: "",
        optionE: "",
      }),
    },
  });
  const { question, questionData } = useQuestionData(reset);

  const handleEditorChange = (content: string) => {
    setValue("bodyContent", content, { shouldValidate: true });
  };

  const { fields } = useFieldArray({
    control,
    name: "questions",
  });

  const onSubmitHandler = async (data: ReadingTestFormData) => {
    const payLoad = {
      id: question?.id ?? null,
      user: user.id,
      type: "Reading" as QType,
      testNum: testNum ?? "",
      questionType: "ReadingForInformation",
      data: JSON.stringify(data),
    };

    if (questionData) {
      await dispatch(updateQuestionAction(payLoad));
    } else {
      await dispatch(createQuestionAction(payLoad));
    }

    if (isSuccess) {
      toast({
        title: "Question saved successfully.",
        status: "success",
        position: "top-right",
        duration: 9000,
        isClosable: true,
      });
      setTimeout(() => {
        window.location.href = "/admin-reading";
      }, 1000);
    } else if (isError) {
      toast({
        title: "Error",
        description: errorMessage,
        status: "error",
        position: "top-right",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Box bg="gray.50" minH="100vh">
      <Container
        maxW="container.2xlg"
        bg="white"
        p={8}
        borderRadius="md"
        boxShadow="md"
        mt={9}
        as="form"
        onSubmit={handleSubmit(onSubmitHandler)}
      >
        <Flex
          direction="row"
          justify="space-between"
          align="center"
          bg="gray.200"
          border="1px #CCC solid"
          p={3}
          h={10}
        >
          <Heading size="md">Add Reading - For Information</Heading>
        </Flex>

        <SimpleGrid columns={2} spacing={8} p={0}>
          {/* Body Content Section */}
          <VStack
            align="start"
            spacing={5}
            w="full"
            p={3}
            border="1px solid #CCC"
            minH="90vh"
            maxH="90vh"
            overflowY="auto"
          >
            <HStack>
              <Icon as={InfoIcon} color="blue.500" />
              <Heading size="sm" color="blue.600">
                Reading Passage
              </Heading>
            </HStack>
            <Box w="100%" p={4} mt={4}>
              <FormControl isInvalid={!!errors.time} mb={9}>
                <FormLabel>Question Time</FormLabel>
                <Select
                  placeholder="Select question time"
                  bg="#FFF"
                  {...register("time")}
                  required
                >
                  {testTimeOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>{errors.time?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!errors.bodyContent}>
                <FormLabel>Body Content</FormLabel>
                <ReactQuill
                  theme="snow"
                  value={watch("bodyContent")}
                  onChange={handleEditorChange}
                  modules={reactQuillModules}
                  formats={reactQuillFormats}
                  style={{ height: 300 }}
                />
                <FormErrorMessage>
                  {errors.bodyContent?.message}
                </FormErrorMessage>
              </FormControl>
            </Box>
          </VStack>

          {/* Questions Section */}
          <VStack
            align="start"
            spacing={5}
            w="full"
            p={3}
            bg="#f7fafc"
            minH="90vh"
            border="1px solid #CCC"
            overflowY="auto"
          >
            <HStack>
              <Icon as={InfoIcon} color="blue.500" />
              <Heading size="sm" color="blue.600">
                Questions
              </Heading>
            </HStack>
            <Box w="100%">
              {fields.map((item, index) => (
                <Box key={item.id} mb={8}>
                  <FormControl
                    isInvalid={!!errors.questions?.[index]?.question}
                    bg={"gray.300"}
                    p={3}
                    borderRadius={"md"}
                  >
                    <FormLabel fontSize={22}>Question {index + 1}</FormLabel>
                    <Input
                      placeholder="Question"
                      {...register(`questions.${index}.question`)}
                      bg={"#FFF"}
                      required
                    />
                    <FormErrorMessage>
                      {errors.questions?.[index]?.question?.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl
                    mt={2}
                    isInvalid={!!errors.questions?.[index]?.correctAnswer}
                    bg={"green.100"}
                    p={4}
                    borderRadius={"md"}
                  >
                    <FormLabel>Correct Answer</FormLabel>
                    <Input
                      placeholder="Correct Answer"
                      {...register(`questions.${index}.correctAnswer`)}
                      bg={"#FFF"}
                      required
                    />
                    <FormErrorMessage>
                      {errors.questions?.[index]?.correctAnswer?.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl
                    mt={2}
                    isInvalid={!!errors.questions?.[index]?.optionB}
                  >
                    <FormLabel>Option B</FormLabel>
                    <Input
                      placeholder="Option B"
                      {...register(`questions.${index}.optionB`)}
                      required
                    />
                    <FormErrorMessage>
                      {errors.questions?.[index]?.optionB?.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl
                    mt={2}
                    isInvalid={!!errors.questions?.[index]?.optionC}
                  >
                    <FormLabel>Option C</FormLabel>
                    <Input
                      placeholder="Option C"
                      {...register(`questions.${index}.optionC`)}
                      required
                    />
                    <FormErrorMessage>
                      {errors.questions?.[index]?.optionC?.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl
                    mt={2}
                    isInvalid={!!errors.questions?.[index]?.optionD}
                  >
                    <FormLabel>Option D</FormLabel>
                    <Input
                      placeholder="Option D"
                      {...register(`questions.${index}.optionD`)}
                      required
                    />
                    <FormErrorMessage>
                      {errors.questions?.[index]?.optionD?.message}
                    </FormErrorMessage>
                  </FormControl>

                  <FormControl
                    mt={2}
                    isInvalid={!!errors.questions?.[index]?.optionE}
                  >
                    <FormLabel>Option E</FormLabel>
                    <Input
                      placeholder="Option E"
                      {...register(`questions.${index}.optionE`)}
                      required
                    />
                    <FormErrorMessage>
                      {errors.questions?.[index]?.optionE?.message}
                    </FormErrorMessage>
                  </FormControl>
                </Box>
              ))}
            </Box>
          </VStack>
        </SimpleGrid>

        <HStack justify="space-between" mt={10}>
          <Button colorScheme="red" type="reset">
            Reset Form
          </Button>
          <Button type="submit" colorScheme="teal" isLoading={isLoading}>
            Submit Form
          </Button>
        </HStack>
      </Container>
    </Box>
  );
};

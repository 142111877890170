import React from "react";
import { Box, HStack, Heading, Text } from "@chakra-ui/react";
import { DisplayQuestionContent } from "../../../components/DisplayQuestionContent";
import { calculateWritingTotalScore } from "../../../utils/scoring-format";

type WritingResultAnswerProps = {
  questionOneScore: string;
  questionOneFeedback: string;
  questionOneSuggestions: string;
  questionTwoScore: string;
  questionTwoFeedback: string;
  questionTwoSuggestions: string;
};

export const WritingResultAnswer: React.FC<WritingResultAnswerProps> = ({
  questionOneScore,
  questionOneFeedback,
  questionOneSuggestions,
  questionTwoScore,
  questionTwoFeedback,
  questionTwoSuggestions,
}) => {
const totalScore = calculateWritingTotalScore (parseInt(questionOneScore), parseInt(questionTwoScore));

  return (
    <Box p={4}>
      <Box mb={3}>
        <HStack>
          <Heading size="lg" color={"blue.600"}>
            Writing Mock Test Result
          </Heading>
        </HStack>
      </Box>
      <Box>
        <Heading size="sm" color={"blue.600"}>
          Question 1 - Writing an Email
        </Heading>
        <Heading size="sm" color={"blue.600"} mt={2} mb={2}>
          SCORE:{" "}
          <Text
            as={"span"}
            bg={"green.600"}
            color={"#FFF"}
            p={1}
            borderRadius={"sm"}
          >
            {questionOneScore}
          </Text>
        </Heading>
        <strong>Feedback:</strong>{" "}
        <DisplayQuestionContent content={questionOneFeedback} />
        <DisplayQuestionContent content={questionOneSuggestions} />
      </Box>
      <Box mt={6}>
        <Heading size="sm" color={"blue.600"}>
          Question 2 - Responding to Survey Questions
        </Heading>
        <Heading size="sm" color={"blue.600"} mt={2} mb={2}>
          SCORE:{" "}
          <Text
            as={"span"}
            bg={"green.600"}
            color={"#FFF"}
            p={1}
            borderRadius={"sm"}
          >
            {questionTwoScore}
          </Text>
        </Heading>
        <strong>Feedback:</strong>{" "}
        <DisplayQuestionContent content={questionTwoFeedback} />
        <DisplayQuestionContent content={questionTwoSuggestions} />


        <Heading size="sm" color={"blue.600"} mt={9}>
         FINAL SCORE:{" "}
          <Text
            as={"span"}
            bg={"green.600"}
            color={"#FFF"}
            p={1}
            borderRadius={"sm"}
          >
            {totalScore}
          </Text>
        </Heading>
      </Box>
    </Box>
  );
};

import { useState, useEffect } from "react";
import {
  Box,
  Spinner,
  Stack,
  Card,
  CardBody,
  CardHeader,
  Heading,
  Text,
  SimpleGrid,
  Button,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Flex,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import {
  useAppSelector,
  useAppDispatch,
} from "../../../redux/hooks/useTypedSelector";
import { getQuestionsAction } from "../../../redux/slice/mock-test/questionBankSlice";
import { formatDate, setNextTestNum } from "../../../utils/helperFuntions";
import {
  SpeakingQuestion,
  SpeakingQuestionData,
} from "../../../types/speaking-type";
import { ReadingQuestion } from "../reading/schema";
import { EditIcon } from "@chakra-ui/icons";
import { FaBook } from "react-icons/fa";
import { encryptString } from "../../../utils/encryption";

export const SpeakingQuestionBank = () => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const { isLoading } = useAppSelector((state) => state.questionBank);
  const [questions, setQuestions] = useState<SpeakingQuestion[]>([]);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const res = await dispatch(getQuestionsAction("Speaking"));
      setQuestions(res.payload);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  if (isLoading) {
    return <Spinner />;
  }

  const sortedQuestions = [...(questions || [])].sort((a, b) => b.id - a.id);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const handleImageClick = (image: string) => {
    setSelectedImage(image);
    onOpen();
  };

  return (
    <Stack>
      <Box>
        <Button
          as={"a"}
          href={`/add-speaking-question/${setNextTestNum(questions as unknown as ReadingQuestion[], "Speaking")}`}
          colorScheme="teal"
          size="md"
          mb={9}
        >
          Add New Question
        </Button>
        <Heading size="md">Total Questions: {sortedQuestions.length}</Heading>
      </Box>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5} p={9}>
        {sortedQuestions.map((question, index) => {
          const parsedData = JSON.parse(question.data) as SpeakingQuestionData;
          return (
            <Card key={question.id} boxShadow="2xl">
              <Flex justify="flex-end" p={3}>
                <Menu>
                  <MenuButton onClick={toggleMenu} as={Box} cursor="pointer">
                    <Text fontWeight={"bold"} fontSize={24}>
                      ...
                    </Text>
                  </MenuButton>
                  <MenuList minW="120px">
                    <MenuItem
                      icon={<FaBook />}
                      as={"a"}
                      target="_blank"
                      href={`/speaking-test-instructions/${encryptString(question.testNum.toString())}`}
                      color={"blue.600"}
                    >
                      Test Mock Test
                    </MenuItem>
                    <MenuItem
                      icon={<EditIcon />}
                      as={"a"}
                      href={`/edit-speaking-question/${question.id}`}
                    >
                      Edit
                    </MenuItem>
                    <MenuItem onClick={closeMenu}>Delete</MenuItem>
                  </MenuList>
                </Menu>
              </Flex>
              <CardHeader>
                <Heading size="md">Question Number: {index + 1}</Heading>
                <Heading size="md">Question ID: {question.id}</Heading>
              </CardHeader>
              <CardBody>
                <Text>
                  <strong>Question Type:</strong> {question.questionType}
                </Text>
                <Text>
                  <strong>Created At:</strong> {formatDate(question.createAt)}
                </Text>

                <Heading size="md" color="blue.600" mb={3} mt={3}>
                  Question Practice Task
                </Heading>
                <Text>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: parsedData.questionPracticeTask,
                    }}
                  />
                </Text>
                <Text p={1} color={"red.600"}>
                  <strong>Time:</strong> {parsedData.questionPracticeTaskTime}{" "}
                  Seconds
                </Text>

                <Heading size="md" color="blue.600" mb={3} mt={3}>
                  Speaking Task One
                </Heading>
                <Text>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: parsedData.questionSpeakingTaskOne,
                    }}
                  />
                </Text>
                <Text p={1} color={"red.600"}>
                  <strong>Time:</strong>{" "}
                  {parsedData.questionSpeakingTaskOneTime} Seconds
                </Text>

                <Heading size="md" color="blue.600" mb={3} mt={3}>
                  Speaking Task Two
                </Heading>
                <Text>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: parsedData.questionSpeakingTaskTwo,
                    }}
                  />
                </Text>
                <Text p={1} color={"red.600"}>
                  <strong>Time:</strong>{" "}
                  {parsedData.questionSpeakingTaskTwoTime} Seconds
                </Text>

                <Heading size="md" color="blue.600" mb={3} mt={3}>
                  Speaking Task Three
                </Heading>
                <Text>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: parsedData.questionSpeakingTaskThree,
                    }}
                  />
                </Text>
                {parsedData.questionSpeakingTaskThreePhoto && (
                  <Image
                    width={100}
                    height={100}
                    src={parsedData.questionSpeakingTaskThreePhoto}
                    alt="Task Three Image"
                    mt={3}
                    cursor="pointer"
                    onClick={() =>
                      handleImageClick(
                        parsedData.questionSpeakingTaskThreePhoto,
                      )
                    }
                  />
                )}
                <Text p={1} color={"red.600"}>
                  <strong>Time:</strong>{" "}
                  {parsedData.questionSpeakingTaskThreeTime} Seconds
                </Text>

                <Heading size="md" color="blue.600" mb={3} mt={3}>
                  Speaking Task Four
                </Heading>
                <Text>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: parsedData.questionSpeakingTaskFour,
                    }}
                  />
                </Text>
                {parsedData.questionSpeakingTaskFourPhoto && (
                  <Image
                    width={100}
                    height={100}
                    src={parsedData.questionSpeakingTaskFourPhoto}
                    alt="Task Four Image"
                    mt={3}
                    cursor="pointer"
                    onClick={() =>
                      handleImageClick(parsedData.questionSpeakingTaskFourPhoto)
                    }
                  />
                )}
                <Text p={1} color={"red.600"}>
                  <strong>Time:</strong>{" "}
                  {parsedData.questionSpeakingTaskFourTime} Seconds
                </Text>

                <Heading size="md" color="blue.600" mb={3} mt={3}>
                  Speaking Task Five
                </Heading>
                <Text>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: parsedData.questionSpeakingTaskFive,
                    }}
                  />
                </Text>
                <Flex>
                  <Box>
                    {parsedData.questionSpeakingTaskFivePhotoOne && (
                      <Image
                        width={100}
                        height={100}
                        src={parsedData.questionSpeakingTaskFivePhotoOne}
                        alt="Task Five Image One"
                        mt={3}
                        cursor="pointer"
                        onClick={() =>
                          handleImageClick(
                            parsedData.questionSpeakingTaskFivePhotoOne,
                          )
                        }
                      />
                    )}
                    <Text>Option one Image</Text>
                  </Box>
                  <Box ml={9}>
                    {parsedData.questionSpeakingTaskFivePhotoTwo && (
                      <Image
                        width={100}
                        height={100}
                        src={parsedData.questionSpeakingTaskFivePhotoTwo}
                        alt="Task Five Image Two"
                        mt={3}
                        cursor="pointer"
                        onClick={() =>
                          handleImageClick(
                            parsedData.questionSpeakingTaskFivePhotoTwo,
                          )
                        }
                      />
                    )}
                    <Text>Option two Image</Text>
                  </Box>
                  <Box ml={9}>
                    {parsedData.questionSpeakingTaskFivePhotoThree && (
                      <Image
                        width={100}
                        height={100}
                        src={parsedData.questionSpeakingTaskFivePhotoThree}
                        alt="Task Five Image Three"
                        mt={3}
                        cursor="pointer"
                        onClick={() =>
                          handleImageClick(
                            parsedData.questionSpeakingTaskFivePhotoThree,
                          )
                        }
                      />
                    )}
                    <Text>Suggested Image</Text>
                  </Box>
                </Flex>
                <Text p={1} color={"red.600"}>
                  <strong>Time:</strong>{" "}
                  {parsedData.questionSpeakingTaskFiveTime} Seconds
                </Text>

                <Heading size="md" color="blue.600" mb={3} mt={3}>
                  Speaking Task Six
                </Heading>
                <Text>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: parsedData.questionSpeakingTaskSix,
                    }}
                  />
                </Text>
                <Text p={1} color={"red.600"}>
                  <strong>Time:</strong>{" "}
                  {parsedData.questionSpeakingTaskSixTime} Seconds
                </Text>

                <Heading size="md" color="blue.600" mb={3} mt={3}>
                  Speaking Task Seven
                </Heading>
                <Text>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: parsedData.questionSpeakingTaskSeven,
                    }}
                  />
                </Text>
                <Text p={1} color={"red.600"}>
                  <strong>Time:</strong>{" "}
                  {parsedData.questionSpeakingTaskSevenTime} Seconds
                </Text>

                <Heading size="md" color="blue.600" mb={3} mt={3}>
                  Speaking Task Eight
                </Heading>
                <Text>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: parsedData.questionSpeakingTaskEight,
                    }}
                  />
                </Text>
                {parsedData.questionSpeakingTaskEightPhoto && (
                  <Image
                    width={100}
                    height={100}
                    src={parsedData.questionSpeakingTaskEightPhoto}
                    alt="Task Eight Image"
                    mt={3}
                    cursor="pointer"
                    onClick={() =>
                      handleImageClick(
                        parsedData.questionSpeakingTaskEightPhoto,
                      )
                    }
                  />
                )}
                <Text p={1} color={"red.600"}>
                  <strong>Time:</strong>{" "}
                  {parsedData.questionSpeakingTaskEightTime} Seconds
                </Text>
              </CardBody>
            </Card>
          );
        })}
      </SimpleGrid>

      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Image View</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedImage && (
              <Image src={selectedImage} alt="Selected Task Image" />
            )}
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Stack>
  );
};

import React, { useMemo, useCallback, useEffect, useRef } from "react";
import { Box } from "@chakra-ui/react";
import DOMPurify from "dompurify";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { UserAnswerSchema, userAnswerSchema } from "../schema";
import { useAnswerStore } from "../../../../redux/zustand-store";

type DynamicSentenceProps = {
  userId: string;
  sectionTwoQuestions: string;
  options: { [key: string]: string }[][];
  questionType?: string; 
};

const shuffleArray = (array: any[]): any[] => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const shuffleNestedArray = (nestedArray: any[][]): any[][] => {
  return nestedArray.map((innerArray) => shuffleArray(innerArray));
};

export const DynamicSentence: React.FC<DynamicSentenceProps> = ({
  userId,
  sectionTwoQuestions,
  options,
  questionType,
}) => {
  const { responses, addResponse, updateResponse } = useAnswerStore();
  const { handleSubmit, setValue } = useForm({
    resolver: yupResolver(userAnswerSchema),
  });

  const containerRef = useRef<HTMLDivElement>(null);

  const optionsDisplay = useMemo(() => shuffleNestedArray(options), [options]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onSubmit = (data: UserAnswerSchema) => {
    const existingResponse = responses.find(
      (response) =>
        response.userId === userId &&
        response.question === data.question
    );

    if (existingResponse) {
      updateResponse(userId, data.question ?? "", data.userAnswer);
    } else {
      addResponse({
        userId,
        question: data.question ?? "",
        userAnswer: data.userAnswer,
        correctAnswer: data.correctAnswer ?? "",
        questionType: questionType || "",
      });
    }
  };

  const handleSelectChange = useCallback((event: Event) => {
    const target = event.target as HTMLSelectElement;
    setValue("question", target.dataset.question);
    setValue("correctAnswer", target.dataset.correctAnswer);
    setValue("userAnswer", target.value);
    handleSubmit(onSubmit)();
  }, [handleSubmit, setValue, onSubmit]);

  const htmlString = useMemo(() => {
    let optionIndex = 0;
  
    return sectionTwoQuestions.replace(/\*\*\*\*/g, () => {
      const correctAnswer = optionsDisplay[optionIndex]?.find(
        (option) => option.correctAnswer,
      )?.correctAnswer;
      const question = optionsDisplay[optionIndex]?.find(
        (option) => option.question,
      )?.question;

      const dropdownHtml = `
        <select class="dynamic-select" style="width: 90px; height: 28px; border-radius: 4px; background-color: #FFF;" data-question="${question}" data-correct-answer="${correctAnswer}">
          <option value="" disabled selected></option>
          ${optionsDisplay[optionIndex]
            ?.map((option) => {
              const key = Object.keys(option)[0];
              const value = option[key];
              if (key !== "question")
                return `<option value="${value}">${value}</option>`;
            })
            .join("")}
        </select>`;
      optionIndex += 1;
      return dropdownHtml;
    });
  }, [sectionTwoQuestions, optionsDisplay]);

  const sanitizedHtmlContent = useMemo(() => {
    return DOMPurify.sanitize(htmlString);
  }, [htmlString]);

  useEffect(() => {
    const container = containerRef.current;
    if (container) {
      const selects = container.querySelectorAll(".dynamic-select");
      selects.forEach((select) => {
        select.addEventListener("change", handleSelectChange);
      });
      return () => {
        selects.forEach((select) => {
          select.removeEventListener("change", handleSelectChange);
        });
      };
    }
  }, [sanitizedHtmlContent, handleSelectChange]);

  return (
    <Box
      ref={containerRef}
      p={1}
      mt={-2}
      dangerouslySetInnerHTML={{ __html: sanitizedHtmlContent }}
    />
  );
};

import { useState, useEffect } from "react";
import {
  Box,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  IconButton,
  HStack,
  Heading,
} from "@chakra-ui/react";
import { CreateListeningQuestion } from "./edit/CreateListeningQuestion";
import {
  useAppSelector,
  useAppDispatch,
} from "../../../redux/hooks/useTypedSelector";
import { getQuestionsAction } from "../../../redux/slice/mock-test/questionBankSlice";
import { ListeningQuestion } from "./schema";
import {
  addSpaceBeforeCaps,
  formatDate,
  getTestTime,
  getTotalNumberOfQuestions,
} from "../../../utils/helperFuntions";
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
import { ReadingQuestion } from "../reading/schema";
import { FaBook } from "react-icons/fa";
import { encryptString } from "../../../utils/encryption";

export const ListeningQuestionBank = () => {
  const { isLoading } = useAppSelector((state) => state.questionBank);
  const [questions, setQuestions] = useState<any>();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const res = await dispatch(getQuestionsAction("Listening"));
      setQuestions(res.payload);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Stack>
      <Box>
        <HStack mb={9}>
          <Heading size="lg" color="blue.600">
            Listening Questions
          </Heading>
        </HStack>
        <CreateListeningQuestion questions={questions as ReadingQuestion[]} />
        <TableContainer>
          <Table variant="simple" bg={"#FFF"} boxShadow={"2xl"}>
            <Thead bg={"#CCC"}>
              <Tr>
                <Th>ID</Th>
                <Th>Category ID</Th>
                <Th>Question Type</Th>
                <Th>Total Questions</Th>
                <Th>Test Time</Th>
                {/* <Th>Students Taking The Test</Th>
                <Th>Created by</Th> */}
                <Th>Created At</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              {questions &&
                questions.map((data: ListeningQuestion, index: any) => (
                  <Tr key={index}>
                    <Td>{data.id}</Td>
                    <Td>{data.testNum}</Td>
                    <Td>{addSpaceBeforeCaps(data.questionType)}</Td>
                    <Td>{getTotalNumberOfQuestions(data.data)}</Td>
                    <Td>{getTestTime(data.data)} Minutes</Td>
                    {/* <Td>---</Td> */}
                    {/* <Td>---</Td> */}
                    <Td>{formatDate(data.createAt)}</Td>
                    <Td>
                      <Menu>
                        <MenuButton as={IconButton} icon={<EditIcon />} />
                        <MenuList>
                          <MenuItem
                            icon={<FaBook />}
                            as={"a"}
                            target="_blank"
                            href={`/listening-test-instructions/${encryptString(data.testNum.toString())}`}
                            color={"blue.600"}
                          >
                            Test Mock Test
                          </MenuItem>
                          <MenuItem
                            icon={<EditIcon />}
                            as={"a"}
                            href={`/edit-listening-question/${data.questionType}/${data.id}`}
                          >
                            Edit
                          </MenuItem>
                          <MenuItem icon={<DeleteIcon />}>Delete</MenuItem>
                        </MenuList>
                      </Menu>
                    </Td>
                  </Tr>
                ))}
            </Tbody>
          </Table>
        </TableContainer>
      </Box>
    </Stack>
  );
};

import { Route } from "react-router-dom";
import { StartMockTest } from "../apps/mock-test/StartMockTest";
import { MockTest } from "../apps/mock-test";
import { ListeningTest } from "../apps/test/listening/ListeningTest";
import { ListeningTestInstructions } from "../apps/test/listening/ListeningTestInstructions";
import { ReadingTest } from "../apps/test/reading/ReadingTest";
import { ReadingTestInstructions } from "../apps/test/reading/ReadingTestInstructions";
import { SpeakingTest } from "../apps/test/speaking/SpeakingTest";
import { SpeakingTestInstructions } from "../apps/test/speaking/SpeakingTestInstructions";
import { SpeakingTestResult } from "../apps/test/speaking/SpeakingTestResult";
import { WritingTest } from "../apps/test/writing/WritingTest";
import { WritingTestInstructions } from "../apps/test/writing/WritingTestInstructions";
import { WritingTestResult } from "../apps/test/writing/WritingTestResult";
import { CompleteTestResult } from "../apps/mock-test/CompleteTestResult";

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  <>
   <Route path="/mock-test" element={<MockTest />} />
    {/* <Route path="/start-mock-test" element={<StartMockTest />} /> */}
    <Route path="/complete-mock-test/:testNum" element={<StartMockTest />} />
    <Route
      path="/reading-test-instructions/:testNum"
      element={<ReadingTestInstructions />}
    />
    <Route path="/reading-test/:testNum" element={<ReadingTest />} />

    <Route
      path="/writing-test-instructions/:testNum"
      element={<WritingTestInstructions />}
    />
    <Route path="/writing-test/:testNum" element={<WritingTest />} />
    <Route path="/writing-test-result/:testNum" element={<WritingTestResult />} />

    <Route
      path="/speaking-test-instructions/:testNum"
      element={<SpeakingTestInstructions />}
    />
    <Route path="/speaking-test/:testNum" element={<SpeakingTest />} />
    <Route
      path="/speaking-test-result/:testID"
      element={<SpeakingTestResult />}
    />

    <Route
      path="/listening-test-instructions/:testNum"
      element={<ListeningTestInstructions />}
    />
    <Route path="/listening-test/:testNum" element={<ListeningTest />} />
    <Route path="/complete-test-result/:testNum" element={<CompleteTestResult />} />
  </>,
];

import { useState, useEffect } from "react";
import { useForm, useFieldArray, useWatch } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useParams } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import {
  ListeningQuestionBankType,
  ListeningTestFormData,
  ListeningTestValidationSchema,
} from "../apps/question-bank/listening/schema";
import {
  useAppSelector,
  useAppDispatch,
} from "../redux/hooks/useTypedSelector";
import { QType } from "../redux/slice/mock-test/mockTestSlice";
import {
  updateQuestionAction,
  createQuestionAction,
} from "../redux/slice/mock-test/questionBankSlice";
import { useQuestionData } from "./use-question-data";
import { useUser } from "./use-user";

export const useListeningForm = () => {
  const user = useUser();
  const { isSuccess, isError, isLoading, errorMessage } = useAppSelector(
    (state) => state.questionBank,
  );
  const { questionType, testNum } = useParams<{
    questionType: ListeningQuestionBankType;
    testNum: string;
  }>();
  const [optionValues, setOptionValues] = useState<string[]>([]);
  const dispatch = useAppDispatch();
  const toast = useToast();

  const {
    handleSubmit,
    register,
    control,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm<ListeningTestFormData>({
    resolver: yupResolver(ListeningTestValidationSchema),
    defaultValues: {
      testNum: testNum,
      listeningInstructionOne: "",
      listeningInstructionTwo: "",
      listeningToProblemSolvingAudioOne: "",
      listeningDefaultNumOfQuestion: "1",
      time: "",
      questions: Array(0).fill({
        question: "",
        correctAnswer: "",
        optionB: "",
        optionC: "",
        optionD: "",
        optionE: "",
      }),
    },
  });

  const { question, questionData } = useQuestionData(reset, "Listening");

  const { fields, append, remove } = useFieldArray({
    control,
    name: "questions",
  });

  const totalDefaultQuestions = watch("listeningDefaultNumOfQuestion");
  const questions = useWatch({
    control,
    name: "questions",
  });

  useEffect(() => {
    const totalQ = Number(totalDefaultQuestions || 0);
    if (questionData) {
      reset({
        questionsType: "Listening",
        testNum: questionData.testNum,
        listeningInstructionOne: questionData.listeningInstructionOne,
        listeningInstructionTwo: questionData.listeningInstructionTwo,
        listeningToProblemSolvingAudioOne:
          questionData.listeningToProblemSolvingAudioOne,
        listeningDefaultNumOfQuestion: `${questionData?.questions.length}`,
        time: questionData.time,
        questions: questionData.questions,
      });
    }

    if (fields.length < totalQ) {
      const toAdd = totalQ - fields.length;
      Array(toAdd)
        .fill(undefined)
        .forEach(() =>
          append({
            question: "",
            correctAnswer: "",
            optionB: "",
            optionC: "",
            optionD: "",
            optionE: "",
          }),
        );
    } else if (fields.length > totalQ) {
      const toRemove = fields.length - totalQ;
      Array(toRemove)
        .fill(undefined)
        .forEach(() => remove(fields.length - 1));
    }
  }, [
    totalDefaultQuestions,
    append,
    remove,
    fields.length,
    questionData,
    reset,
  ]);

  const onSubmitHandler = async (data: ListeningTestFormData) => {
    try {
      if (questionType && data.testNum) {
        const payLoad = {
          id: question?.id ?? null,
          user: user.id,
          type: "Listening" as QType,
          testNum: data.testNum,
          questionType: questionType,
          data: JSON.stringify(data),
        };

        if (questionData) {
          await dispatch(updateQuestionAction(payLoad));
        } else {
          await dispatch(createQuestionAction(payLoad));
        }

        if (isSuccess) {
          toast({
            title: "Question saved successfully.",
            status: "success",
            position: "top-right",
            duration: 9000,
            isClosable: true,
          });
          setTimeout(() => {
            window.location.href = "/admin-listening";
          }, 1000);
        } else if (isError) {
          toast({
            title: "Error",
            description: errorMessage,
            status: "error",
            position: "top-right",
            duration: 9000,
            isClosable: true,
          });
        }
      }
    } catch (error: any) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        position: "top-right",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (questions && questions.length > 0) {
      const newOptionValues = questions.map((question) => {
        const options = [
          question.correctAnswer,
          question.optionB,
          question.optionC,
          question.optionD,
        ];
        const isImageOption = options.some((option) =>
          option?.includes("https://firebasestorage.googleapis.com"),
        );
        return isImageOption ? "imageOptions" : "textOptions";
      });
      setOptionValues(newOptionValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questions]);

  const handleOptionChange = (index: number, value: string) => {
    const newOptionValues = [...optionValues];
    newOptionValues[index] = value;
    setOptionValues(newOptionValues);
  };
  const getAudioLabel = (index: number) => {
    return ` Question ${index + 1}`;
  };

  return {
    handleSubmit,
    register,
    control,
    setValue,
    watch,
    reset,
    fields,
    errors,
    optionValues,
    isLoading,
    onSubmitHandler,
    handleOptionChange,
    getAudioLabel,
    questionType,
  };
};

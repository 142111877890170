import {
  Box,
  FormControl,
  FormLabel,
  Input,
  Radio,
  RadioGroup,
  Stack,
  FormErrorMessage,
  VStack,
} from "@chakra-ui/react";
import {
  FieldArrayWithId,
  UseFormRegister,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { ImageUploader } from "../../../../../components/ImageUpload";
import { AudioAndVideoUploader } from "../../../../../components/AudioAndVideoUploader";

type ListeningQuestionsSectionProps = {
  fields: FieldArrayWithId<any, any>[];
  errors: any;
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>;
  watch: UseFormWatch<any>;
  handleOptionChange: (index: number, value: string) => void;
  optionValues: string[];
  getAudioLabel: (index: number) => string;
  questionType?: "text" | "audio";
};

export const ListeningQuestionsSection: React.FC<
  ListeningQuestionsSectionProps
> = ({
  fields,
  errors,
  register,
  setValue,
  watch,
  handleOptionChange,
  optionValues,
  getAudioLabel,
  questionType = "text",
}) => {
  return (
    <VStack
      align="start"
      spacing={5}
      w="full"
      p={3}
      bg="#f7fafc"
      minH="90vh"
      border="1px solid #CCC"
      overflowY="auto"
    >
      {fields.map((item, index) => (
        <Box key={item.id} w="100%" bg={"gray.100"} p={4}>
          <FormControl
            isInvalid={!!errors.questions?.[index]?.question}
            bg={"gray.300"}
            p={3}
            borderRadius={"md"}
          >
            <FormLabel color={"blue"}>{getAudioLabel(index)}</FormLabel>

            {questionType === "text" ? (
              <Input
              placeholder="Question"
              {...register(`questions.${index}.question`)}
              bg={"#FFF"}
              required
            />
            ) : (
              <AudioAndVideoUploader
              setMediaUrl={(url) =>
                setValue(`questions.${index}.question`, url ?? "", {
                  shouldValidate: true,
                })
              }
              fileType={"audio"}
              oldAudio={watch(`questions.${index}.question`)}
            />
            )
          }
            
            <FormErrorMessage>
              {errors.questions?.[index]?.question?.message}
            </FormErrorMessage>
          </FormControl>

          <RadioGroup
            onChange={(value) => handleOptionChange(index, value)}
            value={optionValues[index] || "textOptions"}
            mb={4}
            mt={5}
          >
            <Stack direction="row">
              <Radio value="textOptions">Text Options</Radio>
              <Radio value="imageOptions">Image Options</Radio>
            </Stack>
          </RadioGroup>
          {/* Dynamic question type Section */}
          {optionValues[index] === "imageOptions" ? (
            <>
              <FormControl
                mt={2}
                isInvalid={!!errors.questions?.[index]?.correctAnswer}
                bg={"green.100"}
                p={4}
                borderRadius={"md"}
              >
                <FormLabel>(A) Correct Answer</FormLabel>
                <ImageUploader
                  setImageUrl={(url) => {
                    setValue(`questions.${index}.correctAnswer`, url ?? "", {
                      shouldValidate: true,
                    });
                  }}
                  oldImage={watch(`questions.${index}.correctAnswer`)}
                />
                <FormErrorMessage>
                  {errors.questions?.[index]?.correctAnswer?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                mt={2}
                isInvalid={!!errors.questions?.[index]?.optionB}
              >
                <FormLabel>(B) Option</FormLabel>
                <ImageUploader
                  setImageUrl={(url) => {
                    setValue(`questions.${index}.optionB`, url ?? "", {
                      shouldValidate: true,
                    });
                  }}
                  oldImage={watch(`questions.${index}.optionB`)}
                />
                <FormErrorMessage>
                  {errors.questions?.[index]?.optionB?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                mt={2}
                isInvalid={!!errors.questions?.[index]?.optionC}
              >
                <FormLabel>(C) Option</FormLabel>
                <ImageUploader
                  setImageUrl={(url) => {
                    setValue(`questions.${index}.optionC`, url ?? "", {
                      shouldValidate: true,
                    });
                  }}
                  oldImage={watch(`questions.${index}.optionC`)}
                />
                <FormErrorMessage>
                  {errors.questions?.[index]?.optionC?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                mt={2}
                isInvalid={!!errors.questions?.[index]?.optionD}
              >
                <FormLabel>(D) Option</FormLabel>
                <ImageUploader
                  setImageUrl={(url) => {
                    setValue(`questions.${index}.optionD`, url ?? "", {
                      shouldValidate: true,
                    });
                  }}
                  oldImage={watch(`questions.${index}.optionD`)}
                />
                <FormErrorMessage>
                  {errors.questions?.[index]?.optionD?.message}
                </FormErrorMessage>
              </FormControl>
            </>
          ) : (
            <>
              <FormControl
                mt={2}
                isInvalid={!!errors.questions?.[index]?.correctAnswer}
                bg={"green.100"}
                p={4}
                borderRadius={"md"}
              >
                <FormLabel>(A) Correct Answer</FormLabel>
                <Input
                  placeholder="Answer"
                  {...register(`questions.${index}.correctAnswer`)}
                  bg={"#FFF"}
                  required
                />
                <FormErrorMessage>
                  {errors.questions?.[index]?.correctAnswer?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                mt={2}
                isInvalid={!!errors.questions?.[index]?.optionB}
              >
                <FormLabel>(B) Option</FormLabel>
                <Input
                  placeholder="Option B"
                  {...register(`questions.${index}.optionB`)}
                  required
                />
                <FormErrorMessage>
                  {errors.questions?.[index]?.optionB?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                mt={2}
                isInvalid={!!errors.questions?.[index]?.optionC}
              >
                <FormLabel>(C) Option</FormLabel>
                <Input
                  placeholder="Option C"
                  {...register(`questions.${index}.optionC`)}
                  required
                />
                <FormErrorMessage>
                  {errors.questions?.[index]?.optionC?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                mt={2}
                isInvalid={!!errors.questions?.[index]?.optionD}
              >
                <FormLabel>(D) Option</FormLabel>
                <Input
                  placeholder="Option D"
                  {...register(`questions.${index}.optionD`)}
                  required
                />
                <FormErrorMessage>
                  {errors.questions?.[index]?.optionD?.message}
                </FormErrorMessage>
              </FormControl>
            </>
          )}
        </Box>
      ))}
    </VStack>
  );
};

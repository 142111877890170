import { Route } from "react-router-dom";
import { Dashboard } from "../apps/dashboard";
import { AllStudents } from "../apps/admin/students";
import { Teachers } from "../apps/admin/teachers";


// eslint-disable-next-line import/no-anonymous-default-export
export default [
  <>
   <Route path="/dashboard" element={<Dashboard />} />
   <Route path="/all-students" element={<AllStudents />} />
   <Route path="/teachers" element={<Teachers />} />
  </>,
];

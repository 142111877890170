import axios from "axios";

import { config } from "../../config";
import { getTokenAndConfig } from "./authService";

const API_URL = config.baseUrl.default;

const teachers = async (thunkAPI: any) => {
  const response = await axios.get(
      API_URL +
        `teachers`,
      getTokenAndConfig(thunkAPI),
    );
    return response.data;
};
const teacherService = {
  teachers,
};

export default teacherService;

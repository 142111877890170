import React from "react";
import {
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  FormControl,
  FormLabel,
  Text,
  useToast,
  ModalFooter,
  Flex,
  Spacer,
  Box,
  ModalCloseButton,
  Input,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { AddNewStudentFormData, AddNewStudentSchema } from "../schema";
import { useAppDispatch } from "../../../redux/hooks/useTypedSelector";
import { useUser } from "../../../hooks/use-user";
import { createStudentAction } from "../../../redux/slice/student/studentSlice";

export const AddNewStudentModal = () => {
  const user  = useUser();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const dispatch = useAppDispatch();
  const toast = useToast();

  const {
    handleSubmit,
    register,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(AddNewStudentSchema),
    defaultValues: {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      accountType: "Student",
      isTeacherCreatingUser: true,
      teacherId: user.id.toString(),
    },
  });

  const create = async (payload: AddNewStudentFormData) => {
    try {
     
      await dispatch(createStudentAction(payload));
      toast({
        title: "Student Created",
        description: "Student Created Successful",
        status: "success",
        position: "top-right",
        duration: 9000,
        isClosable: true,
      });
      setTimeout(() => {
        // eslint-disable-next-line no-restricted-globals
        // location.reload();
        window.location.href = "/students";
      }, 1000);
    } catch (error: any) {
      console.error(error);
      toast({
        title: "Error Creating Student",
        description: error.message,
        status: "error",
        position: "top-right",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <>
      <Button onClick={onOpen}>
        <AddIcon fontSize={14} />
        <Text ml={1} mr={1}>
          Add Student
        </Text>
      </Button>

      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        size={"xl"}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
      >
        <ModalOverlay />
        <Box as={"form"} mt={0} onSubmit={handleSubmit(create)}>
          <ModalContent>
            <ModalHeader> Add New Student</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Flex direction="column" w="100%" background="transparent">
                <FormControl isRequired>
                  <FormLabel>First Name</FormLabel>
                  <Input
                    type="text"
                    {...register("firstName")}
                    placeholder="First Name"
                    required
                    isInvalid={!!errors.firstName}
                    mb={4}
                  />
                  <FormLabel>Last Name</FormLabel>
                  <Input
                    type="text"
                    {...register("lastName")}
                    placeholder="Last Name"
                    required
                    isInvalid={!!errors.lastName}
                    mb={4}
                  />
                  <FormLabel>Email</FormLabel>
                  <Input
                    type="email"
                    {...register("email")}
                    placeholder="Your email address"
                    id="email"
                    required
                    isInvalid={!!errors.email}
                    mb={4}
                  />

                  <FormLabel>Password</FormLabel>
                  <Input
                    type="password"
                    {...register("password")}
                    placeholder="Your password"
                    id="password"
                    isInvalid={!!errors.password}
                    required
                    mb={4}
                  />
                </FormControl>
              </Flex>
            </ModalBody>

            <ModalFooter>
              <Flex
                width={"100%"}
                borderRadius="lg"
                align="center"
                justify="space-between"
              >
                <Button
                  variant="outline"
                  padding={"0px 40px"}
                  onClick={onClose}
                >
                  Cancel
                </Button>
                <Spacer />
                <Button
                  type="submit"
                  padding={"0px 40px"}
                  isLoading={isSubmitting}
                >
                  Save
                </Button>
              </Flex>
            </ModalFooter>
          </ModalContent>
        </Box>
      </Modal>
    </>
  );
};

import {
  Box,
  FormControl,
  FormLabel,
  Select,
  FormErrorMessage,
  VStack,
} from "@chakra-ui/react";
import {
  UseFormRegister,
  UseFormWatch,
  UseFormSetValue,
} from "react-hook-form";
import ReactQuill from "react-quill";
import { AudioAndVideoUploader } from "../../../../../components/AudioAndVideoUploader";
import {
  testTimeOptions,
  reactQuillModules,
  reactQuillFormats,
} from "../../../../../utils/helperFuntions";
type ListeningQuestionInstructionsSectionProps = {
  register: UseFormRegister<any>;
  errors: any;
  watch: UseFormWatch<any>;
  setValue: UseFormSetValue<any>;
  fileType?: "audio" | "video";
};

export const ListeningQuestionInstructionsSection: React.FC<
ListeningQuestionInstructionsSectionProps
> = ({ register, errors, watch, setValue, fileType = "audio" }) => {
  return (
    <VStack
      align="start"
      spacing={5}
      w="full"
      p={3}
      border="1px solid #CCC"
      minH="90vh"
      overflowY="auto"
    >
      <Box w="100%">
        <FormControl isInvalid={!!errors.time}>
          <FormLabel>Question Time</FormLabel>
          <Select
            placeholder="Select question time"
            bg="#FFF"
            {...register("time")}
            required
          >
            {testTimeOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
          </Select>
          <FormErrorMessage>{errors.time?.message}</FormErrorMessage>
        </FormControl>
      </Box>
      <Box w="100%" p={4}>
        <FormControl mt={4} isInvalid={!!errors.listeningInstructionOne}>
          <FormLabel>Instruction</FormLabel>
          <ReactQuill
            theme="snow"
            value={watch("listeningInstructionOne")}
            onChange={(content) => setValue("listeningInstructionOne", content)}
            modules={reactQuillModules}
            formats={reactQuillFormats}
            style={{ height: 200 }}
          />
        </FormControl>
      </Box>
      <Box w="100%" p={4} mt={4}>
        <FormControl mt={4} isInvalid={!!errors.listeningInstructionTwo}>
          <FormLabel>Instruction two</FormLabel>
          <ReactQuill
            theme="snow"
            value={watch("listeningInstructionTwo")}
            onChange={(content) => setValue("listeningInstructionTwo", content)}
            modules={reactQuillModules}
            formats={reactQuillFormats}
            style={{ height: 200 }}
          />
        </FormControl>
      </Box>
      <Box w="100%" p={4} mt={4}>
        <FormControl
          mt={4}
          isInvalid={!!errors.listeningToProblemSolvingAudioOne}
        >
          <FormLabel textTransform={"capitalize"}>{fileType} Conversation</FormLabel>
          <AudioAndVideoUploader
            setMediaUrl={(url) =>
              setValue("listeningToProblemSolvingAudioOne", url ?? "", {
                shouldValidate: true,
              })
            }
            fileType={fileType}
            oldAudio={watch("listeningToProblemSolvingAudioOne")}
          />
          <FormErrorMessage>
            {errors.listeningToProblemSolvingAudioOne?.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl mt={4} isInvalid={!!errors.listeningDefaultNumOfQuestion}>
          <FormLabel>Number of Question</FormLabel>
          <Select
            autoComplete="Number of Questions"
            placeholder="Number of Questions"
            bg="#FFF"
            {...register("listeningDefaultNumOfQuestion")}
            required
          >
            {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((value, key) => (
              <option key={key} value={value}>
                {value}
              </option>
            ))}
          </Select>
          <FormErrorMessage>
            {errors.listeningDefaultNumOfQuestion?.message}
          </FormErrorMessage>
        </FormControl>
      </Box>
    </VStack>
  );
};

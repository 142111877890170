import React, { useMemo, useState } from "react";
import { Box, Image, RadioGroup, Radio, Stack } from "@chakra-ui/react";
import { MockQuestion } from "../../../../types/reading-types";
import { shuffleOptions } from "../../../../utils/helperFuntions";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useAnswerStore } from "../../../../redux/zustand-store";
import { userAnswerSchema, UserAnswerSchema } from "../../reading/schema";
import { ListeningQuestionType } from "../../../../types/listening-types";

type RadioResponseProps = {
  userId: string;
  question: MockQuestion;
  isDisplayOptions?: boolean;
  questionType: ListeningQuestionType;
};

export const RadioResponse: React.FC<RadioResponseProps> = ({
  userId,
  question,
  isDisplayOptions = true,
  questionType,
}) => {
  const { responses, addResponse, updateResponse } = useAnswerStore();
  const { handleSubmit, setValue } = useForm({
    resolver: yupResolver(userAnswerSchema),
  });

  const [selectedOption, setSelectedOption] = useState<string | null>(null);

  const options = useMemo(() => {
    let optionsArray = [
      question.correctAnswer,
      question.optionB,
      question.optionC,
      question.optionD,
      question.optionE ?? "",
    ];
    optionsArray = shuffleOptions(optionsArray);
    return optionsArray;
  }, [question]);

  const onSubmit = (data: UserAnswerSchema) => {
    const existingResponse = responses.find(
      (response) =>
        response.userId === userId && response.question === question.question,
    );

    if (existingResponse) {
      updateResponse(userId, question.question, data.userAnswer);
    } else {
      addResponse({
        userId,
        question: question.question,
        userAnswer: data.userAnswer,
        correctAnswer: question.correctAnswer,
        questionType: questionType || "",
      });
    }
  };

  const handleChange = (value: string) => {
    setSelectedOption(value);
    setValue("userAnswer", value);
    handleSubmit(onSubmit)();
  };

  return (
    <Box p={1} mt={-5}>
      {isDisplayOptions ? (
        <RadioGroup onChange={handleChange} value={selectedOption || ""}>
          <Stack mt={9}>
            {options.map((option, index) => (
              <Radio key={index} value={option}>
                {option.includes("firebasestorage") ?
                <Image src={option} w={200} mr={4} mt={-1} />
                : option }
              </Radio>
            ))}
          </Stack>
        </RadioGroup>
      ) : null}
    </Box>
  );
};

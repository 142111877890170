import { FC } from "react";
import {
  useDisclosure,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  SimpleGrid,
} from "@chakra-ui/react";
import { readingQuestionsSections, setNextTestNum } from "../../../../utils/helperFuntions";
import { ReadingQuestion } from "../schema";

type CreateReadingQuestionProps = {
  questions: ReadingQuestion[];
};

export const CreateReadingQuestion: FC<CreateReadingQuestionProps> = ({ questions }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Button onClick={onOpen} colorScheme="teal" size="md" mb={9}>
        Add New Question
      </Button>

      <Modal isOpen={isOpen} onClose={onClose} size={"xl"}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Select Question Type</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8} p={0} mt={9}>
              {readingQuestionsSections.map((option) => (
                <Button
                  key={option.value}
                  as={"a"}
                  href={`/add-reading-question/${option.value}/${setNextTestNum(questions, option.value)}`}
                  colorScheme="teal"
                  variant="outline"
                  p={9}
                >
                  {option.label}
                </Button>
              ))}
            </SimpleGrid>
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose}>Cancel</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

import {
  Box,
  Flex,
  IconButton,
  Button,
  Stack,
  Collapse,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import {
  HamburgerIcon,
  CloseIcon,
} from "@chakra-ui/icons";
import { Logo } from "../components/Logo";
import { useNavigate } from "react-router-dom";
import { useUser } from "../hooks/use-user";
import { useEffect } from "react";

export default function PublicNabBar() {
  const navigate = useNavigate();
  const user = useUser();
  const { isOpen, onToggle } = useDisclosure();
  const navigateToPricing = () => {
    navigate("/", { state: { scrollToPricing: true } });
  };

  useEffect(() => {
    if (user) {
      window.location.href = "/dashboard";
    }
  }, [user]);

  return (
    <Box>
      <Flex
        bg={useColorModeValue("white", "gray.800")}
        color={useColorModeValue("gray.600", "white")}
        minH={"60px"}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderColor={useColorModeValue("gray.200", "gray.900")}
        backgroundColor="white"
        shadow={2}
        align={"center"}
        as="header"
        position="fixed"
        w="100%"
        zIndex={1000}
        boxShadow={"md"}
      >
        <Flex
          flex={{ base: 1, md: "auto" }}
          ml={{ base: -2 }}
          display={{ base: "flex", md: "none" }}
        >
          <IconButton
            onClick={onToggle}
            icon={
              isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />
            }
            variant={"ghost"}
            aria-label={"Toggle Navigation"}
          />
        </Flex>
        <Flex flex={{ base: 1 }} justify={{ base: "center", md: "start" }}>
         <Logo />
        </Flex>

        <Stack
          flex={{ base: 1, md: 0 }}
          justify={"flex-end"}
          direction={"row"}
          spacing={6}
        >
           <Button
            colorScheme='teal' 
            variant='link'
            width={120}
            onClick={navigateToPricing}
          >
           Pricing
          </Button>
          <Button
            as={"a"}
            href="/sign-in"
            colorScheme='teal' 
            variant='outline'
            width={120}
            borderRadius={"xl"}
          >
            Sign In
          </Button>
        </Stack>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
       
      </Collapse>
    </Box>
  );
}


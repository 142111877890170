import {
  Box,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
  HStack,
  VStack,
  Container,
  Flex,
  SimpleGrid,
  FormErrorMessage,
  useToast,
  Select,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFieldArray, useForm } from "react-hook-form";
import { AudioAndVideoUploader } from "../../../../components/AudioAndVideoUploader";
import {
  ListeningTestFormData,
  ListeningTestValidationSchema,
} from "../schema";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/hooks/useTypedSelector";
import {
  createQuestionAction,
  QType,
  updateQuestionAction,
} from "../../../../redux/slice/mock-test/questionBankSlice";
import { useUser } from "../../../../hooks/use-user";
import { testTimeOptions } from "../../../../utils/helperFuntions";
import { useQuestionData } from "../../../../hooks/use-question-data";
import { useParams } from "react-router-dom";

export const CreateListeningPracticeQuestionForm = () => {
  const user = useUser();
  const { isSuccess, isError, isLoading, errorMessage } = useAppSelector(
    (state) => state.questionBank,
  );
  const { testNum } = useParams();
  const dispatch = useAppDispatch();
  const toast = useToast();

  const {
    handleSubmit,
    register,
    control,
    setValue,
    reset,
    formState: { errors },
  } = useForm<ListeningTestFormData>({
    resolver: yupResolver(ListeningTestValidationSchema),
    defaultValues: {
      questionsType: "PracticeQuestion",
      listeningDefaultNumOfQuestion: "1",
      testNum: testNum,
      bodyContent: "",
      time: "",
      questions: [
        {
          question: "",
          correctAnswer: "",
          optionB: "",
          optionC: "",
          optionD: "",
        },
      ],
    },
  });
  const { question, questionData } = useQuestionData(reset);

  const { fields } = useFieldArray({
    control,
    name: "questions",
  });

  const onSubmitHandler = async (data: ListeningTestFormData) => {
    const payLoad = {
      id: question?.id ?? null,
      user: user.id,
      type: "Listening" as QType,
      testNum: data.testNum ?? "",
      questionType: "PracticeQuestion",
      data: JSON.stringify(data),
    };
    if (questionData) {
      await dispatch(updateQuestionAction(payLoad));
    } else {
      await dispatch(createQuestionAction(payLoad));
    }

    if (isSuccess) {
      toast({
        title: "Question saved successfully.",
        status: "success",
        position: "top-right",
        duration: 9000,
        isClosable: true,
      });
      setTimeout(() => {
        window.location.href = "/admin-listening";
      }, 1000);
    } else if (isError) {
      toast({
        title: "Error",
        description: errorMessage,
        status: "error",
        position: "top-right",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Box bg="gray.50" minH="100vh">
      <Container
        maxW="container.2xlg"
        bg="white"
        p={8}
        borderRadius="md"
        boxShadow="md"
        mt={9}
        as="form"
        onSubmit={handleSubmit(onSubmitHandler)}
      >
        <Flex
          direction="row"
          justify="space-between"
          align="center"
          bg="gray.200"
          border="1px #CCC solid"
          p={3}
          h={10}
        >
          <Heading size="md">Add Practice Question</Heading>
        </Flex>

        <SimpleGrid columns={2} spacing={8} p={0}>
          {/* Audio Conversation Input */}
          <VStack
            align="start"
            spacing={5}
            w="full"
            p={3}
            border="1px solid #CCC"
            minH="90vh"
            maxH="90vh"
            overflowY="auto"
          >
            <Box w="100%">
              <FormControl isInvalid={!!errors.time} mb={9}>
                <FormLabel>Question Time</FormLabel>
                <Select
                  placeholder="Select question time"
                  bg="#FFF"
                  {...register("time")}
                  required
                >
                  {testTimeOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>{errors.time?.message}</FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={!!errors.bodyContent}>
                <FormLabel>Audio Conversation</FormLabel>
                <AudioAndVideoUploader
                  setMediaUrl={(url) =>
                    setValue("bodyContent", url ?? "", { shouldValidate: true })
                  }
                  fileType="audio"
                  oldAudio={questionData?.bodyContent}
                />
                <FormErrorMessage>
                  {errors.bodyContent?.message}
                </FormErrorMessage>
              </FormControl>
            </Box>
          </VStack>

          <VStack
            align="start"
            spacing={5}
            w="full"
            p={3}
            bg="#f7fafc"
            minH="90vh"
            border="1px solid #CCC"
            overflowY="auto"
          >
            {/* Questions Section */}
            {fields.map((item, index) => (
              <Box key={item.id} w="100%">
                {/* <FormControl isInvalid={!!errors.questions?.[index]?.question}>
                <FormLabel>Question {index + 1}</FormLabel>
                <Input placeholder="Question" {...register(`questions.${index}.question`)} required />
                <FormErrorMessage>{errors.questions?.[index]?.question?.message}</FormErrorMessage>
              </FormControl> */}

                <FormControl
                  isInvalid={!!errors.questions?.[index]?.correctAnswer}
                  mt={2}
                >
                  <FormLabel>Correct Answer (A)</FormLabel>
                  <Input
                    placeholder="Correct Answer"
                    {...register(`questions.${index}.correctAnswer`)}
                    required
                  />
                  <FormErrorMessage>
                    {errors.questions?.[index]?.correctAnswer?.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  isInvalid={!!errors.questions?.[index]?.optionB}
                  mt={2}
                >
                  <FormLabel>Option B</FormLabel>
                  <Input
                    placeholder="Option B"
                    {...register(`questions.${index}.optionB`)}
                    required
                  />
                  <FormErrorMessage>
                    {errors.questions?.[index]?.optionB?.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  isInvalid={!!errors.questions?.[index]?.optionC}
                  mt={2}
                >
                  <FormLabel>Option C</FormLabel>
                  <Input
                    placeholder="Option C"
                    {...register(`questions.${index}.optionC`)}
                    required
                  />
                  <FormErrorMessage>
                    {errors.questions?.[index]?.optionC?.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  isInvalid={!!errors.questions?.[index]?.optionD}
                  mt={2}
                >
                  <FormLabel>Option D</FormLabel>
                  <Input
                    placeholder="Option D"
                    {...register(`questions.${index}.optionD`)}
                    required
                  />
                  <FormErrorMessage>
                    {errors.questions?.[index]?.optionD?.message}
                  </FormErrorMessage>
                </FormControl>
              </Box>
            ))}
          </VStack>
        </SimpleGrid>

        <HStack justify="space-between" mt={10}>
          <Button colorScheme="red" type="reset">
            Reset Form
          </Button>
          <Button type="submit" colorScheme="teal" isLoading={isLoading}>
            Submit Form
          </Button>
        </HStack>
      </Container>
    </Box>
  );
};

import React, { useEffect, useState } from "react";
import {
  Box,
  Container,
  Heading,
  VStack,
  Text,
  SimpleGrid,
  Spinner,
  Flex,
} from "@chakra-ui/react";
import { useUser } from "../../hooks/use-user";
import {
  useAppSelector,
  useAppDispatch,
} from "../../redux/hooks/useTypedSelector";
import {
  ResultPayload,
  getMockTestResultAction,
} from "../../redux/slice/mock-test/mockTestResultSlice";
import { useParams } from "react-router-dom";
import { decryptString } from "../../utils/encryption";
import { TestResult } from "../../types/test-result-type";
import { ListeningResultPreview } from "./ListeningResultPreview";
import { ReadingResultPreview } from "./ReadingResultPreview";
import { WritingResultPreview } from "./WritingResultPreview";
import { SpeakingResultPreview } from "./SpeakingResultPreview";
import { FinalResultPreview } from "./FinalResultPreview";

const getTestDataByType = (
  data: TestResult[],
  testType: string,
): TestResult[] => {
  return data?.filter((item) => item?.testType === testType);
};

export const CompleteTestResult: React.FC = () => {
  const user = useUser();
  const { isLoading } = useAppSelector(
    (state: { mockTestResult: any }) => state.mockTestResult,
  );
  const [mockTestResults, setMockTestResults] = useState<
    ResultPayload[] | null
  >(null);
  const { testNum } = useParams();

  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const res = await dispatch(
        getMockTestResultAction({
          testNum: decryptString(testNum?.toString() ?? ""),
          userID: user.id.toString(),
        }),
      );
      const results: ResultPayload[] = res.payload;

      setMockTestResults(results);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, testNum, user.id]);

  if (isLoading) {
    return (
      <Flex justify="center" align="center" minH="100vh">
        <Spinner size="xl" />
      </Flex>
    );
  }

  const listeningResult = getTestDataByType(
    mockTestResults as unknown as TestResult[],
    "Listening",
  ) ?? [];

  const readingResult = getTestDataByType(
    mockTestResults as unknown as TestResult[],
    "Reading",
  ) ?? [];

  const writingResult = getTestDataByType(
    mockTestResults as unknown as TestResult[],
    "Writing",
  ) ?? [];

  const speakingResult = getTestDataByType(
    mockTestResults as unknown as TestResult[],
    "Speaking",
  ) ?? [];

  return (
    <Box minH="100vh" py={10}>
      <Container
        maxW="container.xl"
        bg="white"
        borderRadius="md"
        boxShadow="lg"
        p={6}
      >
        <VStack spacing={8} align="stretch">
          <Heading size="lg" textAlign="center" color="blue.900">
            Complete Mock Test Results
          </Heading>

          <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8} w="30%">
            <Box>
              {listeningResult && listeningResult?.length > 0 ? (
                <ListeningResultPreview result={listeningResult[0]} />
              ) : (
                <Text color="blue.500" textAlign="center">
                  No listening test result available
                </Text>
              )}
            </Box>
            <Box>
              {readingResult && readingResult?.length > 0 ? (
                <ReadingResultPreview result={readingResult[0]} />
              ) : (
                <Text color="blue.500" textAlign="center">
                  No reading test result available
                </Text>
              )}
            </Box>
            <Box>
              {writingResult && writingResult.length > 0 ? (
                <WritingResultPreview result={writingResult[0]} />
              ) : (
                <Text color="blue.500" textAlign="center">
                  No writing test result available
                </Text>
              )}
            </Box>
            <Box>
              {speakingResult && speakingResult.length > 0 ? (
                <SpeakingResultPreview result={speakingResult[0]} />
              ) : (
                <Text color="blue.500" textAlign="center">
                  No speaking test result available
                </Text>
              )}
            </Box>
          </SimpleGrid>
          <FinalResultPreview
            listeningResult = {listeningResult[0]}
            readingResult = {readingResult[0]}
            writingResult = {writingResult[0]}
            speakingResult = {speakingResult[0]}
          />
        </VStack>
      </Container>
    </Box>
  );
};

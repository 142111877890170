import { DataItem } from "../reading/schema";
export const sections = [
  {
    id: 0,
    name: "Practice",
  },
  {
    id: 1,
    name: "Reading_Correspondence",
  },
  {
    id: 2,
    name: "Reading_to_Apply_Diagram",
  },
  {
    id: 3,
    name: "Reading_for_Information",
  },
  {
    id: 4,
    name: "Reading_for_Viewpoints",
  },
];

export const PracticeQuestion: DataItem = {
  id: 1,
  header: "Practice Test 1 - Reading Practice Task",
  instruction: `Canada is bordered by oceans on three sides. The Arctic Ocean is to the north, adjacent to Yukon, Northwest Territories, and Nunavut. The Pacific Ocean lies off the west coast of British Columbia. To the east, the Atlantic Ocean borders Canada's Maritime provinces, which consist of Nova Scotia, New Brunswick, and Prince Edward Island.`,
  questionData: [
    {
      instruction:
        "Using the drop-down menu (▼), choose the best option according to the information given in the message",
      questionType: "Practice",
      questions: [
        {
          question: "1. Which province is located on the Pacific Ocean?",
          options: [
            "Nova Scotia",
            "New Brunswick",
            "Prince Edward Island",
            "British Columbia",
          ],
          correctAnswer: "British Columbia",
        },
      ],
    },
  ],
};

export const ReadingCorrespondence: DataItem = {
  id: 1,
  header: "Mock Test- Reading Correspondence Task",
  instruction: `Dear Joan,

    I enjoyed receiving your postcards with the glowing descriptions of your recent trip to Europe. Of course, when I told Benny about your trip, he wanted to revisit Paris immediately, especially after an unusual dining experience we had here last week. Being the food critic for the Winnipeg Daily News for ten years now, Benny thought that he’d seen and tasted everything. Well, that was until last Friday night.

    We went to a new restaurant called Le French, on the corner of Davis Street and Park Avenue. Imagine our surprise when we discovered that this restaurant specializes in Italian cuisine. When Benny asked the waiter why the owners decided to name an Italian restaurant Le French, he simply said, “Don’t ask me!” Then a little while later, this same waiter knocked over Benny’s wine glass, spilling red wine onto Benny’s pants. The waiter merely said, “Oops, sorry!” and left us to fend for ourselves. After finding some extra napkins and cleaning up the wine, Benny fleetingly thought of leaving, but, being a consummate professional reviewer and hoping that the food might surpass the service, we stayed. Was that ever a mistake!

    I ordered spaghetti with tomato sauce—that’s about as simple a meal as you can get—and Benny decided to try the restaurant specialty, lasagna. Well, my spaghetti was not fully cooked, and the tomato sauce tasted like the canned sauces you would find on a supermarket shelf. Benny’s lasagna was inedible—full of dry cheese and very little sauce. Fortunately, the fresh bread eased our hunger somewhat, although the butter was cold and much too hard to spread, which didn’t improve our mood.

    As we were about to leave, the restaurant owner apologized for ruining Benny’s pants and said that Benny didn’t have to pay for his meal. However, he did not offer to compensate Benny for his ruined trousers. Benny wants to give Le French a special award for the worst culinary experience he’s ever had, but I’m trying to calm him down a bit before he writes a highly critical review.

    Give us a call before you come to town next month and we’ll arrange to go somewhere special to celebrate your birthday—but rest assured that it won’t be Le French.

    Cheers,
    Tracy`,
  questionData: [
    {
      instruction:
        "Using the drop-down menu (▼), choose the best option according to the information given in the message",
      questionType: "Reading_Correspondence",
      questions: [
        {
          question: "1. Benny",
          options: [
            "Visited Le French alone",
            "Had a great experience",
            "Did not pay for his meal",
            "Loves Italian cuisine",
          ],
          correctAnswer: "Did not pay for his meal",
        },
        {
          question: "2. The restaurant Le French specializes in which cuisine?",
          options: ["French", "Italian", "Chinese", "Mexican"],
          correctAnswer: "Italian",
        },
        {
          question: "3. Who received postcards with glowing descriptions?",
          options: ["Benny", "Tracy", "Joan", "The waiter"],
          correctAnswer: "Tracy",
        },
        {
          question: "4. What did the waiter spill on Benny's pants?",
          options: ["Water", "Wine", "Soup", "Juice"],
          correctAnswer: "Wine",
        },
        {
          question:
            "5. How long has Benny been a food critic for the Winnipeg Daily News?",
          options: ["5 years", "10 years", "15 years", "20 years"],
          correctAnswer: "10 years",
        },
        {
          question: "6. What was the restaurant's specialty?",
          options: ["Spaghetti", "Pizza", "Lasagna", "Risotto"],
          correctAnswer: "Lasagna",
        },
      ],
    },
    {
      instruction:
        "Here is a response to the message. Complete the response by filling in the blanks. Select the best choice for each blank from the drop-down",
      questionType: "Reading_to_Apply_Diagram",
      questions: [
        {
          question:
            "It sounds like you had a really ** experience at Le French.",
          options: ["terrible", "wonderful", "okay", "expensive"],
          correctAnswer: "terrible",
        },
        {
          question:
            "I understand why Benny would love to visit France again; both the French and Italian food here ** Le French.",
          options: [
            "is better than",
            "is worse than",
            "is similar to",
            "is different from",
          ],
          correctAnswer: "is different from",
        },
        {
          question:
            "Remember that Le French is a new restaurant, so maybe you should go there one more time before Benny **.",
          options: ["writes a review", "leaves", "complains", "returns"],
          correctAnswer: "writes a review",
        },
        {
          question:
            "I know that the ** was really bad, but it may be that this was not the regular waiter.",
          options: ["service", "food", "price", "ambiance"],
          correctAnswer: "service",
        },
        {
          question:
            "Having said that, I think we should **, when we meet next month.",
          options: [
            "try another restaurant",
            "complain",
            "go back",
            "order takeout",
          ],
          correctAnswer: "try another restaurant",
        },
      ],
    },
  ],
};

export const ReadingToApplyADiagram: DataItem = {
  id: 1,
  header: "Mock Test-  Reading to Apply Diagram Task",
  instruction: "image",
  photo: "one.png",
  questionData: [
    {
      instruction:
        "Using the drop-down menu (▼), choose the best option according to the information given in the message",
      questionType: "Reading_to_Apply_Diagram",
      questions: [
        {
          question: "1. Benny",
          options: [
            "Visited Le French alone",
            "Had a great experience",
            "Did not pay for his meal",
            "Loves Italian cuisine",
          ],
          correctAnswer: "Did not pay for his meal",
        },
        {
          question: "2. The restaurant Le French specializes in which cuisine?",
          options: ["French", "Italian", "Chinese", "Mexican"],
          correctAnswer: "Italian",
        },
        {
          question: "3. Who received postcards with glowing descriptions?",
          options: ["Benny", "Tracy", "Joan", "The waiter"],
          correctAnswer: "Tracy",
        },
        {
          question: "4. What did the waiter spill on Benny's pants?",
          options: ["Water", "Wine", "Soup", "Juice"],
          correctAnswer: "Wine",
        },
        {
          question:
            "5. How long has Benny been a food critic for the Winnipeg Daily News?",
          options: ["5 years", "10 years", "15 years", "20 years"],
          correctAnswer: "10 years",
        },
        {
          question: "6. What was the restaurant's specialty?",
          options: ["Spaghetti", "Pizza", "Lasagna", "Risotto"],
          correctAnswer: "Lasagna",
        },
      ],
    },
    {
      instruction:
        "Here is a response to the message. Complete the response by filling in the blanks. Select the best choice for each blank from the drop-down",
      questionType: "Reading_to_Apply_Diagram",
      questions: [
        {
          question:
            "It sounds like you had a really ** experience at Le French.",
          options: ["terrible", "wonderful", "okay", "expensive"],
          correctAnswer: "terrible",
        },
        {
          question:
            "I understand why Benny would love to visit France again; both the French and Italian food here ** Le French.",
          options: [
            "is better than",
            "is worse than",
            "is similar to",
            "is different from",
          ],
          correctAnswer: "is different from",
        },
        {
          question:
            "Remember that Le French is a new restaurant, so maybe you should go there one more time before Benny **.",
          options: ["writes a review", "leaves", "complains", "returns"],
          correctAnswer: "writes a review",
        },
        {
          question:
            "I know that the ** was really bad, but it may be that this was not the regular waiter.",
          options: ["service", "food", "price", "ambiance"],
          correctAnswer: "service",
        },
        {
          question:
            "Having said that, I think we should **, when we meet next month.",
          options: [
            "try another restaurant",
            "complain",
            "go back",
            "order takeout",
          ],
          correctAnswer: "try another restaurant",
        },
      ],
    },
  ],
};

export const ReadingForInformation: DataItem = {
  id: 1,
  header: "Mock Test - Reading for Information Task",
  instruction: `
    A. Canada is surrounded on three sides by oceans. To the north, the
    Arctic Ocean borders Yukon, Northwest Territories, and Nunavut.
    Off the west coast of British Columbia is the Pacific Ocean. The
    Atlantic Ocean, meanwhile, sits to the east of Canada's Maritime
    provinces, which include Nova Scotia, New Brunswick, and Prince
    Edward Island.
    
    B. Canada is surrounded on three sides by oceans. To the north, the
    Arctic Ocean borders Yukon, Northwest Territories, and Nunavut.
    Off the west coast of British Columbia is the Pacific Ocean. The
    Atlantic Ocean, meanwhile, sits to the east of Canada's Maritime
    provinces, which include Nova Scotia, New Brunswick, and Prince
    Edward Island.
    
    C. Canada is surrounded on three sides by oceans. To the north, the
    Arctic Ocean borders Yukon, Northwest Territories, and Nunavut.
    Off the west coast of British Columbia is the Pacific Ocean. The
    Atlantic Ocean, meanwhile, sits to the east of Canada's Maritime
    provinces, which include Nova Scotia, New Brunswick, and Prince
    Edward Island.
    
    D. Canada is surrounded on three sides by oceans. To the north, the
    Arctic Ocean borders Yukon, Northwest Territories, and Nunavut.
    Off the west coast of British Columbia is the Pacific Ocean. The
    Atlantic Ocean, meanwhile, sits to the east of Canada's Maritime
    provinces, which include Nova Scotia, New Brunswick, and Prince
    Edward Island.`,
  questionData: [
    {
      instruction:
        "Using the drop-down menu (▼), choose the best option according to the information given in the message",
      questionType: "Reading_for_Information",
      questions: [
        {
          question: "1. Benny",
          options: [
            "Visited Le French alone",
            "Had a great experience",
            "Did not pay for his meal",
            "Loves Italian cuisine",
          ],
          correctAnswer: "Did not pay for his meal",
        },
        {
          question: "2. The restaurant Le French specializes in which cuisine?",
          options: ["French", "Italian", "Chinese", "Mexican"],
          correctAnswer: "Italian",
        },
        {
          question: "3. Who received postcards with glowing descriptions?",
          options: ["Benny", "Tracy", "Joan", "The waiter"],
          correctAnswer: "Tracy",
        },
        {
          question: "4. What did the waiter spill on Benny's pants?",
          options: ["Water", "Wine", "Soup", "Juice"],
          correctAnswer: "Wine",
        },
        {
          question:
            "5. How long has Benny been a food critic for the Winnipeg Daily News?",
          options: ["5 years", "10 years", "15 years", "20 years"],
          correctAnswer: "10 years",
        },
        {
          question: "6. What was the restaurant's specialty?",
          options: ["Spaghetti", "Pizza", "Lasagna", "Risotto"],
          correctAnswer: "Lasagna",
        },
      ],
    },
  ],
};

export const ReadingForViewpoints: DataItem = {
  id: 1,
  header: "Mock Test- Reading for Viewpoints Task",
  instruction: `Dear Joan,

    I enjoyed receiving your postcards with the glowing descriptions of your recent trip to Europe. Of course, when I told Benny about your trip, he wanted to revisit Paris immediately, especially after an unusual dining experience we had here last week. Being the food critic for the Winnipeg Daily News for ten years now, Benny thought that he’d seen and tasted everything. Well, that was until last Friday night.

    We went to a new restaurant called Le French, on the corner of Davis Street and Park Avenue. Imagine our surprise when we discovered that this restaurant specializes in Italian cuisine. When Benny asked the waiter why the owners decided to name an Italian restaurant Le French, he simply said, “Don’t ask me!” Then a little while later, this same waiter knocked over Benny’s wine glass, spilling red wine onto Benny’s pants. The waiter merely said, “Oops, sorry!” and left us to fend for ourselves. After finding some extra napkins and cleaning up the wine, Benny fleetingly thought of leaving, but, being a consummate professional reviewer and hoping that the food might surpass the service, we stayed. Was that ever a mistake!

    I ordered spaghetti with tomato sauce—that’s about as simple a meal as you can get—and Benny decided to try the restaurant specialty, lasagna. Well, my spaghetti was not fully cooked, and the tomato sauce tasted like the canned sauces you would find on a supermarket shelf. Benny’s lasagna was inedible—full of dry cheese and very little sauce. Fortunately, the fresh bread eased our hunger somewhat, although the butter was cold and much too hard to spread, which didn’t improve our mood.

    As we were about to leave, the restaurant owner apologized for ruining Benny’s pants and said that Benny didn’t have to pay for his meal. However, he did not offer to compensate Benny for his ruined trousers. Benny wants to give Le French a special award for the worst culinary experience he’s ever had, but I’m trying to calm him down a bit before he writes a highly critical review.

    Give us a call before you come to town next month and we’ll arrange to go somewhere special to celebrate your birthday—but rest assured that it won’t be Le French.

    Cheers,
    Tracy`,
  questionData: [
    {
      instruction:
        "Using the drop-down menu (▼), choose the best option according to the information given in the message",
      questionType: "Reading_for_Viewpoints",
      questions: [
        {
          question: "1. Benny",
          options: [
            "Visited Le French alone",
            "Had a great experience",
            "Did not pay for his meal",
            "Loves Italian cuisine",
          ],
          correctAnswer: "Did not pay for his meal",
        },
        {
          question: "2. The restaurant Le French specializes in which cuisine?",
          options: ["French", "Italian", "Chinese", "Mexican"],
          correctAnswer: "Italian",
        },
        {
          question: "3. Who received postcards with glowing descriptions?",
          options: ["Benny", "Tracy", "Joan", "The waiter"],
          correctAnswer: "Tracy",
        },
        {
          question: "4. What did the waiter spill on Benny's pants?",
          options: ["Water", "Wine", "Soup", "Juice"],
          correctAnswer: "Wine",
        },
        {
          question:
            "5. How long has Benny been a food critic for the Winnipeg Daily News?",
          options: ["5 years", "10 years", "15 years", "20 years"],
          correctAnswer: "10 years",
        },
        {
          question: "6. What was the restaurant's specialty?",
          options: ["Spaghetti", "Pizza", "Lasagna", "Risotto"],
          correctAnswer: "Lasagna",
        },
      ],
    },
    {
      instruction:
        "Here is a response to the message. Complete the response by filling in the blanks. Select the best choice for each blank from the drop-down",
      questionType: "Reading_to_Apply_Diagram",
      questions: [
        {
          question:
            "It sounds like you had a really ** experience at Le French.",
          options: ["terrible", "wonderful", "okay", "expensive"],
          correctAnswer: "terrible",
        },
        {
          question:
            "I understand why Benny would love to visit France again; both the French and Italian food here ** Le French.",
          options: [
            "is better than",
            "is worse than",
            "is similar to",
            "is different from",
          ],
          correctAnswer: "is different from",
        },
        {
          question:
            "Remember that Le French is a new restaurant, so maybe you should go there one more time before Benny **.",
          options: ["writes a review", "leaves", "complains", "returns"],
          correctAnswer: "writes a review",
        },
        {
          question:
            "I know that the ** was really bad, but it may be that this was not the regular waiter.",
          options: ["service", "food", "price", "ambiance"],
          correctAnswer: "service",
        },
        {
          question:
            "Having said that, I think we should **, when we meet next month.",
          options: [
            "try another restaurant",
            "complain",
            "go back",
            "order takeout",
          ],
          correctAnswer: "try another restaurant",
        },
      ],
    },
  ],
};

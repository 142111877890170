import React, { useEffect, useState } from "react";
import { Box, Container } from "@chakra-ui/react";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../redux/hooks/useTypedSelector";
import { WritingMockTestData } from "../../../redux/slice/mock-test/writingTestSlice";
import { useUser } from "../../../hooks/use-user";
import { decryptString } from "../../../utils/encryption";
import { useParams } from "react-router-dom";
import { sendMockTestResultAction } from "../../../redux/slice/mock-test/mockTestResultSlice";
import { useMockTestList } from "../../../hooks/use-mock-test-list";
import { addMissingCommas } from "../../../utils/scoring-format";
import { WritingResultAnswer } from "./WritingResultAnswer";

export const WritingTestResult: React.FC = () => {
  const user = useUser();
  const { data } = useAppSelector((state) => state.writingMockTest);
  const [questionOneScore, setQuestionOneScore] = useState<string>("");
  const [questionOneFeedback, setQuestionOneFeedback] = useState<string>("");
  const [questionOneSuggestions, setQuestionOneSuggestions] =
    useState<string>("");
  const [questionTwoScore, setQuestionTwoScore] = useState<string>("");
  const [questionTwoFeedback, setQuestionTwoFeedback] = useState<string>("");
  const [questionTwoSuggestions, setQuestionTwoSuggestions] =
    useState<string>("");
  const { testNum } = useParams();
  const dispatch = useAppDispatch();
  const { getNextStepHref } = useMockTestList();

  useEffect(() => {
    if (data) {
      try {
        const fixedData = addMissingCommas(data);

        const result = JSON.parse(fixedData) as WritingMockTestData;
        setQuestionOneScore(result?.questionOneScore);
        setQuestionOneFeedback(result?.questionOneFeedback);
        setQuestionOneSuggestions(result?.questionOneSuggestions);
        setQuestionTwoScore(result?.questionTwoScore);
        setQuestionTwoFeedback(result?.questionTwoFeedback);
        setQuestionTwoSuggestions(result?.questionTwoSuggestions);

        const saveResultData = async () => {
          const payLoad = {
            testID: "0",
            testNum: decryptString(testNum?.toString() ?? ""),
            userID: user.id.toString(),
            testType: "Writing",
            section: "",
            score: "0",
            overallScore: "0",
            feedback: JSON.stringify(result),
          };

          await dispatch(sendMockTestResultAction(payLoad));
        };
        saveResultData();
      } catch (error) {
        console.error("Error parsing JSON:", error);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  useEffect(() => {
    const isCompleteMockTest = localStorage.getItem("isCompleteMockTest");
    if (isCompleteMockTest === "true") {
      window.location.href = `${getNextStepHref("Writing")}/${testNum}`;
    }
  }, [getNextStepHref, testNum]);

  return (
    <Box minH="100vh" py={10}>
      <Container
        maxW="100%"
        bg="white"
        p={0}
        borderRadius="md"
        boxShadow="md"
        mt={9}
      >
        <WritingResultAnswer
          questionOneScore={questionOneScore}
          questionOneFeedback={questionOneFeedback}
          questionOneSuggestions={questionOneSuggestions}
          questionTwoScore={questionTwoScore}
          questionTwoFeedback={questionTwoFeedback}
          questionTwoSuggestions={questionTwoSuggestions}
        />
      </Container>
    </Box>
  );
};

import React, { useState, useEffect } from "react";
import { Box, HStack, Heading, Icon, Spinner } from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";
import { StaticSentence } from "./StaticSentence";
import {
  MockQuestion,
  QuestionData,
  QuestionType,
} from "../../../../types/reading-types";
import {
  ReadingForInformationInstruction,
  ReadingForViewpointsInstruction,
  defaultInstructionSection,
  instructionSectionImage,
  instructionSectionOne,
  instructionSectionTwo,
} from "../../../../utils/helperFuntions";
import { DynamicSentence } from "./DynamicSentence";
import { useAppSelector } from "../../../../redux/hooks/useTypedSelector";

type PracticeProps = {
  data: QuestionData;
};

const createOptionObject = (question: MockQuestion, index: number, type: string) => ({
  question: `${index}-${type}`,
  correctAnswer: question.correctAnswer,
  optionB: question.optionB,
  optionC: question.optionC,
  optionD: question.optionD,
  optionE: question.optionE,
});

const filterAndMapOptions = (optionObject: any) => {
  return Object.entries(optionObject)
    .filter(([, value]) => value !== undefined)
    .map(([key, value]) => ({ [key]: value as string }));
};

export const Practice: React.FC<PracticeProps> = ({ data }) => {
  const { user } = useAppSelector((state: { auth: any }) => state.auth);
  const questions = data.questions as MockQuestion[];
  const [options, setOptions] = useState<{ [key: string]: string }[][]>([]);

  useEffect(() => {
    const newOptions: { [key: string]: string }[][] = [];
    questions.forEach((question, index) => {
      let optionObject: any;

      if (data.questionsType === "ReadingCorrespondence" && index >= 6) {
        optionObject = createOptionObject(question, index, "ReadingCorrespondence");
      }
      if (data.questionsType === "ReadingToApplyADiagram" && index < 5) {
        optionObject = createOptionObject(question, index, "ReadingToApplyADiagram");
      }
      if (data.questionsType === "ReadingForViewpoints" && index >= 5) {
        optionObject = createOptionObject(question, index, "ReadingForViewpoints");
      }

      if (optionObject) {
        newOptions.push(filterAndMapOptions(optionObject));
      }
    });
    setOptions(newOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questions]);

  if (!options) {
    return <Spinner />;
  }

  return (
    <Box w="100%">
      <Box mb={9}>
        <HStack>
          <Icon as={InfoIcon} color="blue.500" />
          <Heading size="sm" color={"blue.600"}>
            {data.questionsType === "ReadingToApplyADiagram"
              ? instructionSectionImage
              : data.questionsType === "ReadingForInformation"
                ? ReadingForInformationInstruction
                : instructionSectionOne}
          </Heading>
        </HStack>

        {data.questionsType === "ReadingToApplyADiagram" ? (
          <Box mt={2} p={2}>
            {questions.length >= 6 &&
              data.sectionTwoQuestions?.includes("****") && (
                <DynamicSentence
                  userId={user.user.id.toString()} 
                  sectionTwoQuestions={data.sectionTwoQuestions}
                  options={options}
                  questionType={data.questionsType as QuestionType}              
                 />
              )}
          </Box>
        ) : (
          ""
        )}

        <Box borderRadius="sm" w="full" mt={2}>
          {questions.map((question, index) => (
            <React.Fragment key={index}>
              {/* PracticeQuestion And ReadingForInformation  Static Sentence */}
              {data.questionsType === "PracticeQuestion" ||
              data.questionsType === "ReadingForInformation" ? (
                <Box w="full">
                  <Box mt={2}>
                    <StaticSentence
                      userId={user.user.id.toString()} 
                      question={question}
                      isDisplayOptions={
                        index >= 6 && data.sectionTwoQuestions?.includes("****")
                          ? false
                          : true
                      }
                      questionType={data.questionsType as QuestionType}
                    />
                  </Box>
                </Box>
              ) : (
                ""
              )}

              {/* ReadingCorrespondence Static Sentence */}
              {data.questionsType === "ReadingCorrespondence" ? (
                <Box w="full">
                  <Box>
                    <StaticSentence
                      userId={user.user.id.toString()} 
                      question={question}
                      isDisplayOptions={
                        index >= 6 && data.sectionTwoQuestions?.includes("****")
                          ? false
                          : true
                      }
                      questionType={data.questionsType as QuestionType}
                    />
                  </Box>
                </Box>
              ) : (
                ""
              )}

              {/* ReadingForViewpoints Static Sentence */}
              {data.questionsType === "ReadingForViewpoints" ? (
                <Box w="full">
                  <Box>
                    <StaticSentence
                      userId={user.user.id.toString()} 
                      question={question}
                      isDisplayOptions={
                        index >= 5 && data.sectionTwoQuestions?.includes("****")
                          ? false
                          : true
                      }
                      questionType={data.questionsType as QuestionType}
                    />
                  </Box>
                </Box>
              ) : (
                ""
              )}
              {/* ReadingToApplyADiagram Static Sentence */}
              {index >= 5 && data.questionsType === "ReadingToApplyADiagram" ? (
                <Box w="full">
                  <Heading size="sm" color={"blue.600"}>
                    {index === 5 ? defaultInstructionSection : ""}
                  </Heading>
                  <Box mt={5}>
                    <StaticSentence
                      userId={user.user.id.toString()} 
                      question={question}
                      questionType={data.questionsType as QuestionType}
                    />
                  </Box>
                </Box>
              ) : (
                ""
              )}
            </React.Fragment>
          ))}

          {/* ReadingCorrespondence Dynamic Sentence */}
          {data.questionsType === "ReadingCorrespondence" ? (
            <Box mt={-100}>
              <HStack>
                <Icon as={InfoIcon} color="blue.500" />
                <Heading size="sm" color="blue.600">
                  {instructionSectionTwo}
                </Heading>
              </HStack>
              <Box mt={9}>
              {questions.length >= 6 &&
                data.sectionTwoQuestions?.includes("**") && (
                  <DynamicSentence
                    userId={user.user.id.toString()} 
                    sectionTwoQuestions={data.sectionTwoQuestions}
                    options={options}
                    questionType={data.questionsType as QuestionType}
                  />
                )}
              </Box>
            </Box>
          ) : (
            ""
          )}

          {/* ReadingForViewpoints Dynamic Sentence */}
          {data.questionsType === "ReadingForViewpoints" ? (
            <Box mt={-109}>
              <HStack>
                <Icon as={InfoIcon} color="blue.500" />
                <Heading size="sm" color="blue.600">
                  {ReadingForViewpointsInstruction}
                </Heading>
              </HStack>
              <Box pt={7}>
                {questions.length >= 5 &&
                  data.sectionTwoQuestions?.includes("****") && (
                    <DynamicSentence
                      userId={user.user.id.toString()} 
                      sectionTwoQuestions={data.sectionTwoQuestions}
                      options={options}
                    />
                  )}
              </Box>
            </Box>
          ) : (
            ""
          )}
        </Box>
      </Box>
    </Box>
  );
};
import * as yup from "yup";


export type Question = {
  question: string;
  options: string[];
  correctAnswer: string;
};

export type QuestionSection = {
  instruction: string;
  questionType: "Practice" | "Reading_Correspondence" |  "Reading_to_Apply_Diagram" | "Reading_for_Information" | "Reading_for_Viewpoints";
  questions: Question[];
};

export type DataItem = {
  id: number;
  header: string;
  instruction: string;
  photo?: string;
  questionData: QuestionSection[];
};

export const userAnswerSchema = yup.object().shape({
  question: yup.string().optional(),
  correctAnswer: yup.string().optional(),
  userAnswer: yup.string().required("Answer is required"),

});
export type UserAnswerSchema = yup.InferType<typeof userAnswerSchema>;

import { useUser } from "../../hooks/use-user";
import { TeacherPackagePlan } from "./TeacherPackagePlan";
import { StudentPackagePlan } from "./student-plan/StudentPackagePlan";

export const PackagePlan = () => {
  const user  = useUser();
  const accountType = user.accountType

  return (
    <>
      {accountType === "teacher" ? (
        <TeacherPackagePlan />
      ) : accountType === "student" ? (
        <StudentPackagePlan />
      ) : (
        ""
      )}
    </>
  );
};

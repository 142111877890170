import {
  Box,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
  Spinner,
  useToast,
  Stack,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useUser } from "../../hooks/use-user";
import { useAppDispatch, useAppSelector } from "../../redux/hooks/useTypedSelector";
import { updatePasswordAction } from "../../redux/slice/auth/authSlice";

const passwordSchema = yup.object().shape({
  oldPassword: yup.string().required("Old Password is required"),
  newPassword: yup
    .string()
    .required("New Password is required")
    .min(8, "Password must be at least 8 characters long"),
});

export const UpdatePassword = () => {
 const { isSuccess, isError, errorMessage } = useAppSelector(
        (state) => state.auth,
      );
  const user = useUser();
  const dispatch = useAppDispatch();
  const toast = useToast();

  const {
    handleSubmit,
    register,
    formState: { isSubmitting, errors },
  } = useForm({
    resolver: yupResolver(passwordSchema),
  });

  const onSubmitHandler = async (data: any) => {
    try {
      const payload = {
        ...data,
        id: user?.id,
        email: user?.email,
      };

      await dispatch(updatePasswordAction(payload));

      if(!isError && isSuccess){
        toast({
            title: "Password Updated.",
            description: "Your password has been updated successfully.",
            status: "success",
            position: "top-right",
            duration: 9000,
            isClosable: true,
          });
          setInterval(() => {
            window.location.href = "/student/settings";
          }, 1000);
          
      }else{
        toast({
            title: "Error",
            description: errorMessage + "Internal Server Error: Invalid Old Password",
            status: "error",
            position: "top-right",
            duration: 9000,
            isClosable: true,
          });
      }
      
    } catch (error: any) {
      console.log(error.message);
      toast({
        title: "Error",
        description: "An error occurred while updating your password.",
        status: "error",
        position: "top-right",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  return (
    <Box
      mt={0}
      alignItems={"center"}
      textAlign={"center"}
      bg={"#FFF"}
      boxShadow={"lg"}
      borderRadius={5}
      p={3}
    >
      <Heading fontSize="22px" textAlign={"center"}>
        Update Password
      </Heading>
      <Box as="form" onSubmit={handleSubmit(onSubmitHandler)}>
        <Stack spacing={4}>
          <FormControl isInvalid={!!errors.oldPassword}>
            <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
              Old Password
            </FormLabel>
            <Input
              type="password"
              mb="24px"
              fontSize="sm"
              placeholder="Old Password"
              size="lg"
              {...register("oldPassword")}
            />
            <p style={{ color: "red" }}>{errors.oldPassword?.message}</p>
          </FormControl>
          <FormControl isInvalid={!!errors.newPassword}>
            <FormLabel ms="4px" fontSize="sm" fontWeight="normal">
              New Password
            </FormLabel>
            <Input
              type="password"
              mb="24px"
              fontSize="sm"
              placeholder="New Password"
              size="lg"
              {...register("newPassword")}
            />
            <p style={{ color: "red" }}>{errors.newPassword?.message}</p>
          </FormControl>
          <Button
            fontFamily={"heading"}
            mt={8}
            w={"full"}
            bgGradient="linear(to-r, #319795,#319795)"
            color={"white"}
            _hover={{
              bgGradient: "linear(to-r, #5fc4b5,#5fc4b5)",
              boxShadow: "xl",
            }}
            type="submit"
          >
            {isSubmitting ? <Spinner /> : "Submit"}
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};

import { ComponentStyleConfig } from "@chakra-ui/react";

export const ReiHeading: ComponentStyleConfig = {
  baseStyle: {
    // fontWeight: "md",
    // paddingBottom: "2rem",
    fontSize: 32,
    color:"#001a62"
  },
};

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import readingTestService from "../../service/mockTestService";

export type CreateMockTestState = {
  data: any;
  isError: boolean;
  isSuccess: boolean;
  isLoading: boolean;
  errorMessage: string;
};

export const initialState: CreateMockTestState = {
  data: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  errorMessage: "",
};
export type QType =
  | "Listening"
  | "Reading"
  | "Writing"
  | "Speaking";

export type CreateMockTest = {
    user: number;
    type: QType, 
    testNum: string;
    questionType: string;
    data: string;
}


export const handleRejected = (state: CreateMockTestState, action: any) => {
  state.isLoading = false;
  state.isError = true;
  state.errorMessage = action.payload as string;
};

export const createQuestionAction = createAsyncThunk(
  "create-reading-test",
  async (payload: CreateMockTest, thunkAPI) => {
    try {
      return await readingTestService.createTestQuestion(payload, thunkAPI);
    } catch (error: any) {
      let message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      if (error.response?.status === 400) {
        message = error.response.data.data;
      }
      return thunkAPI.rejectWithValue(message);
    }
  },
);
export const updateQuestionAction = createAsyncThunk(
  "update-reading-test",
  async (payload: CreateMockTest, thunkAPI) => {
    try {
      return await readingTestService.updateTestQuestion(payload, thunkAPI);
    } catch (error: any) {
      let message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();

      if (error.response?.status === 400) {
        message = error.response.data.data;
      }
      return thunkAPI.rejectWithValue(message);
    }
  },
);
export const getQuestionsAction = createAsyncThunk('auth/getQuestions', async (type: QType,thunkAPI) => {
  try {
    return await readingTestService.getQuestions(type, thunkAPI);
  } catch (error: any) {
    let message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

    return message;
  }
});

export const questionBankSlice = createSlice({
  name: "questionBank",
  initialState,
  reducers: {
    CreateMockTestTest: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(createQuestionAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createQuestionAction.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.isError = false
        state.data = action?.payload?.data;
      })
      .addCase(createQuestionAction.rejected, (state, action) => {
        handleRejected(state, action);
      })
      .addCase(updateQuestionAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateQuestionAction.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.isError = false
        state.data = action?.payload?.data;
      })
      .addCase(updateQuestionAction.rejected, (state, action) => {
        handleRejected(state, action);
      })
      .addCase(getQuestionsAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getQuestionsAction.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.isError = false
        state.data = action?.payload?.data;
      })
      .addCase(getQuestionsAction.rejected, (state, action) => {
        handleRejected(state, action);
      })
  },
});

export const { CreateMockTestTest } = questionBankSlice.actions;
export default questionBankSlice.reducer;
import React from "react";
import {
  Box,
  Container,
  Heading,
  VStack,
  HStack,
  Icon,
  Button,
  Flex,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";
import { useNavigate, useParams } from "react-router-dom";

export const WritingTestInstructions: React.FC = () => {
  const { testNum } = useParams();
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate(-1);
  };


  return (
    <Box minH="100vh" py={10}>
      <Container
        maxW="100%"
        bg="white"
        p={8}
        borderRadius="md"
        boxShadow="md"
        mt={90}
      >
        <Flex direction="row" justify="space-between" align="center">
          <Heading size="md" mb={6}>
            Mock Test - Writing
          </Heading>
          <Button as={"a"} href={`/writing-test/${testNum}`} colorScheme="blue" h={7}>
            NEXT
          </Button>
        </Flex>
        <VStack align="start" spacing={5}>
          <HStack>
            <Icon as={InfoIcon} color="blue.500" />
            <Heading size="sm">Writing Test Instructions</Heading>
          </HStack>
          <VStack align="start" spacing={3} color={"blue.600"}>
            <UnorderedList spacing={3}>
              <ListItem>
                On the official test, if you do not finish Task 1 in 27 minutes,
                the screen will move to Task 2. You cannot go back to Task 1.
                However, in this practice test, in order to move forward in the
                test you must click on "NEXT."
              </ListItem>
              <ListItem>
                You have 53 minutes to complete this practice Writing Test.
              </ListItem>
              <ListItem>
                Watch the timer in the top right corner to make sure that you
                complete the Reading Test before the time is up.
              </ListItem>
            </UnorderedList>
          </VStack>
        </VStack>
        <HStack justify="space-between" mt={10}>
          <Button colorScheme="red" h={7} onClick={handleBackClick}>
            BACK
          </Button>
        </HStack>
      </Container>
    </Box>
  );
};

import {
  Flex,
  Heading,
  Spacer,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { AddNewStudentModal } from "./edit/AddNewStudentModal";
import { Student } from "../../redux/slice/student/studentSlice";
import { RemoveStudentModal } from "./edit/RemoveStudentModal";
import { useStudents } from "../../hooks/use-students";

export const Students = () => {
const {isLoading, students} = useStudents("teacher");
 

  if (isLoading) {
    return <Spinner />;
  }

  const sortedStudents = [...(students || [])].sort((a, b) => b.id - a.id);

  return (
    <Stack>
      <Flex>
        <Heading>Students</Heading>
        <Spacer />
        <AddNewStudentModal />
      </Flex>

      <TableContainer>
        <Table variant="simple" bg={"#FFF"} boxShadow={"2xl"} borderRadius={"md"}>
          <Thead bg={"gray.50"}>
            <Tr>
              <Th>ID</Th>
              <Th>First Name</Th>
              <Th>Last Name</Th>
              <Th>Email</Th>
              <Th>Created At</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
          {sortedStudents &&
                sortedStudents.map((student: Student, index: any) => (
                  <Tr key={index}>
                    <Td>ST-{student.id}</Td>
                    <Td>{student.firstName}</Td>
                    <Td>{student.lastName}</Td>
                    <Td>{student.email}</Td>
                    <Td>{new Date(student.created_at).toLocaleDateString()}</Td>
                    <Td>
                      <RemoveStudentModal student={student} />
                    </Td>
                  </Tr>
                ))}
          </Tbody>
        </Table>
      </TableContainer>
    </Stack>
  );
};
import axios from "axios";
import {
  SignUp,
  RecoverPassword,
  ResetPassword,
  ContactUs,
  SignIn as ISignIn,
} from "../../apps/auth/schema";
import { config } from "../../config";

const API_URL = config.baseUrl.default;

export const getTokenAndConfig = (thunkAPI: any) => {
  return {
    headers: {
      Authorization: `Bearer ${thunkAPI.getState().auth.user.token}`,
    },
  };
};

const signUp = async (data: SignUp) => {
  const response = await axios.post(API_URL + "signup", data);
  return response.data;
};
const signIn = async (data: ISignIn) => {
  const response = await axios.post(API_URL + "login", data);
  return response.data;
};
const verifyEmail = async (token: string) => {
  const response = await axios.post(API_URL + `verify-email/${token}`);
  return response.data;
};
const updateAccount = async (payload: SignUp, thunkAPI: any) => {
  const response = await axios.put(API_URL + "update-user", payload,  getTokenAndConfig(thunkAPI));
  return response.data;
};
const updatePassword = async (payload: SignUp, thunkAPI: any) => {
  const response = await axios.put(API_URL + "update-password", payload,  getTokenAndConfig(thunkAPI));
  return response.data;
};
const recoverPassword = async (email: RecoverPassword) => {
  const response = await axios.post(API_URL + "send-reset-password", email);
  return response.data;
};
const resetPassword = async (payload: ResetPassword) => {
  const response = await axios.post(API_URL + "reset-password", payload);
  return response.data;
};
const contactUs = async (payload: ContactUs) => {
  const response = await axios.post(API_URL + "contact-us", payload);
  return response.data;
};
const authService = {
  signUp,
  signIn,
  updateAccount,
  updatePassword,
  verifyEmail,
  recoverPassword,
  resetPassword,
  contactUs,
};

export default authService;

import {
  Flex,
  Box,
  SimpleGrid,
  Heading,
  Spinner,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Button,
  useToast,
} from "@chakra-ui/react";
import { useAppDispatch } from "../../redux/hooks/useTypedSelector";
import { useForm } from "react-hook-form";
import { updateAccountAction } from "../../redux/slice/auth/authSlice";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../hooks/use-user";
import { UpdatePassword } from "./UpdatePassword";

export const StudentSettings = () => {
  const user = useUser();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const toast = useToast();

  const {
    handleSubmit,
    register,
    formState: { isSubmitting, errors },
  } = useForm({
    defaultValues: {
      id: user?.id,
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
    },
  });

  const onSubmitHandler = async (data: any) => {
    try {
      await dispatch(updateAccountAction(data));
      toast({
        title: "Account Updated.",
        description: "Account Updated",
        status: "success",
        position: "top-right",
        duration: 9000,
        isClosable: true,
      });
      navigate(`/student/settings`);
    } catch (error: any) {
      console.log(error.message);
    }
  };

  return (
    <Flex minH={"100vh"} pt={0} direction="column">
      <Box borderRadius="15px" px="0px" display="flex">
        <Heading fontSize="32px" mb={5} textAlign={"center"}>
          Account Settings
        </Heading>
      </Box>

      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={3}>
        <Box
          as={"form"}
          mt={0}
          bg={"#FFF"}
          boxShadow={"lg"}
          borderRadius={5}
          p={5}
          onSubmit={handleSubmit(onSubmitHandler)}
        >
          <Stack spacing={4}>
            <FormControl isRequired>
              <FormLabel>First Name</FormLabel>
              <Input
                {...register("firstName")}
                placeholder="First Name"
                isInvalid={!!errors.firstName}
                required
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Last Name</FormLabel>
              <Input
                {...register("lastName")}
                placeholder="Last Name"
                isInvalid={!!errors.lastName}
                required
              />
            </FormControl>
            <FormControl isRequired>
              <FormLabel>Email</FormLabel>
              <Input
                {...register("email")}
                placeholder="Email"
                isInvalid={!!errors.email}
                required
                readOnly
              />
            </FormControl>
          </Stack>
          <Button
            fontFamily={"heading"}
            mt={8}
            w={"full"}
            bgGradient="linear(to-r, #319795,#319795)"
            color={"white"}
            _hover={{
              bgGradient: "linear(to-r, #5fc4b5,#5fc4b5)",
              boxShadow: "xl",
            }}
            type="submit"
          >
            {isSubmitting ? <Spinner /> : "Submit"}
          </Button>
        </Box>
        <UpdatePassword />
      </SimpleGrid>
    </Flex>
  );
};

import * as yup from 'yup';

export const SpeakingTestValidationSchema = yup.object().shape({
  questionPracticeTaskTime: yup.string().optional(),
  questionSpeakingTaskOneTime: yup.string().optional(),
  questionSpeakingTaskTwoTime: yup.string().optional(),
  questionSpeakingTaskThreeTime: yup.string().optional(),
  questionSpeakingTaskFourTime: yup.string().optional(),
  questionSpeakingTaskFiveTime: yup.string().optional(),
  questionSpeakingTaskSixTime: yup.string().optional(),
  questionSpeakingTaskSevenTime: yup.string().optional(),
  questionSpeakingTaskEightTime: yup.string().optional(),

  questionsType: yup.string().optional(),
  questionPracticeTask: yup.string().optional(),

  questionSpeakingTaskOne: yup.string().optional(),
  questionSpeakingTaskTwo: yup.string().optional(),
  questionSpeakingTaskThree: yup.string().optional(),
  questionSpeakingTaskThreePhoto: yup.string().optional(),

  questionSpeakingTaskFour: yup.string().optional(),
  questionSpeakingTaskFourPhoto: yup.string().optional(),

  questionSpeakingTaskFive: yup.string().optional(),
  questionSpeakingTaskFiveTwo: yup.string().optional(),
  questionSpeakingTaskFiveInstruction: yup.string().optional(),
  questionSpeakingTaskFivePhotoOne: yup.string().optional(),
  questionSpeakingTaskFivePhotoTwo: yup.string().optional(),
  questionSpeakingTaskFivePhotoThree: yup.string().optional(),

  questionSpeakingTaskSix: yup.string().optional(),
  questionSpeakingTaskSixInstructionOne: yup.string().optional(),

  questionSpeakingTaskSeven: yup.string().optional(),
  questionSpeakingTaskSevenInstruction: yup.string().optional(),

  questionSpeakingTaskEight: yup.string().optional(),
  questionSpeakingTaskEightPhoto: yup.string().optional(),
});

export type SpeakingTestFormData = yup.InferType<typeof SpeakingTestValidationSchema>;

import { useUser } from "../../hooks/use-user";
import { AdminDashboard } from "./AdminDashboard";
import { StudentDashboard } from "./StudentDashboard";
import { TeacherDashboard } from "./TeacherDashboard";

export const Dashboard = () => {
  const user  = useUser();
  const accountType = user?.accountType

  return (
    <>
      {accountType === "admin" ? (
        <AdminDashboard />
      ) : accountType === "teacher" ? (
        <TeacherDashboard />
      ) : accountType === "student" ? (
        <StudentDashboard />
      ) : (
        ""
      )}
    </>
  );
};

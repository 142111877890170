export type SpeakingQuestionData = {
  questionPracticeTaskTime: string;
  questionSpeakingTaskOneTime: string;
  questionSpeakingTaskTwoTime: string;
  questionSpeakingTaskThreeTime: string;
  questionSpeakingTaskFourTime: string;
  questionSpeakingTaskFiveTime: string;
  questionSpeakingTaskSixTime: string;
  questionSpeakingTaskSevenTime: string;
  questionSpeakingTaskEightTime: string;
  questionsType: string;
  questionPracticeTask: string;
  questionSpeakingTaskOne: string;
  questionSpeakingTaskTwo: string;
  questionSpeakingTaskThree: string;
  questionSpeakingTaskThreePhoto: string;
  questionSpeakingTaskFour: string;
  questionSpeakingTaskFourPhoto: string;
  questionSpeakingTaskFive: string;
  questionSpeakingTaskFiveTwo: string;
  questionSpeakingTaskFiveInstruction: string;
  questionSpeakingTaskFivePhotoOne: string;
  questionSpeakingTaskFivePhotoTwo: string;
  questionSpeakingTaskFivePhotoThree: string;
  questionSpeakingTaskSix: string;
  questionSpeakingTaskSixInstructionOne: string;
  questionSpeakingTaskSeven: string;
  questionSpeakingTaskSevenInstruction: string;
  questionSpeakingTaskEight: string;
  questionSpeakingTaskEightPhoto: string;
};
export type SpeakingQuestion = {
  id: number;
  user: number;
  type: string;
  testNum: string;
  questionType: string;
  data: string;
  createAt: string;
  UpdateAt: string;
};

export const SpeakingSectionName = [
  "Practice",
  "Giving Advice",
  "Talking about a Personal Experience",
  "Describing a Scene",
  "Making Predictions",
  "Comparing and Persuading",
  "Dealing with a Difficult Situation",
  "Expressing Opinions",
  "Describing an Unusual Situation",
];

export type FeedbackItem = {
  questionNumber: string;
  score: number;
  feedback: string;
  suggestions: string;
}

export type Feedback = {
  allScore: FeedbackItem[];
  overallScore: number;
  overallFeedback: string;
  overallSuggestions: string;
}

export type UserSpeakingFeedback = {
  id: number;
  testID: number;
  testNum: number;
  userID: number;
  testType: string;
  section: string;
  score: number;
  overallScore: number;
  feedback: string;
  created_at: string;
  updated_at: string;
}

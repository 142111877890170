import * as yup from 'yup';

export const AddNewStudentSchema = yup.object().shape({
  firstName: yup.string().required('First Name is required'),
  lastName: yup.string().required('Last Name is required'),
  email: yup.string().required('Email is required'),
  password: yup.string().required('Password  is required'),
  accountType: yup.string().required('Account Type is required'),
  isTeacherCreatingUser: yup.boolean().required('Is Teacher Creating User is required'),
  teacherId: yup.string().required('Teacher Id Type is required'),
});

export type AddNewStudentFormData = yup.InferType<typeof AddNewStudentSchema>;

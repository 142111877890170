import { useState, useEffect } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../redux/hooks/useTypedSelector";
import { Student, getAllStudentsAction, getStudentAction } from "../redux/slice/student/studentSlice";
import { useUser } from "./use-user";

export const useStudents = (type: "all" | "teacher") => {
  const user = useUser();
  const { isLoading } = useAppSelector((state) => state.students);
  const [students, setStudents] = useState<Student[]>([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchStudents = async () => {
    
      if(type === "all"){
        const res = await dispatch(
          getAllStudentsAction(""),
        );
        setStudents(res.payload.data);
      }
      if(type === "teacher"){
        const res = await dispatch(
          getStudentAction({ teacherId: user.id.toString() }),
        );
        setStudents(res.payload.data);
      }
     
    };
    fetchStudents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch,type]);

  return {
    isLoading,
    students
  }
};

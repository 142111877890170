import { Navigate, Route } from "react-router-dom";
import { ListeningQuestionBank } from "../apps/question-bank/listening";
import { CreateListeningTestForm } from "../apps/question-bank/listening/edit/CreateListeningTestForm";
import { ReadingQuestionBank } from "../apps/question-bank/reading";
import { CreateReadingTestForm } from "../apps/question-bank/reading/edit/CreateReadingTestForm";
import { SpeakingQuestionBank } from "../apps/question-bank/speaking";
import { CreateSpeakingTestForm } from "../apps/question-bank/speaking/edit/CreateSpeakingTestForm";
import { WritingQuestionBank } from "../apps/question-bank/writing";
import { CreateWritingTestForm } from "../apps/question-bank/writing/edit/CreateWritingTestForm";
import { useAppSelector } from "../redux/hooks/useTypedSelector";

const AdminRoute = ({ children }: { children: JSX.Element }) => {
  const { user } = useAppSelector((state: { auth: any }) => state.auth);

  // If the user is not an admin, redirect them to the dashboard
  if (user?.user?.accountType !== "admin") {
    return <Navigate to="/dashboard" replace />;
  }

  // If the user is an admin, render the children (protected routes)
  return children;
};

// eslint-disable-next-line import/no-anonymous-default-export
export default [
  <>
    {/* Question Bank */}
   
    <Route
        path="/admin-reading"
        element={
          <AdminRoute>
            <ReadingQuestionBank />
          </AdminRoute>
        }
      />
       <Route
        path="/add-reading-question/:questionType/:testNum"
        element={
          <AdminRoute>
            <CreateReadingTestForm />
          </AdminRoute>
        }
      />
       <Route
        path="/edit-reading-question/:questionType/:id"
        element={
          <AdminRoute>
            <CreateReadingTestForm />
          </AdminRoute>
        }
      />
      <Route
        path="/admin-writing"
        element={
          <AdminRoute>
            <WritingQuestionBank />
          </AdminRoute>
        }
      />
      <Route
        path="/add-writing-question/:testNum"
        element={
          <AdminRoute>
            <CreateWritingTestForm />
          </AdminRoute>
        }
      />
       <Route
        path="/edit-writing-question/:id"
        element={
          <AdminRoute>
            <CreateWritingTestForm />
          </AdminRoute>
        }
      />
      <Route
        path="/admin-speaking"
        element={
          <AdminRoute>
            <SpeakingQuestionBank />
          </AdminRoute>
        }
      />
      <Route
        path="/add-speaking-question/:testNum"
        element={
          <AdminRoute>
            <CreateSpeakingTestForm />
          </AdminRoute>
        }
      />
       <Route
        path="/edit-speaking-question/:id"
        element={
          <AdminRoute>
            <CreateSpeakingTestForm />
          </AdminRoute>
        }
      />
      <Route
        path="/admin-listening"
        element={
          <AdminRoute>
            <ListeningQuestionBank />
          </AdminRoute>
        }
      />
      <Route
        path="/add-listening-question/:questionType/:testNum"
        element={
          <AdminRoute>
            <CreateListeningTestForm />
          </AdminRoute>
        }
      />
      <Route
        path="/edit-listening-question/:questionType/:id"
        element={
          <AdminRoute>
            <CreateListeningTestForm />
          </AdminRoute>
        }
      />

    {/* <Route
      path="/add-reading-question/:questionType/:testNum"
      element={<CreateReadingTestForm />}
    />
    <Route path="/admin-writing" element={<WritingQuestionBank />} />
    <Route path="/add-writing-question/:testNum" element={<CreateWritingTestForm />} />
    <Route path="/admin-speaking" element={<SpeakingQuestionBank />} />
    <Route path="/add-speaking-question/:testNum" element={<CreateSpeakingTestForm />} />
    <Route path="/admin-listening" element={<ListeningQuestionBank />} />
    <Route
      path="/add-listening-question/:questionType/:testNum"
      element={<CreateListeningTestForm />}
    /> */}
  </>,
];

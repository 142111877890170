import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import readingTestService from "../../service/mockTestService";

export type CreateMockTestState = {
  data: any;
  isError: boolean;
  isSuccess: boolean;
  isLoading: boolean;
  errorMessage: string;
};

export const initialState: CreateMockTestState = {
  data: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  errorMessage: "",
};


export const handleRejected = (state: CreateMockTestState, action: any) => {
  state.isLoading = false;
  state.isError = true;
  state.errorMessage = action.payload as string;
};


export const getMockTestQuestionsAction = createAsyncThunk('auth/get-mock-tests', async (userId: number, thunkAPI) => {
    try {
      return await readingTestService.getAllMockTests(userId,thunkAPI);
    } catch (error: any) {
      let message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
  
      return message;
    }
  });

export const mockTestViewSlice = createSlice({
  name: "mockTestView",
  initialState,
  reducers: {
    ViewMockTestTest: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMockTestQuestionsAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getMockTestQuestionsAction.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.isError = false
        state.data = action?.payload?.data;
      })
      .addCase(getMockTestQuestionsAction.rejected, (state, action) => {
        handleRejected(state, action);
      });
  },
});

export const { ViewMockTestTest } = mockTestViewSlice.actions;
export default mockTestViewSlice.reducer;
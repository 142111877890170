import { useCallback } from "react";

type MockTest = {
  id: number;
  step: string;
  href: string;
};

export const useMockTestList = () => {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const mockTestList: MockTest[] = [
    {
      id: 0,
      step: "Start",
      href: `#`,
    },
    {
      id: 1,
      step: "Listening",
      href: `/listening-test-instructions`,
    },
    {
      id: 2,
      step: "Reading",
      href: `/reading-test-instructions`,
    },
    {
      id: 3,
      step: "Writing",
      href: `/writing-test-instructions`,
    },
    {
      id: 4,
      step: "Speaking",
      href: `/speaking-test-instructions`,
    },
  ];

  const getNextStepHref = useCallback(
    (currentStep: string): string | null => {
      const currentStepIndex = mockTestList.findIndex(
        (test) => test.step === currentStep,
      );
      if (
        currentStepIndex !== -1 &&
        currentStepIndex < mockTestList.length - 1
      ) {
        return mockTestList[currentStepIndex + 1].href;
      }
      return null;
    },
    [mockTestList],
  );

  return { getNextStepHref };
};

import { useAppSelector } from "../redux/hooks/useTypedSelector";
import { AuthUser } from "../redux/slice/auth/authSlice";


export const useUser = () => {
    const { user } = useAppSelector((state: { auth: any; }) => state.auth);
    const authenticatedUser = user?.user[0] ?? user?.user;

    return authenticatedUser as AuthUser;
};


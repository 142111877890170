import { useState, useEffect } from "react";
import {
  Box,
  Spinner,
  Card,
  CardBody,
  CardHeader,
  Heading,
  SimpleGrid,
  Button,
  Center,
  Container,
  useDisclosure,
  AlertIcon,
  Alert,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import {
  useAppSelector,
  useAppDispatch,
} from "../../redux/hooks/useTypedSelector";
import { getMockTestQuestionsAction } from "../../redux/slice/mock-test/mockTestsViewSlice";
import { WritingQuestion } from "../../types/writing-types";
import { encryptString } from "../../utils/encryption";
import { useUser } from "../../hooks/use-user";
import { isDateExpired } from "../../utils/helperFuntions";
import { CompleteTestButton } from "../../components/ CompleteTestButton";

export const MockTest = () => {
  const user = useUser();
  const { isLoading } = useAppSelector((state) => state.mockTestView);
  const [questions, setQuestions] = useState<WritingQuestion[]>([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const res = await dispatch(getMockTestQuestionsAction(user.id));
      setQuestions(res.payload);
    };
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const getHighestTestNum = (questions: WritingQuestion[]) => {
    return Math.max(...questions?.map((q) => Number(q.testNum)));
  };

  const handleExpModal = () => {
    if (isDateExpired(user?.exp_date)) {
      onOpen();
      return;
    }
    // Proceed with normal behavior if not expired
  };

  const highestTestNum = getHighestTestNum(questions);
  const renderTestButtons = (testNum: number) => {
    const testTypes = ["Listening", "Reading", "Writing", "Speaking"];

    const clearStorage = () => {
      localStorage.removeItem("isCompleteMockTest");
      if (isDateExpired(user?.exp_date) && testNum !== 1) {
        handleExpModal();
      }
    };

    return testTypes.map((type) => {
      const testExists = questions.some(
        (q) => Number(q.testNum) === testNum && q.type === type,
      );
      const encryptedTestNum = encryptString(testNum.toString());
      if (!testExists)
        return (
          <Button
            key={`${type}-${testNum}`}
            as={"a"}
            href={`#`}
            colorScheme="red"
            variant="outline"
            p={7}
            w={{ base: "100%", md: "auto" }}
            fontSize={"12px"}
          >
            {`${type} Not Set`}
          </Button>
        );
      return (
        <Button
          key={`${type}-${testNum}`}
          as={"a"}
          href={
            isDateExpired(user?.exp_date) && testNum !== 1
              ? "#"
              : `/${type.toLowerCase()}-test-instructions/${encryptedTestNum}`
          }
          color="teal"
          variant="outline"
          p={7}
          w={{ base: "100%", md: "auto" }}
          bg={
            type === "Listening"
              ? "pink.50"
              : type === "Reading"
                ? "blue.50"
                : type === "Writing"
                  ? "green.50"
                  : type === "Speaking"
                    ? "teal.50"
                    : "white"
          }
          onClick={clearStorage}
        >
          {`${type} Test`}
        </Button>
      );
    });
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <Box minH="100vh" py={2}>
      <Container
        maxW="container.2xl"
        bg="white"
        p={{ base: 4, md: 8 }}
        borderRadius="md"
        boxShadow="md"
      >
        <Heading fontSize={{ base: "24px", md: "29px" }} color="blue.700">
          Start Mock Test
        </Heading>
        {isDateExpired(user?.exp_date) && (
          <>
            <Box mt={4} as="a" href="/package-plan">
              <Alert status="warning" mt={4} borderRadius={9}>
                <AlertIcon />
                Your subscription has expired. You can only practice using one
                free mock test. Please upgrade your plan to practice all the
                mock tests.
              </Alert>
            </Box>
          </>
        )}
        <Center mt={9} p={2}>
          <SimpleGrid columns={{ base: 1, lg: 2, md: 1 }} spacing={8}>
            {Array.from({ length: highestTestNum }, (_, i) => (
              <Card key={i} boxShadow="2xl" w={{ base: "100%", md: "auto" }}>
                <CardHeader>
                  <Heading size="md" color="blue.700">
                    Practice Test {i + 1}
                  </Heading>
                </CardHeader>
                <CardBody>
                  <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8}>
                    {renderTestButtons(i + 1)}
                  </SimpleGrid>
                  <Box w={"100%"} mt={4}>
                    {(isDateExpired(user?.exp_date) && i + 1 === 1) ||
                    !isDateExpired(user?.exp_date) ? (
                      <CompleteTestButton
                        key={`complete-${i + 1}`}
                        testNum={i + 1}
                        questions={questions}
                      />
                    ) : (
                      isDateExpired(user?.exp_date) &&
                      i + 1 !== 1 && (
                        <Button
                          as="a"
                          href="/package-plan"
                          bg={"blue.600"}
                          width={"100%"}
                          _hover={{ bg: "blue.900" }}
                        >
                          Upgrade Now
                        </Button>
                      )
                    )}
                  </Box>
                </CardBody>
              </Card>
            ))}
          </SimpleGrid>
        </Center>
      </Container>

      {/* Upgrade Plan Modal */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Upgrade Your Plan</ModalHeader>
          <ModalBody>
            <Alert status="warning" mt={4}>
              <AlertIcon />
              Your subscription has expired. To access this feature, please
              upgrade your plan.
            </Alert>
          </ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={onClose} bg={"gray.300"} color={"gray.700"}>
              Cancel
            </Button>
            <Button colorScheme="green" as="a" href="/package-plan">
              Upgrade Now
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

import * as yup from 'yup';


export const WritingTestValidationSchema = yup.object().shape({
  questionOneTime: yup.string().required("Time is required"),
  questionTwoTime: yup.string().required("Time is required"),
  questionsType: yup.string().required("questionsType is required"),
  questionOne: yup.string().required("Question is required"),
  questionOneInstructionOne: yup.string().required("Instruction is required"),
  questionOneInstructionTwo: yup.string().required("Instruction is required"),
  questionTwo: yup.string().required("Question is required"),
  questionTwoInstruction: yup.string().required("Instruction is required"),
  questionTwoOptionA: yup.string().required("Option is required"),
  questionTwoOptionB: yup.string().required("Option is required"),
  testNum: yup.string().optional(),
});

export type WritingTestFormData = yup.InferType<typeof WritingTestValidationSchema>;

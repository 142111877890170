import { useState, useEffect } from "react";
import {
  Box,
  Heading,
  FormControl,
  FormLabel,
  Input,
  Button,
  HStack,
  VStack,
  Container,
  Flex,
  SimpleGrid,
  FormErrorMessage,
  useToast,
  Select,
  Radio,
  RadioGroup,
  Stack,
} from "@chakra-ui/react";
import { yupResolver } from "@hookform/resolvers/yup";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { AudioAndVideoUploader } from "../../../../components/AudioAndVideoUploader";
import { ImageUploader } from "../../../../components/ImageUpload";
import ReactQuill from "react-quill";
import {
  ListeningTestFormData,
  ListeningTestValidationSchema,
} from "../schema";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../redux/hooks/useTypedSelector";
import {
  createQuestionAction,
  QType,
  updateQuestionAction,
} from "../../../../redux/slice/mock-test/questionBankSlice";
import { useParams } from "react-router-dom";
import { useUser } from "../../../../hooks/use-user";
import { useQuestionData } from "../../../../hooks/use-question-data";
import {
  reactQuillFormats,
  reactQuillModules,
  testTimeOptions,
} from "../../../../utils/helperFuntions";

export const CreateListeningToProblemSolvingForm = () => {
  const user = useUser();
  const { isSuccess, isError, isLoading, errorMessage } = useAppSelector(
    (state) => state.questionBank,
  );
  const { testNum } = useParams();
  const [imageUrl, setImageUrl] = useState<string | null>(null);
  const [optionValues, setOptionValues] = useState<string[]>([]);
  const dispatch = useAppDispatch();
  const toast = useToast();

  const {
    handleSubmit,
    register,
    control,
    setValue,
    watch,
    reset,
    formState: { errors },
  } = useForm<ListeningTestFormData>({
    resolver: yupResolver(ListeningTestValidationSchema),
    defaultValues: {
      testNum: testNum,
      listeningToProblemSolvingInstruction: "",
      listeningToProblemSolvingImage: "",
      listeningToProblemSolvingAudioOne: "",
      listeningToProblemSolvingAudioTwo: "",
      listeningToProblemSolvingAudioThree: "",
      questions: Array(5).fill({
        question: "",
        correctAnswer: "",
        optionB: "",
        optionC: "",
        optionD: "",
        optionE: "",
      }),
    },
  });
  const { question, questionData } = useQuestionData(reset, "Listening");

  const { fields, append, remove } = useFieldArray({
    control,
    name: "questions",
  });

  const audioOneQuestions = watch(
    "listeningToProblemSolvingAudioOneNumOfQuestion",
  );
  const audioTwoQuestions = watch(
    "listeningToProblemSolvingAudioTwoNumOfQuestion",
  );
  const audioThreeQuestions = watch(
    "listeningToProblemSolvingAudioThreeNumOfQuestion",
  );

  // Use useWatch to watch the 'questions' array
  const questions = useWatch({
    control,
    name: "questions",
  });

  useEffect(() => {
    // Calculate the total number of questions based on audio question counts
    const total =
      Number(audioOneQuestions || 0) +
      Number(audioTwoQuestions || 0) +
      Number(audioThreeQuestions || 0);

    if (questionData) {
      reset({
        questionsType: "Listening",
        testNum: questionData.testNum,
        bodyContent: questionData.bodyContent,
        listeningToProblemSolvingInstruction:
          questionData.listeningToProblemSolvingInstruction,
        listeningToProblemSolvingImage:
          questionData.listeningToProblemSolvingImage,
        listeningToProblemSolvingAudioOne:
          questionData.listeningToProblemSolvingAudioOne,
        listeningToProblemSolvingAudioOneNumOfQuestion:
          questionData.listeningToProblemSolvingAudioOneNumOfQuestion,
        listeningToProblemSolvingAudioTwo:
          questionData.listeningToProblemSolvingAudioTwo,
        listeningToProblemSolvingAudioTwoNumOfQuestion:
          questionData.listeningToProblemSolvingAudioTwoNumOfQuestion,
        listeningToProblemSolvingAudioThree:
          questionData.listeningToProblemSolvingAudioThree,
        listeningToProblemSolvingAudioThreeNumOfQuestion:
          questionData.listeningToProblemSolvingAudioThreeNumOfQuestion,
        time: questionData.time,
        questions: questionData.questions,
      });
    }
    // Adjust the questions array size
    if (fields.length < total) {
      const toAdd = total - fields.length;
      Array(toAdd)
        .fill(undefined)
        .forEach(() =>
          append({
            question: "",
            correctAnswer: "",
            optionB: "",
            optionC: "",
            optionD: "",
            optionE: "",
          }),
        );
    } else if (fields.length > total) {
      const toRemove = fields.length - total;
      Array(toRemove)
        .fill(undefined)
        .forEach(() => remove(fields.length - 1));
    }
  }, [
    audioOneQuestions,
    audioTwoQuestions,
    audioThreeQuestions,
    append,
    remove,
    fields.length,
    questionData,
    reset,
  ]);

  const onSubmitHandler = async (data: ListeningTestFormData) => {
    const payLoad = {
      id: question?.id ?? null,
      user: user.id,
      type: "Listening" as QType,
      testNum: data.testNum ?? "",
      questionType: "ListeningToProblemSolving",
      data: JSON.stringify(data),
    };

    if (questionData) {
      await dispatch(updateQuestionAction(payLoad));
    } else {
      await dispatch(createQuestionAction(payLoad));
    }

    if (isSuccess) {
      toast({
        title: "Question saved successfully.",
        status: "success",
        position: "top-right",
        duration: 9000,
        isClosable: true,
      });
      setTimeout(() => {
        window.location.href = "/admin-listening";
      }, 1000);
    } else if (isError) {
      toast({
        title: "Error",
        description: errorMessage,
        status: "error",
        position: "top-right",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  useEffect(() => {
    if (questions && questions.length > 0) {
      const newOptionValues = questions.map((question) => {
        const options = [
          question.correctAnswer,
          question.optionB,
          question.optionC,
          question.optionD,
        ];
        const isImageOption = options.some((option) =>
          option?.includes("https://firebasestorage.googleapis.com"),
        );
        return isImageOption ? "imageOptions" : "textOptions";
      });
      setOptionValues(newOptionValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questions]);

  const handleOptionChange = (index: number, value: string) => {
    const newOptionValues = [...optionValues];
    newOptionValues[index] = value;
    setOptionValues(newOptionValues);
  };

  const getAudioLabel = (index: number) => {
    const audioOneCount = Number(audioOneQuestions || 0);
    const audioTwoCount = Number(audioTwoQuestions || 0);
    if (index < audioOneCount) return `Audio one Question ${index + 1}`;
    if (index < audioOneCount + audioTwoCount)
      return `Audio two Question ${index + 1 - audioOneCount}`;
    return `Audio three Question ${index + 1 - audioOneCount - audioTwoCount}`;
  };

  return (
    <Box bg="gray.50" minH="100vh">
      <Container
        maxW="container.2xlg"
        bg="white"
        p={8}
        borderRadius="md"
        boxShadow="md"
        mt={9}
        as="form"
        onSubmit={handleSubmit(onSubmitHandler)}
      >
        <Flex
          direction="row"
          justify="space-between"
          align="center"
          bg="gray.200"
          border="1px #CCC solid"
          p={3}
          h={10}
        >
          <Heading size="md">
            Create Listening To Problem Solving Question
          </Heading>
        </Flex>

        <SimpleGrid columns={2} spacing={8} p={0}>
          {/* Audio Conversation Input */}
          <VStack
            align="start"
            spacing={5}
            w="full"
            p={3}
            border="1px solid #CCC"
            minH="90vh"
            // maxH="90vh"
            overflowY="auto"
          >
            <Box w="100%">
              <FormControl isInvalid={!!errors.time} mb={9}>
                <FormLabel>Question Time</FormLabel>
                <Select
                  placeholder="Select question time"
                  bg="#FFF"
                  {...register("time")}
                  required
                >
                  {testTimeOptions.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>{errors.time?.message}</FormErrorMessage>
              </FormControl>
              <FormControl
                mt={4}
                isInvalid={!!errors.listeningToProblemSolvingInstruction}
              >
                <FormLabel>Instruction</FormLabel>
                <ReactQuill
                  theme="snow"
                  value={watch("listeningToProblemSolvingInstruction")}
                  onChange={(content) =>
                    setValue("listeningToProblemSolvingInstruction", content)
                  }
                  modules={reactQuillModules}
                  formats={reactQuillFormats}
                  style={{ height: 200 }}
                />
              </FormControl>
            </Box>
            <Box w="100%" p={4} mt={4}>
              <FormControl
                mt={4}
                isInvalid={!!errors.listeningToProblemSolvingImage}
              >
                <FormLabel>Image Instruction</FormLabel>
                <>
                  <ImageUploader
                    setImageUrl={(url) => {
                      setValue("listeningToProblemSolvingImage", url ?? "");
                      setImageUrl(url);
                    }}
                    oldImage={watch("listeningToProblemSolvingImage")}
                  />
                </>
              </FormControl>
            </Box>
            <Box w="100%" p={4} mt={4}>
              <FormControl
                mt={4}
                isInvalid={!!errors.listeningToProblemSolvingAudioOne}
              >
                <FormLabel>Audio Conversation one</FormLabel>
                <AudioAndVideoUploader
                  setMediaUrl={(url) =>
                    setValue("listeningToProblemSolvingAudioOne", url ?? "", {
                      shouldValidate: true,
                    })
                  }
                  fileType={"audio"}
                  oldAudio={watch("listeningToProblemSolvingAudioOne")}
                />
                <FormErrorMessage>
                  {errors.listeningToProblemSolvingAudioOne?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                mt={4}
                isInvalid={
                  !!errors.listeningToProblemSolvingAudioOneNumOfQuestion
                }
              >
                <FormLabel>Audio one Number of Question</FormLabel>
                <Select
                  autoComplete="Number of Questions"
                  placeholder="Number of Questions"
                  bg="#FFF"
                  {...register(
                    "listeningToProblemSolvingAudioOneNumOfQuestion",
                  )}
                  required
                >
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((value, key) => (
                    <option key={key} value={value}>
                      {value}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>
                  {
                    errors.listeningToProblemSolvingAudioOneNumOfQuestion
                      ?.message
                  }
                </FormErrorMessage>
              </FormControl>
            </Box>
            <Box w="100%" p={4} mt={4}>
              <FormControl
                mt={4}
                isInvalid={!!errors.listeningToProblemSolvingAudioTwo}
              >
                <FormLabel>Audio Conversation Two</FormLabel>
                <AudioAndVideoUploader
                  setMediaUrl={(url) =>
                    setValue("listeningToProblemSolvingAudioTwo", url ?? "", {
                      shouldValidate: true,
                    })
                  }
                  fileType={"audio"}
                  oldAudio={watch("listeningToProblemSolvingAudioTwo")}
                />
                <FormErrorMessage>
                  {errors.listeningToProblemSolvingAudioTwo?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                mt={4}
                isInvalid={
                  !!errors.listeningToProblemSolvingAudioTwoNumOfQuestion
                }
              >
                <FormLabel>Audio two Number of Question</FormLabel>
                <Select
                  autoComplete="Number of Questions"
                  placeholder="Number of Questions"
                  bg="#FFF"
                  {...register(
                    "listeningToProblemSolvingAudioTwoNumOfQuestion",
                  )}
                  required
                >
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((value, key) => (
                    <option key={key} value={value}>
                      {value}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>
                  {
                    errors.listeningToProblemSolvingAudioTwoNumOfQuestion
                      ?.message
                  }
                </FormErrorMessage>
              </FormControl>
            </Box>
            <Box w="100%" p={4} mt={4}>
              <FormControl
                mt={4}
                isInvalid={!!errors.listeningToProblemSolvingAudioThree}
              >
                <FormLabel>Audio Conversation Three</FormLabel>
                <AudioAndVideoUploader
                  setMediaUrl={(url) =>
                    setValue("listeningToProblemSolvingAudioThree", url ?? "", {
                      shouldValidate: true,
                    })
                  }
                  fileType={"audio"}
                  oldAudio={watch("listeningToProblemSolvingAudioThree")}
                />
                <FormErrorMessage>
                  {errors.listeningToProblemSolvingAudioThree?.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                mt={4}
                isInvalid={
                  !!errors.listeningToProblemSolvingAudioThreeNumOfQuestion
                }
              >
                <FormLabel>Audio three Number of Question</FormLabel>
                <Select
                  autoComplete="Number of Questions"
                  placeholder="Number of Questions"
                  bg="#FFF"
                  {...register(
                    "listeningToProblemSolvingAudioThreeNumOfQuestion",
                  )}
                  required
                >
                  {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((value, key) => (
                    <option key={key} value={value}>
                      {value}
                    </option>
                  ))}
                </Select>
                <FormErrorMessage>
                  {
                    errors.listeningToProblemSolvingAudioThreeNumOfQuestion
                      ?.message
                  }
                </FormErrorMessage>
              </FormControl>
            </Box>
          </VStack>

          <VStack
            align="start"
            spacing={5}
            w="full"
            p={3}
            bg="#f7fafc"
            minH="90vh"
            border="1px solid #CCC"
            overflowY="auto"
          >
            {/* Questions Section */}
            {fields.map((item, index) => (
              <Box key={item.id} w="100%">
                <FormControl isInvalid={!!errors.questions?.[index]?.question}  bg={"gray.300"}
            p={3}
            borderRadius={"md"}>
                  <FormLabel color={"blue"}>{getAudioLabel(index)}</FormLabel>

                  <AudioAndVideoUploader
                    setMediaUrl={(url) =>
                      setValue(`questions.${index}.question`, url ?? "", {
                        shouldValidate: true,
                      })
                    }
                    fileType={"audio"}
                    oldAudio={watch(`questions.${index}.question`)}
                  />
                  <FormErrorMessage>
                    {errors.questions?.[index]?.question?.message}
                  </FormErrorMessage>
                </FormControl>

                <RadioGroup
                  onChange={(value) => handleOptionChange(index, value)}
                  value={optionValues[index] || "textOptions"}
                  mb={4}
                  mt={5}
                >
                  <Stack direction="row">
                    <Radio value="textOptions">Text Options</Radio>
                    <Radio value="imageOptions">Image Options</Radio>
                  </Stack>
                </RadioGroup>
                {/* Dynamic question type Section */}
                {optionValues[index] === "imageOptions" ? (
                  <>
                    <FormControl
                      mt={2}
                      isInvalid={!!errors.questions?.[index]?.correctAnswer}
                      bg={"green.100"}
                      p={4}
                      borderRadius={"md"}
                    >
                      <FormLabel>(A) Correct Answer</FormLabel>
                      <ImageUploader
                        setImageUrl={(url) => {
                          setValue(
                            `questions.${index}.correctAnswer`,
                            url ?? "",
                            { shouldValidate: true },
                          );
                        }}
                        oldImage={watch(`questions.${index}.correctAnswer`)}
                      />
                      <FormErrorMessage>
                        {errors.questions?.[index]?.correctAnswer?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      mt={2}
                      isInvalid={!!errors.questions?.[index]?.optionB}
                    >
                      <FormLabel>(B) Option</FormLabel>
                      <ImageUploader
                        setImageUrl={(url) => {
                          setValue(`questions.${index}.optionB`, url ?? "", {
                            shouldValidate: true,
                          });
                        }}
                        oldImage={watch(`questions.${index}.optionB`)}
                      />
                      <FormErrorMessage>
                        {errors.questions?.[index]?.optionB?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      mt={2}
                      isInvalid={!!errors.questions?.[index]?.optionC}
                    >
                      <FormLabel>(C) Option</FormLabel>
                      <ImageUploader
                        setImageUrl={(url) => {
                          setValue(`questions.${index}.optionC`, url ?? "", {
                            shouldValidate: true,
                          });
                        }}
                        oldImage={watch(`questions.${index}.optionC`)}
                      />
                      <FormErrorMessage>
                        {errors.questions?.[index]?.optionC?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      mt={2}
                      isInvalid={!!errors.questions?.[index]?.optionD}
                    >
                      <FormLabel>(D) Option</FormLabel>
                      <ImageUploader
                        setImageUrl={(url) => {
                          setValue(`questions.${index}.optionD`, url ?? "", {
                            shouldValidate: true,
                          });
                        }}
                        oldImage={watch(`questions.${index}.optionD`)}
                      />
                      <FormErrorMessage>
                        {errors.questions?.[index]?.optionD?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </>
                ) : (
                  <>
                    <FormControl
                      mt={2}
                      isInvalid={!!errors.questions?.[index]?.correctAnswer}
                      bg={"green.100"}
                      p={4}
                      borderRadius={"md"}
                    >
                      <FormLabel>(A) Correct Answer</FormLabel>
                      <Input
                        placeholder="Answer"
                        {...register(`questions.${index}.correctAnswer`)}
                        bg={"#FFF"}
                        required
                      />
                      <FormErrorMessage>
                        {errors.questions?.[index]?.correctAnswer?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      mt={2}
                      isInvalid={!!errors.questions?.[index]?.optionB}
                    >
                      <FormLabel>(B) Option</FormLabel>
                      <Input
                        placeholder="Option B"
                        {...register(`questions.${index}.optionB`)}
                        required
                      />
                      <FormErrorMessage>
                        {errors.questions?.[index]?.optionB?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      mt={2}
                      isInvalid={!!errors.questions?.[index]?.optionC}
                    >
                      <FormLabel>(C) Option</FormLabel>
                      <Input
                        placeholder="Option C"
                        {...register(`questions.${index}.optionC`)}
                        required
                      />
                      <FormErrorMessage>
                        {errors.questions?.[index]?.optionC?.message}
                      </FormErrorMessage>
                    </FormControl>
                    <FormControl
                      mt={2}
                      isInvalid={!!errors.questions?.[index]?.optionD}
                    >
                      <FormLabel>(D) Option</FormLabel>
                      <Input
                        placeholder="Option D"
                        {...register(`questions.${index}.optionD`)}
                        required
                      />
                      <FormErrorMessage>
                        {errors.questions?.[index]?.optionD?.message}
                      </FormErrorMessage>
                    </FormControl>
                  </>
                )}
              </Box>
            ))}
          </VStack>
        </SimpleGrid>

        <HStack justify="space-between" mt={10}>
          <Button colorScheme="red" type="reset">
            Reset Form
          </Button>
          <Button type="submit" colorScheme="teal" isLoading={isLoading}>
            Submit Form
          </Button>
        </HStack>
      </Container>
    </Box>
  );
};

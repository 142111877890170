import React from "react";
import { Stack } from "@chakra-ui/react";
import { Elements } from "@stripe/react-stripe-js";
import { useStudentPlan } from "../../../hooks/use-student-plan"; 
import { PackageSelection } from "./PackageSelection";
import { AmountDetails } from "./AmountDetails";

export const StudentPackagePlan: React.FC = () => {
  const { stripePromise, days, updatePrice, handleCheckout } = useStudentPlan();
  return (
    <Elements stripe={stripePromise}>
      <Stack spacing={8} p={4} w={["full", "90%"]} mx="auto">
        <PackageSelection days={days} updatePrice={updatePrice} />
        <AmountDetails
          days={days}
          handleCheckout={handleCheckout}
          btnTitle={"Check Out"}
        />
      </Stack>
    </Elements>
  );
};

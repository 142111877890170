import {
  Box,
  Center,
  HStack,
  Stack,
  Heading,
  SimpleGrid,
  Flex,
  Icon,
  Spinner,
} from "@chakra-ui/react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import {
  FcManager,
} from "react-icons/fc";
import { AddNewStudentModal } from "../students/edit/AddNewStudentModal";
import { useStudents } from "../../hooks/use-students";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const data = {
  labels: ["Reading", "Speaking", "Writing", "Listening"],
  datasets: [
    {
      label: "Total Mock Tests",
      data: [50, 30, 40, 20],
      backgroundColor: "rgba(75, 192, 192, 0.6)",
    },
    {
      label: "Students Per Test",
      data: [120, 80, 100, 70],
      backgroundColor: "rgba(153, 102, 255, 0.6)",
    },
  ],
};

export const TeacherDashboard = () => {
const {isLoading, students} = useStudents("teacher");

  if (isLoading) {
    return <Spinner />;
  }
  
  
  return (
    <Stack spacing={8} padding={8}>
      <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
        <Box boxShadow="md" p={6} rounded="md" bg="white">
          <Heading as="h3" size="lg">
            Mock Test Analysis
          </Heading>
          <Bar
            data={data}
            options={{
              responsive: true,
              plugins: {
                legend: { position: "top" },
                title: { display: true, text: "Mock Test Analysis" },
              },
            }}
          />
        </Box>
        <Box boxShadow="md" p={6} rounded="md" bg="white">
          <Center p={10}>
          <Stack align={"center"} spacing={2}>
            <Flex
              w={16}
              h={16}
              align={"center"}
              justify={"center"}
              color={"white"}
              rounded={"full"}
            >
              <Icon as={FcManager} w={10} h={10} />
            </Flex>
            <Heading size="md" mt={9}>
              Total Students: {students.length}
            </Heading>
          </Stack>
          </Center>
          <HStack spacing={4} justify="center" mt={10}>
          <AddNewStudentModal />
          </HStack>
        </Box>
      </SimpleGrid>
    </Stack>
  );
};

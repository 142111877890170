import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import readingTestService from "../../service/mockTestService";

export type CreateMockTestState = {
  data: any;
  isError: boolean;
  isSuccess: boolean;
  isLoading: boolean;
  errorMessage: string;
};

export const initialState: CreateMockTestState = {
  data: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  errorMessage: "",
};
export type QType =
  | "Listening"
  | "Reading"
  | "Writing"
  | "Speaking";

export type StartTestPayload = {
  type: QType,
  testNum: string;
}
export const handleRejected = (state: CreateMockTestState, action: any) => {
  state.isLoading = false;
  state.isError = true;
  state.errorMessage = action.payload as string;
};


export const startMockTestQuestionsAction = createAsyncThunk('auth/start-test', async (payload: StartTestPayload, thunkAPI) => {
  try {
    return await readingTestService.startMockTest(payload,thunkAPI);
  } catch (error: any) {
    let message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

    return message;
  }
});

export const mockTestSlice = createSlice({
  name: "mockTest",
  initialState,
  reducers: {
    CreateMockTestTest: (state) => initialState,
  },
  extraReducers: (builder) => {
    builder
      .addCase(startMockTestQuestionsAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(startMockTestQuestionsAction.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.isError = false
        state.data = action?.payload?.data;
      })
      .addCase(startMockTestQuestionsAction.rejected, (state, action) => {
        handleRejected(state, action);
      });
  },
});

export const { CreateMockTestTest } = mockTestSlice.actions;
export default mockTestSlice.reducer;
import React from "react";
import {
  Box,
  Container,
  Heading,
  VStack,
  HStack,
  Icon,
  Button,
  Flex,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";
import { useParams, useNavigate } from "react-router-dom";

export const ListeningTestInstructions: React.FC = () => {
  const { testNum } = useParams();
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <Box minH="100vh" py={10}>
      <Container
        maxW="100%"
        bg="white"
        p={8}
        borderRadius="md"
        boxShadow="md"
        mt={90}
      >
        <Flex direction="row" justify="space-between" align="center">
          <Heading size="md" mb={6}>
            Mock Test - Listening
          </Heading>
          <Button
            as={"a"}
            href={`/listening-test/${testNum}`}
            colorScheme="blue"
            h={7}
          >
            NEXT
          </Button>
        </Flex>
        <VStack align="start" spacing={5}>
          <HStack>
            <Icon as={InfoIcon} color="blue.500" />
            <Heading size="sm">Listening Test Instructions</Heading>
          </HStack>
          <VStack align="start" spacing={3} color={"blue.600"}>
            <UnorderedList spacing={3}>
              <ListItem>
                On the official test, once you leave a page, you cannot go back
                to it to change your answers. However, in this practice test,
                you can.
              </ListItem>
              <ListItem>
                Please note that the order of question types on the official
                test may differ from the order presented here.
              </ListItem>
              <ListItem>
                This Listening Test is identical in format to the official test
                except that the Listening section of the official test may be
                slightly longer as it might contain additional questions
                included for research and development purposes.
              </ListItem>
            </UnorderedList>
          </VStack>
        </VStack>
        <HStack justify="space-between" mt={10}>
          <Button colorScheme="red" h={7} onClick={handleBackClick}>
            BACK
          </Button>
        </HStack>
      </Container>
    </Box>
  );
};

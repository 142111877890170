import { useState, useEffect } from "react";
import {
  Flex,
  Heading,
  Spacer,
  Spinner,
  Stack,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
  IconButton,
} from "@chakra-ui/react";

export const TestResult = () => {
  
  return (
    <Stack>
      <Flex>
        <Heading>Result</Heading>
        <Spacer />
        {/* <AddNewStudentModal /> */}
      </Flex>

      <TableContainer>
        <Table variant="simple" bg={"#FFF"} boxShadow={"2xl"} borderRadius={"md"}>
          <Thead bg={"gray.50"}>
            <Tr>
              <Th>ID</Th>
              <Th>First Name</Th>
              <Th>Last Name</Th>
              <Th>Subject</Th>
              <Th>Score</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
          
          </Tbody>
        </Table>
      </TableContainer>
    </Stack>
  );
};

import axios from "axios";
import { config } from "../../config";
import { getTokenAndConfig } from "./authService";
import { QType, StartTestPayload } from "../slice/mock-test/mockTestSlice";
import { SpeakingFeedback } from "../slice/mock-test/speakingTestSlice";
import { CreateMockTest } from "../slice/mock-test/questionBankSlice";
import { UserWritingResponseSchemaType } from "../../apps/test/writing/user-response/schema";
import { GetMockTestResult, ResultPayload } from "../slice/mock-test/mockTestResultSlice";

const API_URL = config.baseUrl.default;

const createTestQuestion = async (payload: CreateMockTest, thunkAPI: any) => {
  const response = await axios.post(
    API_URL + "question/create",
    payload,
    getTokenAndConfig(thunkAPI),
  );

  return response.data;
};
const updateTestQuestion = async (payload: CreateMockTest, thunkAPI: any) => {
  const response = await axios.post(
    API_URL + "question/update",
    payload,
    getTokenAndConfig(thunkAPI),
  );

  return response.data;
};
const getQuestions= async (questionType: QType, thunkAPI: any) => {
  const response = await axios.get(
    API_URL +
      `questions/${questionType}`,
    getTokenAndConfig(thunkAPI),
  );
  return response.data;
};
const startMockTest= async (payload: StartTestPayload, thunkAPI: any) => {
  const response = await axios.get(
    API_URL +
      `start-mock-test/${payload.type}/${payload.testNum}`,
    getTokenAndConfig(thunkAPI),
  );
  return response.data;
};
const sendMockTestResult = async (payload: ResultPayload, thunkAPI: any) => {
  const response = await axios.post(
    API_URL + "create-mock-test-score",
    payload,
    getTokenAndConfig(thunkAPI),
  );

  return response.data;
};

const sendUserWritingResponse = async (payload: UserWritingResponseSchemaType, thunkAPI: any) => {
  const response = await axios.post(
    API_URL + "mock-test-writing-answer",
    payload,
    getTokenAndConfig(thunkAPI),
  );

  return response.data;
};
const sendUserSpeakingResponse = async (payload: any, thunkAPI: any) => {
  const response = await axios.post(
    API_URL + "mock-test-speaking-answer",
    payload,
    getTokenAndConfig(thunkAPI),
  );

  return response.data;
};
const getSpeakingFeedback= async (data: SpeakingFeedback, thunkAPI: any) => {
  const response = await axios.get(
    API_URL +
      `mock-test-speaking/${data.testNum}/${data.userID}`,
    getTokenAndConfig(thunkAPI),
  );
  return response.data;
};

const getAllMockTests= async (userId: number,thunkAPI: any) => {
  const response = await axios.get(
    API_URL +
      `mock-test/${userId}`,
    getTokenAndConfig(thunkAPI),
  );
  return response.data;
};
const getMockTestResult= async (data: GetMockTestResult, thunkAPI: any) => {
  const response = await axios.get(
    API_URL +
      `get-mock-test-result/${data.testNum}/${data.userID}`,
    getTokenAndConfig(thunkAPI),
  );
  return response.data;
};

const readingTestService = {
  createTestQuestion,
  updateTestQuestion,
  getQuestions,
  startMockTest,
  sendUserWritingResponse,
  sendUserSpeakingResponse,
  getSpeakingFeedback,
  getAllMockTests,
  sendMockTestResult,
  getMockTestResult,
};

export default readingTestService;

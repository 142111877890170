import { useMemo } from "react";
import { ReadingAndListeningResponseType } from "../types/reading-types";
import { readingScoringAnswer } from "../utils/scoring-format";

type useListeningAndReadingScoringProps = {
  feedback: string;
};

export const useListeningAndReadingScoring = ({
  feedback,
}: useListeningAndReadingScoringProps) => {
  const responses = useMemo(() => {
    try {
      return JSON.parse(feedback) as ReadingAndListeningResponseType[] | null;
    } catch (error) {
      console.error("Error parsing feedback JSON:", error);
      return null;
    }
  }, [feedback]);

  const totalCorrectAnswers = useMemo(() => {
    if (Array.isArray(responses)) {
      return responses?.reduce((acc, response) => {
        if (response?.correctAnswer === response?.userAnswer) {
          if (response?.questionType) {
            acc += 1;
          }
        }
        return acc;
      }, 0);
    }
    return 0;
  }, [responses]);

  const totalScore = useMemo(() => {
    return readingScoringAnswer(totalCorrectAnswers);
  }, [totalCorrectAnswers]);

  return { responses, totalCorrectAnswers, totalScore };
};

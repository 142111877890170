import React, { FC } from "react";
import { ReadingQuestionBankType } from "../schema";
import "react-quill/dist/quill.snow.css";
import { useParams } from "react-router-dom";
import { CreateReadingCorrespondenceForm } from "./CreateReadingCorrespondenceForm";
import { Box } from "@chakra-ui/react";
import { CreatePracticeQuestionForm } from "./CreatePracticeQuestionForm";
import { CreateReadingToApplyADiagramForm } from "./CreateReadingToApplyADiagramForm";
import { CreateReadingForInformationForm } from "./CreateReadingForInformationForm";
import { CreateReadingForViewpointsForm } from "./CreateReadingForViewpointsForm";

export const CreateReadingTestForm: React.FC = () => {
  const { questionType } = useParams<{
    questionType: ReadingQuestionBankType;
    testNum: string;
  }>();

  let FormComponent: FC<any>;

  switch (questionType) {
    case "ReadingCorrespondence":
      FormComponent = CreateReadingCorrespondenceForm;
      break;
    case "PracticeQuestion":
      FormComponent = CreatePracticeQuestionForm;
      break;
    case "ReadingToApplyADiagram":
      FormComponent = CreateReadingToApplyADiagramForm;
      break;
    case "ReadingForInformation":
      FormComponent = CreateReadingForInformationForm;
      break;
      case "ReadingForViewpoints":
        FormComponent = CreateReadingForViewpointsForm;
        break;
    default:
      FormComponent = () => <Box>Form not found</Box>;
      break;
  }

  return (
    <Box pr={3}>
      <FormComponent />
    </Box>
  );
};

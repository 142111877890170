import React, { useEffect, useMemo } from "react";
import { useAnswerStore } from "../../../../redux/zustand-store";
import { useUser } from "../../../../hooks/use-user";
import { useMockTestList } from "../../../../hooks/use-mock-test-list";
import { useParams } from "react-router-dom";
import { useAppDispatch } from "../../../../redux/hooks/useTypedSelector";
import { sendMockTestResultAction } from "../../../../redux/slice/mock-test/mockTestResultSlice";
import { decryptString } from "../../../../utils/encryption";
import { ListeningAnswerTable } from "./ListeningAnswerTable";

export const ListeningAnswerKey: React.FC = () => {
  const user = useUser();
  const { getNextStepHref } = useMockTestList();
  const { testNum } = useParams();
  const dispatch = useAppDispatch();
  const { responses } = useAnswerStore();


  const totalCorrectAnswers = useMemo(() => {
    return responses.reduce((acc, response) => {
      if (response.correctAnswer === response.userAnswer) {
        acc += 1;
      }
      return acc;
    }, 0);
  }, [responses]);

  const getTotalScore = (correctAnswers: number) => {
    if (correctAnswers >= 35 && correctAnswers <= 38) return "10 to 12";
    if (correctAnswers >= 33 && correctAnswers < 35) return 9;
    if (correctAnswers >= 30 && correctAnswers < 33) return 8;
    if (correctAnswers >= 27 && correctAnswers < 31) return 7;
    if (correctAnswers >= 22 && correctAnswers < 28) return 6;
    if (correctAnswers >= 17 && correctAnswers < 23) return 5;
    if (correctAnswers >= 11 && correctAnswers < 18) return 4;
    if (correctAnswers >= 7 && correctAnswers < 12) return 3;
    if (correctAnswers >= 0 && correctAnswers < 7) return "M";
    return 0;
  };
  const totalScore = getTotalScore(totalCorrectAnswers);

  useEffect(() => {

    const saveResultData = async () => {
      const payLoad = {
        testID: "0",
        testNum: decryptString(testNum?.toString() ?? ""),
        userID: user.id.toString(),
        testType: "Listening",
        section: "",
        score: totalScore.toString(),
        overallScore: "0",
        feedback: JSON.stringify(responses),
      };
      await dispatch(sendMockTestResultAction(payLoad));
    };
    saveResultData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isCompleteMockTest = localStorage.getItem("isCompleteMockTest");
    if(isCompleteMockTest === "true"){
      window.location.href = `${getNextStepHref("Start")}/${testNum}`;
    }
}, [getNextStepHref,testNum]);

  return (
    <ListeningAnswerTable
    totalCorrectAnswers={totalCorrectAnswers}
    totalScore={totalScore}
    responses={responses}
  />
  );
};

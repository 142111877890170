import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AddNewStudentFormData } from '../../../apps/students/schema';
import studentService from '../../service/studentService';

export type Student = {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    created_at: string;
};
type CreateStudentState = {
  user: any;
  isError: boolean;
  isSuccess: boolean;
  isLoading: boolean;
  errorMessage: string;
}

const initialState: CreateStudentState = {
  user: null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  errorMessage: '',
};
export type GetStudents = {
    studentId?: string;
    teacherId: string;
}

export const createStudentAction = createAsyncThunk('student/create', async (data: AddNewStudentFormData, thunkAPI) => {
  try {
    return await studentService.create(data,thunkAPI);
  } catch (error: any) {
    let message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

    if (error.response?.status === 400) {
      message = error.response.data.data;
    }
    return thunkAPI.rejectWithValue(message);
  }
});
export const getAllStudentsAction = createAsyncThunk('students', async (id: string, thunkAPI) => {
  try {
    return await studentService.students(thunkAPI);
  } catch (error: any) {
    let message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

    if (error.response?.status === 400) {
      message = error.response.data.data;
    }
    return thunkAPI.rejectWithValue(message);
  }
});

export const getStudentAction = createAsyncThunk('student/get', async (data: GetStudents, thunkAPI) => {
    try {
      return await studentService.get(data,thunkAPI);
    } catch (error: any) {
      let message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString();
  
      if (error.response?.status === 400) {
        message = error.response.data.data;
      }
      return thunkAPI.rejectWithValue(message);
    }
});
export const removeStudentAction = createAsyncThunk('student/remove', async (data: GetStudents, thunkAPI) => {
  try {
    return await studentService.remove(data,thunkAPI);
  } catch (error: any) {
    let message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString();

    if (error.response?.status === 400) {
      message = error.response.data.data;
    }
    return thunkAPI.rejectWithValue(message);
  }
});

const handleRejected = (state: CreateStudentState, action: any) => {
  state.isLoading = false;
  state.isSuccess = false;
  state.isError = true;
  state.errorMessage = action.payload as string;
};
const handleFulfilled = (state: CreateStudentState, action: any) => {
  if(action?.payload?.status !== 500 || action?.payload?.status !== 511){
    state.isLoading = false
    state.isSuccess = true
    state.isError = false
    state.user = action?.payload?.user ?  action.payload as AddNewStudentFormData : null;
  }else{
    state.isLoading = false
    state.isSuccess = false
    state.isError = true
    state.errorMessage = action.payload.data.errorMessage
    state.user = null
  }
};
export const studentSlice = createSlice({
  name: 'students',
  initialState,
  reducers: {
    resetStudent: (state) => {
      state.isError = false
      state.isSuccess = false
      state.isLoading = false
      state.errorMessage = ''
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createStudentAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createStudentAction.fulfilled, (state, action) => {
        handleFulfilled(state, action); 
      })
      .addCase(createStudentAction.rejected, (state, action) => {
        handleRejected(state, action);
      })
      .addCase(getStudentAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getStudentAction.fulfilled, (state, action) => {
        handleFulfilled(state, action); 
      })
      .addCase(getStudentAction.rejected, (state, action) => {
        handleRejected(state, action);
      })
      .addCase(removeStudentAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(removeStudentAction.fulfilled, (state, action) => {
        handleFulfilled(state, action); 
      })
      .addCase(removeStudentAction.rejected, (state, action) => {
        handleRejected(state, action);
      }).addCase(getAllStudentsAction.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAllStudentsAction.fulfilled, (state, action) => {
        handleFulfilled(state, action); 
      })
      .addCase(getAllStudentsAction.rejected, (state, action) => {
        handleRejected(state, action);
      });
  },
});



export const { resetStudent } = studentSlice.actions;
export default studentSlice.reducer;
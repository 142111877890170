import {
    Box,
    Button,
    Center,
    HStack,
    Stack,
    Heading,
    SimpleGrid,
    Text,
  } from "@chakra-ui/react";
  import { Bar } from "react-chartjs-2";
  import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  } from "chart.js";
  
  ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  );
  
  const data = {
    labels: ["Reading", "Speaking", "Writing", "Listening"],
    datasets: [
      {
        label: "Total Mock Tests",
        data: [50, 30, 40, 20], 
        backgroundColor: "rgba(75, 192, 192, 0.6)",
      },
      {
        label: "Students Per Test",
        data: [120, 80, 100, 70], 
        backgroundColor: "rgba(153, 102, 255, 0.6)",
      },
    ],
  };
  
  export const AdminDashboard = () => {
   
    
    return (
      <Stack spacing={8} padding={8}>
        <Center>
          <Heading as="h1" size="2xl">
            Dashboard
          </Heading>
        </Center>
        <SimpleGrid columns={{ base: 1, md: 2 }} spacing={10}>
          <Box boxShadow="md" p={6} rounded="md" bg="white">
            <Heading as="h3" size="lg">
              Mock Test Analysis
            </Heading>
            <Bar
              data={data}
              options={{
                responsive: true,
                plugins: {
                  legend: { position: "top" },
                  title: { display: true, text: "Mock Test Analysis" },
                },
              }}
            />
          </Box>
          <Box boxShadow="md" p={6} rounded="md" bg="white">
            <Heading as="h3" size="lg" mb={9}>
              Total Analysis
            </Heading>
            <Text fontSize="lg">Total Students: 300</Text>
            <Text fontSize="lg">Total Teachers: 20</Text>
          </Box>
        </SimpleGrid>
        <HStack spacing={4} justify="center" mt={10}>
          <Button as="a" href="#" colorScheme="teal" size="lg">
            Take Mock Test
          </Button>
        </HStack>
      </Stack>
    );
  };
  
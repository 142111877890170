import { ComponentStyleConfig } from "@chakra-ui/react";

export const ReiModal: ComponentStyleConfig = {
  baseStyle: {
    overlay: {
      backdropFilter: "blur(10px) hue-rotate(20deg)",
    },
    header: {
      fontFamily: "var(--chakra-fonts-heading)",
      textAlign: "center",
      fontWeight: "normal",
      padding: "1.6rem",
    },
    footer: {
      padding: "2rem 1.6rem",
    },
  },
  defaultProps: {
    motionPreset: "slideInBottom",
  },
};

import create from 'zustand';

type Question = {
  questionNumber: string;
  question: string;
  answer: string;
};

type QuestionStore = {
  speakingResponse: Question[];
  addQuestion: (question: Question) => void;
};

export const useSpeakingQuestionStore = create<QuestionStore>((set) => ({
  speakingResponse: [],
  addQuestion: (question) =>
    set((state) => ({ speakingResponse: [...state.speakingResponse, question] })),
}));

import * as yup from 'yup';

export type ReadingQuestionBankType = 
  | "PracticeQuestion" 
  | "ReadingToApplyADiagram" 
  | "ReadingForInformation" 
  | "ReadingForViewpoints" 
  | "ReadingCorrespondence" 



export type ReadingQuestion = {
  id: number,
  questionType: string,
  testNum: string,
  user: number,
  data: any;
  createAt: string,
  UpdateAt: string,
};
export type Question = {
  question: string;
  correctAnswer: string;
  optionB: string;
  optionC: string;
  optionD: string;
  optionE?: string;
};

export type QuestionData = {
  testNum: string | undefined;
  questionsType: string;
  bodyContent: string;
  sectionTwoQuestions: string;
  time: string;
  questions: Question[];
};


export const ReadingTestValidationSchema = yup.object().shape({
  questionsType: yup.string().required("Header is required"),
  testNum: yup.string().required("Test Num is required"),
  bodyContent: yup.string().required("Body Content is required"),
  sectionTwoQuestions: yup.string().optional(),
  time: yup.string().required("Time is required"),
  questions: yup.array().of(
    yup.object().shape({
      question: yup.string().optional(),
      correctAnswer: yup.string().optional(),
      optionB: yup.string().optional(),
      optionC: yup.string().optional(),
      optionD: yup.string().optional(),
      optionE: yup.string().optional(),
    })
  ),
});

export type ReadingTestFormData = yup.InferType<typeof ReadingTestValidationSchema>;

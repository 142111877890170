import { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  HStack,
  Stack,
  Heading,
  SimpleGrid,
  Text,
  Progress,
  IconButton,
  Image,
  Flex,
} from "@chakra-ui/react";
import { FiMic, FiPlay } from "react-icons/fi";
import { useUser } from "../../hooks/use-user";

export const StudentDashboard = () => {
  const user = useUser();
  const [isRecording, setIsRecording] = useState(false);
  const [audioBlob, setAudioBlob] = useState<Blob | null>(null);
  const [recordingProgress, setRecordingProgress] = useState(0);
  const [playbackProgress, setPlaybackProgress] = useState(0);
  const mediaRecorderRef = useRef<MediaRecorder | null>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const recordingIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const playbackIntervalRef = useRef<NodeJS.Timeout | null>(null);

  const handleStartRecording = async () => {
    if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
      alert("Your browser does not support audio recording");
      return;
    }

    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    mediaRecorderRef.current = new MediaRecorder(stream);
    const audioChunks: Blob[] = [];

    mediaRecorderRef.current.ondataavailable = (event) => {
      audioChunks.push(event.data);
    };

    mediaRecorderRef.current.onstop = () => {
      const audioBlob = new Blob(audioChunks, { type: "audio/mp3" });
      setAudioBlob(audioBlob);
      stream.getTracks().forEach((track) => track.stop());
      setRecordingProgress(0);
    };

    mediaRecorderRef.current.start();
    setIsRecording(true);

    // Start recording progress
    recordingIntervalRef.current = setInterval(() => {
      setRecordingProgress((prev) => prev + 1);
    }, 1000); // Update every second
  };

  const handleStopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
      setIsRecording(false);
      clearInterval(recordingIntervalRef.current!);
      setRecordingProgress(0); // Reset progress after recording stops
    }
  };

  const handlePlayRecording = () => {
    if (audioBlob) {
      const audioURL = URL.createObjectURL(audioBlob);
      audioRef.current = new Audio(audioURL);
      audioRef.current.play();

      // Initialize playback progress
      setPlaybackProgress(0);

      playbackIntervalRef.current = setInterval(() => {
        if (audioRef.current) {
          const progress =
            (audioRef.current.currentTime / audioRef.current.duration) * 100;
          setPlaybackProgress(progress);

          // Clear interval when audio ends
          if (progress >= 100) {
            clearInterval(playbackIntervalRef.current!);
          }
        }
      }, 100);
    }
  };

  useEffect(() => {
    return () => {
      // Clean up intervals on component unmount
      if (recordingIntervalRef.current) clearInterval(recordingIntervalRef.current);
      if (playbackIntervalRef.current) clearInterval(playbackIntervalRef.current);
    };
  }, []);

  return (
    <Stack spacing={8} padding={8}>
      <Box bg="blue.50" borderRadius="md" p={4} boxShadow="md" textAlign="center">
        <Flex direction={["column", "row"]} align="center">
          <Box mb={[4, 0]}>
            <Image src="/images/student.png" w={[100, 200]} h={[100, 200]} />
          </Box>
          <Box p={[2, 9]} textAlign={["center", "left"]}>
            <Text fontSize={["xl", "2xl"]} fontWeight="bold">
              Hello,
            </Text>
            <Text fontSize={["2xl", "3xl"]} fontWeight="bold">
              {`${user.firstName} ${user.lastName}`}
            </Text>
            <Text fontSize="md" color="gray.600">
              Practice and master the CELPIP Test with us
            </Text>
          </Box>
        </Flex>
      </Box>

      <SimpleGrid columns={[1, 2]} spacing={10}>
        <Box bg="white" borderRadius="md" p={4} boxShadow="md" textAlign="center">
          <Heading as="h2" size="md">
            Total Mock Test
          </Heading>
          <Text fontSize="lg">1/7</Text>
          <Progress value={1} max={100} colorScheme="blue" />
        </Box>

        <Box bg="white" borderRadius="md" p={4} boxShadow="md" textAlign="center">
          <Heading as="h2" size="md">
            Target Score
          </Heading>
          <Text fontSize="5xl" fontWeight="bold" mt={3}>
            9+
          </Text>
        </Box>
      </SimpleGrid>

      <Box bg="white" borderRadius="md" p={4} boxShadow="md" textAlign="center">
        <Heading as="h2" size="md">
          Microphone Test
        </Heading>
        <Box p={4}>
          <HStack justify="center" spacing={4}>
            {isRecording ? (
              <Button colorScheme="red" size="lg" onClick={handleStopRecording}>
                Stop
              </Button>
            ) : (
              <IconButton
                icon={<FiMic />}
                aria-label="Record Audio"
                colorScheme="blue"
                isRound
                size="lg"
                onClick={handleStartRecording}
              />
            )}
            <IconButton
              icon={<FiPlay />}
              aria-label="Play Audio"
              colorScheme="green"
              isRound
              size="lg"
              onClick={handlePlayRecording}
              isDisabled={!audioBlob}
            />
          </HStack>
          {isRecording && (
            <Progress mt={4} value={recordingProgress} max={10} colorScheme="blue" />
          )}
          {audioBlob && !isRecording && (
            <Progress mt={4} value={playbackProgress} max={100} colorScheme="green" />
          )}
        </Box>
      </Box>
    </Stack>
  );
};

import React, { useEffect, useMemo, useState } from "react";
import { useAnswerStore } from "../../../../redux/zustand-store";
import { useParams } from "react-router-dom";
import { decryptString } from "../../../../utils/encryption";
import { useUser } from "../../../../hooks/use-user";
import { useAppDispatch } from "../../../../redux/hooks/useTypedSelector";
import { sendMockTestResultAction } from "../../../../redux/slice/mock-test/mockTestResultSlice";
import { useMockTestList } from "../../../../hooks/use-mock-test-list";
import { readingScoringAnswer } from "../../../../utils/scoring-format";
import { ReadingAnswerTable } from "./ReadingAnswerTable";

export const ReadingAnswerKey: React.FC = () => {
  const user = useUser();
  const [totalScore, setTotalScore] = useState<number | string>(0);
  const { getNextStepHref } = useMockTestList();
  const { testNum } = useParams();
  const dispatch = useAppDispatch();
  const { responses } = useAnswerStore();

  const totalCorrectAnswers = useMemo(() => {
    return responses.reduce((acc, response) => {
      if (response.correctAnswer === response.userAnswer) {
        if (response.questionType) {
          acc += 1;
        }
      }

      return acc;
    }, 0);
  }, [responses]);

  useEffect(() => {
    const totalScore = readingScoringAnswer(totalCorrectAnswers);
    setTotalScore(totalScore);
    const saveResultData = async () => {
      const payLoad = {
        testID: "0",
        testNum: decryptString(testNum?.toString() ?? ""),
        userID: user.id.toString(),
        testType: "Reading",
        section: "",
        score: totalScore.toString(),
        overallScore: "0",
        feedback: JSON.stringify(responses),
      };
      await dispatch(sendMockTestResultAction(payLoad));
    };
    saveResultData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const isCompleteMockTest = localStorage.getItem("isCompleteMockTest");
    if(isCompleteMockTest === "true"){
      window.location.href = `${getNextStepHref("Reading")}/${testNum}`;
    }
}, [getNextStepHref,testNum]);

  return (
    <ReadingAnswerTable
      totalCorrectAnswers={totalCorrectAnswers}
      totalScore={totalScore}
      responses={responses}
    />
  );
};
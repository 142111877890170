import React from "react";
import { Box, Heading, Text, Button, VStack, Icon, Center } from "@chakra-ui/react";
import { useUser } from "../../hooks/use-user";
import { FiXCircle } from "react-icons/fi";

export const CancelPayment: React.FC = () => {
  const user = useUser();

  const handleRetryPayment = () => {
   window.location.href = "/package-plan"
  };

  return (
    <Box minH="100vh" bg="gray.50" py={12} px={6}>
      <Center>
        <VStack spacing={6} maxW="lg" bg="white" p={8} borderRadius="lg" boxShadow="lg">
          <Icon as={FiXCircle} w={20} h={20} color="red.400" />
          <Heading as="h1" size="2xl" textAlign="center">
            Payment Cancelled
          </Heading>
          <Text fontSize="xl" color="gray.600" textAlign="center">
            Hello, {`${user?.firstName} ${user?.lastName}`}! It looks like your payment was not completed.
          </Text>
          <Text fontSize="md" color="gray.500" textAlign="center">
            If you have any issues or changed your mind, you can retry the payment or contact our support team for assistance.
          </Text>
          <Button size="lg" colorScheme="red" onClick={handleRetryPayment}>
            Retry Payment
          </Button>
        </VStack>
      </Center>
    </Box>
  );
};

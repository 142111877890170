import React from "react";
import {
  Box,
  Container,
  Heading,
  VStack,
  HStack,
  Icon,
  Button,
  Flex,
  UnorderedList,
  ListItem,
} from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";
import { useNavigate, useParams } from "react-router-dom";

export const SpeakingTestInstructions: React.FC = () => {
  const { testNum } = useParams();
  const navigate = useNavigate();
  const handleBackClick = () => {
    navigate(-1);
  };

  return (
    <Box minH="100vh" py={10}>
      <Container
        maxW="100%"
        bg="white"
        p={8}
        borderRadius="md"
        boxShadow="md"
        mt={90}
      >
        <Flex
          direction="row"
          justify="space-between"
          align="center"
        >
          <Heading size="md" mb={6}>
            Mock Test - Speaking
          </Heading>
          <Button as={"a"} href={`/speaking-test/${testNum}`} colorScheme="blue" h={7}>NEXT</Button>
        </Flex>
        <VStack align="start" spacing={5}>
          <HStack>
            <Icon as={InfoIcon} color="blue.500" />
            <Heading size="sm">Speaking Test Instructions</Heading>
          </HStack>
          <VStack align="start" spacing={3} color={"blue.600"}>
            <UnorderedList spacing={3}>
              <ListItem>In this practice test, no score will be provided for any of the Speaking tasks. However, you can refer to the Performance Standards for Speaking or listen to sample speaking responses at the end of the Speaking Test.</ListItem>
              <ListItem>For this practice test, you should use a timer to make sure that you complete each task within the given time.</ListItem>
              <ListItem>The practice test will not record your answers. If you wish to record your own answers, record and save your responses using your computer microphone or your own recording device (cellphone, digital recorder, etc.).</ListItem>
              <ListItem>On the official test, if you do not finish a task in the time provided, the screen will move to the next task. You cannot go back to the previous task. However, in this practice test, in order to move forward in the test you must click on “NEXT.”</ListItem>
              <ListItem>Try to complete this practice Speaking Test in around 20 minutes.</ListItem>
            </UnorderedList>
          </VStack>
        </VStack>
        <HStack justify="space-between" mt={10}>
          <Button colorScheme="red" h={7} onClick={handleBackClick}>BACK</Button>
        </HStack>
      </Container>
    </Box>
  );
};

import { Text } from "@chakra-ui/react";
import { FC } from "react";
import { useUser } from "../hooks/use-user";

type LogoProps = {
  size?: number
}
export const Logo: FC<LogoProps>  = ({size = 32}) => {
  const user = useUser();
  return (
    <Text
    as={"a"}
    href={user ? "/dashboard" : "/"}
    fontSize={{ base: 18, md: size}}
    fontWeight={400}
    color={"#001a62"}
    ml={{ base:0, sm:1}}
  >
    Pass
    <Text
      as={"span"}
      color={"#F26B3A"}
      borderTop={'2px #F26B3A solid'}
      borderBottom={'2px #F26B3A solid'}
      borderRadius={9}
      p={"0 3px"}
    >
      English
    </Text>
    Test
  </Text>
  );
};

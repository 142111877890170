import { useState, useEffect } from "react";
import {
  useAppDispatch,
  useAppSelector,
} from "../redux/hooks/useTypedSelector";
import {
  getAllTeachersAction,
  Teacher,
} from "../redux/slice/student/teacherSlice";

export const useTeachers = () => {
  const { isLoading } = useAppSelector((state) => state.teachers);
  const [teachers, setTeachers] = useState<Teacher[]>([]);
  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchTeachers = async () => {
      const res = await dispatch(getAllTeachersAction(""));
      setTeachers(res.payload.data);
    };
    fetchTeachers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return {
    isLoading,
    teachers,
  };
};

import React, { FC } from "react";
import { TestResult } from "../../types/test-result-type";
import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Spacer,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { WritingMockTestData } from "../../redux/slice/mock-test/writingTestSlice";
import { WritingResultAnswer } from "../test/writing/WritingResultAnswer";

type WritingResultPreviewProps = {
  result: TestResult;
};

export const WritingResultPreview: FC<WritingResultPreviewProps> = ({
  result,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const initialRef = React.useRef(null);
  const finalRef = React.useRef(null);
  const res = JSON.parse(
    result.feedback,
  ) as unknown as WritingMockTestData | null;

  const questionOneScore = res?.questionOneScore;
  const questionOneFeedback = res?.questionOneFeedback;
  const questionOneSuggestions = res?.questionOneSuggestions;
  const questionTwoScore = res?.questionTwoScore;
  const questionTwoFeedback = res?.questionTwoFeedback;
  const questionTwoSuggestions = res?.questionTwoSuggestions;

  return (
    <Box>
      <Button onClick={onOpen} variant='outline' colorScheme='blue' w={170}>
        <Text ml={1} mr={1}>
          Writing Result
        </Text>
      </Button>

      <Modal
        initialFocusRef={initialRef}
        finalFocusRef={finalRef}
        size={"5xl"}
        isOpen={isOpen}
        onClose={onClose}
        isCentered
      >
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <WritingResultAnswer
              questionOneScore={questionOneScore ?? "0"}
              questionOneFeedback={questionOneFeedback ?? ""}
              questionOneSuggestions={questionOneSuggestions ?? ""}
              questionTwoScore={questionTwoScore ?? "0"}
              questionTwoFeedback={questionTwoFeedback ?? ""}
              questionTwoSuggestions={questionTwoSuggestions ?? ""}
            />
          </ModalBody>

          <ModalFooter>
            <Flex
              width={"100%"}
              borderRadius="lg"
              align="center"
              justify="space-between"
            >
              <Button variant="outline" padding={"0px 40px"} onClick={onClose}>
                Cancel
              </Button>
              <Spacer />
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

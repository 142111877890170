import React, { useEffect, useState, useCallback } from "react";
import {
  Box,
  Button,
  Container,
  Flex,
  HStack,
  Heading,
  Icon,
  Image,
  Spinner,
  Text,
  VStack,
  RadioGroup,
  Radio,
} from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";
// import { addSpaceBeforeCaps } from "../../../utils/helperFuntions";
import {
  useAppSelector,
  useAppDispatch,
} from "../../../redux/hooks/useTypedSelector";
import { startMockTestQuestionsAction } from "../../../redux/slice/mock-test/mockTestSlice";
import { WritingQuestion } from "../../../types/writing-types";
import { DisplayQuestionContent } from "../../../components/DisplayQuestionContent";
import {
  SpeakingQuestionData,
  SpeakingSectionName,
} from "../../../types/speaking-type";
import { VoiceRecorder } from "./VoiceRecorder";
import { decryptString } from "../../../utils/encryption";
import { useParams } from "react-router-dom";

export const SpeakingTest: React.FC = () => {
  const { user } = useAppSelector((state: { auth: any }) => state.auth);
  const [currentSection, setCurrentSection] = useState<number>(1);
  const [secondsLeft, setSecondsLeft] = useState<number>(0);
  const [showTimer, setShowTimer] = useState<boolean>(true);
  const { isLoading } = useAppSelector((state) => state.mockTest);
  const [questions, setQuestions] = useState<WritingQuestion[]>([]);
  const [questionSixInstruction, setQuestionSixInstruction] = useState<string>("");
  const [currentQuestion, setCurrentQuestion] = useState<string>("");
  const [testID, setTestID] = useState<number>(0);
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [questionFivePhotoOne, setQuestionFivePhotoOne] = useState<
    string | null
  >(null);
  const [questionFivePhotoTwo, setQuestionFivePhotoTwo] = useState<
    string | null
  >(null);
  const [questionFivePhotoThree, setQuestionFivePhotoThree] = useState<
    string | null
  >(null);
  const [suggestedPhoto, setSuggestedPhoto] = useState<string | null>(null);
  const { testNum } = useParams();
  const decryptedTestNum = decryptString(testNum?.toString() ?? "");

  const dispatch = useAppDispatch();

  useEffect(() => {
    const fetchData = async () => {
      const res = await dispatch(
        startMockTestQuestionsAction({
          type: "Speaking",
          testNum: decryptedTestNum,
        }),
      );
      setQuestions(res.payload as WritingQuestion[]);
    };
    fetchData();
    if (questions[0]) {
      setTestID(questions[0]?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const setNextQuestion = useCallback(() => {
    setShowTimer(false);
    setTimeout(() => {
      setCurrentSection((prevSection) => prevSection + 1);
    }, 0);
  }, []);

  const handleAutoSelectOption = useCallback(() => {
    if (questionFivePhotoOne) {
      setSuggestedPhoto(questionFivePhotoOne);
    } else if (questionFivePhotoTwo) {
      setSuggestedPhoto(questionFivePhotoTwo);
    } else if (questionFivePhotoThree) {
      setSuggestedPhoto(questionFivePhotoThree);
    }
  }, [questionFivePhotoOne, questionFivePhotoTwo, questionFivePhotoThree]);

  const handleStartSpeaking = useCallback(() => {
    setShowTimer(true);
    if (currentSection === 7) {
      handleAutoSelectOption();
      setNextQuestion();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSection, handleAutoSelectOption, setNextQuestion]);

  const getCurrentQuestions = JSON.parse(
    questions[0]?.data ?? "{}",
  ) as SpeakingQuestionData;

  useEffect(() => {
    if (currentSection === 1) {
      setSecondsLeft(parseInt(getCurrentQuestions.questionPracticeTaskTime));
      setCurrentQuestion(getCurrentQuestions.questionPracticeTask);
    }
    if (currentSection === 2) {
      setSecondsLeft(parseInt(getCurrentQuestions.questionSpeakingTaskOneTime));
      setCurrentQuestion(getCurrentQuestions.questionSpeakingTaskOne);
    }
    if (currentSection === 3) {
      setSecondsLeft(parseInt(getCurrentQuestions.questionSpeakingTaskTwoTime));
      setCurrentQuestion(getCurrentQuestions.questionSpeakingTaskTwo);
    }
    if (currentSection === 4) {
      setSecondsLeft(
        parseInt(getCurrentQuestions.questionSpeakingTaskThreeTime),
      );
      setCurrentQuestion(getCurrentQuestions.questionSpeakingTaskThree);
      setSelectedImage(getCurrentQuestions.questionSpeakingTaskThreePhoto);
    }
    if (currentSection === 5) {
      setSecondsLeft(
        parseInt(getCurrentQuestions.questionSpeakingTaskFourTime),
      );
      setCurrentQuestion(getCurrentQuestions.questionSpeakingTaskFour);
      setSelectedImage(getCurrentQuestions.questionSpeakingTaskFourPhoto);
    }
    if (currentSection === 6) {
      setSecondsLeft(30);
      setCurrentQuestion(
        getCurrentQuestions.questionSpeakingTaskFiveInstruction,
      );
      setSelectedImage(null);
    }
    if (currentSection === 7) {
      setSecondsLeft(
        parseInt(getCurrentQuestions.questionSpeakingTaskFiveTime),
      );
      setCurrentQuestion(getCurrentQuestions.questionSpeakingTaskFive);
      setSelectedImage(null);
      setQuestionFivePhotoOne(
        getCurrentQuestions.questionSpeakingTaskFivePhotoOne,
      );
      setQuestionFivePhotoTwo(
        getCurrentQuestions.questionSpeakingTaskFivePhotoTwo,
      );
    }
    if (currentSection === 8) {
      setSecondsLeft(
        parseInt(getCurrentQuestions.questionSpeakingTaskFiveTime),
      );
      setCurrentQuestion(getCurrentQuestions.questionSpeakingTaskFiveTwo);
      setQuestionFivePhotoThree(
        getCurrentQuestions.questionSpeakingTaskFivePhotoThree,
      );
      setSelectedImage(null);
      setQuestionFivePhotoOne(null);
      setQuestionFivePhotoTwo(null);
    }
    if (currentSection === 9) {
      setSecondsLeft(parseInt(getCurrentQuestions.questionSpeakingTaskSixTime));
      setQuestionSixInstruction(getCurrentQuestions.questionSpeakingTaskSixInstructionOne)
      setCurrentQuestion(getCurrentQuestions.questionSpeakingTaskSix);
      setQuestionFivePhotoThree(null);
      setSuggestedPhoto(null);
    }
    if (currentSection === 10) {
      setSecondsLeft(
        parseInt(getCurrentQuestions.questionSpeakingTaskSevenTime),
      );
      setCurrentQuestion(getCurrentQuestions.questionSpeakingTaskSeven);
      setQuestionFivePhotoThree(null);
    }
    if (currentSection === 11) {
      setSecondsLeft(
        parseInt(getCurrentQuestions.questionSpeakingTaskEightTime),
      );
      setCurrentQuestion(getCurrentQuestions.questionSpeakingTaskEight);
      setSelectedImage(getCurrentQuestions.questionSpeakingTaskEightPhoto);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSection, getCurrentQuestions]);

  useEffect(() => {
    setShowTimer(showTimer ? false : true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSection]);

  if (isLoading || questions.length < 1) {
    return <Spinner />;
  }

  if (questions.length < 1) {
    return (
      <>
        <Spinner />
        <Text>No questions available</Text>
      </>
    );
  }

  const handlePhotoSelect = (value: string) => {
    setSuggestedPhoto(value);
  };

  //   console.log("getCurrentQuestions: ", getCurrentQuestions);

  return (
    <Box minH="100vh" py={10}>
      <Container
        maxW="100%"
        bg="white"
        p={8}
        borderRadius="md"
        boxShadow="md"
        mt={9}
      >
        <Flex
          direction="row"
          justify="space-between"
          align="center"
          bg={"gray.200"}
          border={"1px #CCC solid"}
          p={3}
          h={10}
        >
          <Heading size="md" fontWeight={300} fontSize={16}>
            Mock Test {" "} {decryptedTestNum} {" "}
            {/* {addSpaceBeforeCaps(questions[0].questionType)} {" "} */}
            {SpeakingSectionName[currentSection ===  6 || currentSection === 7 || currentSection === 8 ? 5 : currentSection > 8 ? currentSection -3 : currentSection - 1]} {currentSection}
          </Heading>
          <Box>
            <Flex>
              {/* {showTimer && (
                <CountdownTimer
                  time={secondsLeft}
                  onTimeUp={handleTimeUp}
                  timeType="Second"
                />
              )} */}
              <Text>
                <Text as={"span"} color={"#53565a"}>
                  Preparation:
                </Text>{" "}
                30 seconds
                <Text as={"span"} color={"#53565a"} ml={3}>
                  Recording:
                </Text>{" "}
                {secondsLeft} seconds
              </Text>
              <Button
                colorScheme="blue"
                fontSize={11}
                ml={4}
                h={3}
                p={4}
                onClick={setNextQuestion}
              >
                NEXT
              </Button>
            </Flex>
          </Box>
        </Flex>
        <HStack justify="space-between">
          <VStack
            align="start"
            spacing={5}
            w="full"
            p={3}
            borderLeft="1px #CCC solid"
            borderRight="1px #CCC solid"
            borderBottom="1px #CCC solid"
            h={"100vh"}
            overflowY="auto"
          >
            <HStack>
              <Icon as={InfoIcon} color="blue.500" />
              <Heading size="sm" color="blue.600">
                Read the following message
              </Heading>
            </HStack>
            {currentSection === 9 && <DisplayQuestionContent content={questionSixInstruction} />}

            <DisplayQuestionContent content={currentQuestion} />


            {questionFivePhotoOne && questionFivePhotoTwo ? (
              <>
                <RadioGroup
                  onChange={handlePhotoSelect}
                  value={suggestedPhoto ?? ""}
                >
                  <Flex>
                    <Box
                      border={"1px #000 solid"}
                      bg={
                        suggestedPhoto === questionFivePhotoOne
                          ? "green.200"
                          : "transparent"
                      }
                    >
                      <Radio value={questionFivePhotoOne}>
                        <Image src={questionFivePhotoOne} w={500} />
                      </Radio>
                    </Box>
                    <Box
                      border={"1px #000 solid"}
                      ml={2}
                      bg={
                        suggestedPhoto === questionFivePhotoTwo
                          ? "green.200"
                          : "transparent"
                      }
                    >
                      <Radio value={questionFivePhotoTwo}>
                        <Image src={questionFivePhotoTwo} w={500} />
                      </Radio>
                    </Box>
                  </Flex>
                </RadioGroup>
                <VoiceRecorder
                  key={`${currentSection}-${currentQuestion}`}
                  recordTime={secondsLeft}
                  countdownTimer={30}
                  questionNumber={currentSection.toString()}
                  question={currentQuestion ? currentQuestion : ""}
                  currentSection={currentSection}
                  onTimeUp={handleStartSpeaking}
                  testID={testID}
                  testNum={decryptedTestNum}
                  user={user.user.id}
                />
              </>
            ) : (
              <>
                <Flex>
                  <Box>
                    {selectedImage && <Image src={selectedImage} w={500} />}
                  </Box>
                  <Box>
                    {!suggestedPhoto && (
                      <VoiceRecorder
                        key={`${currentSection}-${currentQuestion}`}
                        recordTime={secondsLeft}
                        countdownTimer={30}
                        questionNumber={currentSection.toString()}
                        question={currentQuestion ? currentQuestion : ""}
                        currentSection={currentSection}
                        onTimeUp={handleStartSpeaking}
                        testID={testID}
                        testNum={decryptedTestNum}
                        user={user.user.id}
                      />
                    )}
                  </Box>
                </Flex>
              </>
            )}

            {suggestedPhoto && !questionFivePhotoTwo ? (
              <>
                <Flex>
                  <Box border={"1px #000 solid"}>
                    {questionFivePhotoThree && (
                      <Image src={questionFivePhotoThree} w={500} />
                    )}
                  </Box>

                  <Box border={"1px #000 solid"} ml={2} bg={"green.200"}>
                    <Text textAlign={"center"}>Your Choice</Text>
                    {suggestedPhoto && <Image src={suggestedPhoto} w={500} />}
                  </Box>
                </Flex>
                <VoiceRecorder
                  key={`${currentSection}-${currentQuestion}`}
                  recordTime={secondsLeft}
                  countdownTimer={30}
                  questionNumber={currentSection.toString()}
                  question={currentQuestion ? currentQuestion : ""}
                  currentSection={currentSection}
                  onTimeUp={handleStartSpeaking}
                  testID={testID}
                  testNum={decryptedTestNum}
                  user={user.user.id}
                />
              </>
            ) : (
              ""
            )}
          </VStack>
        </HStack>
      </Container>
    </Box>
  );
};
